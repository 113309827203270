import React from 'react'
import App from '../App'
import { useTranslation } from 'react-i18next'
import { FaApple, FaGooglePlay } from "react-icons/fa";

function GlobalLayout() {
    const [t] = useTranslation("global")
    return (
        <div className='bg-white flex justify-center items-center w-full flex-1 relative'>
            <div className='w-[400px] bg-white sm:w-full fixed top-0 right-[300px] md:right-[100px] sm:right-0 min-h-screen max-h-max  overflow-scroll z-10 shadow-lg'>
                <App />
            </div>
            <div className='fixed w-full top-0 left-0 h-[500px]' style={{ background: "linear-gradient(84deg, #f9d2f6 20%, #b6d4fb 81%)" }}>
                <div className='flex w-[450px] ml-[250px] md:ml-[100px] mt-8 flex-col gap-0'>
                    {/* <h1 className='text-blue text-[45px] font-extrabold mb-0 lowercase'>seeding+</h1> */}
                    <img src={require('../assets/seedingplus_logo_pc.png')} className='w-[220px]'/>
                    <span>
                        <p className='text-black text-lg font-normal mt-4'>{t("pc.text1")}</p>
                        <p className='text-black text-lg font-normal mt-2'>{t("pc.text1_1")}</p>
                    </span>
                    <span className=''>
                        <p className='text-black text-lg font-normal mt-2'>{t("pc.text2")}</p>
                        <p className='text-black text-lg font-normal mt-2'>{t("pc.text2_2")}</p>
                    </span>
                    <img src={require('../assets/banner1.png')} className='bg-white mt-6 w-[350px] rounded-lg border-[1px] border-grey/40 shadow-md'/>
                </div>
            </div>
            <div className='fixed w-full top-[440px] left-0' >
                <div className='flex w-[450px] ml-[250px] md:ml-[100px] mt-24 justify-between items-start gap-8'>
                    <div>
                        <span>
                            <p className='text-black text-xl font-normal'>{t("pc.text3")}</p>
                            <p className='text-black text-xl font-medium'>{t("pc.text3_3")}</p>
                        </span>

                        {/* <span className='flex justify-start items-center gap-3 mt-8'>
                            <div className='flex justify-center items-center gap-2 rounded-xl px-3 py-2 border-[1px] border-grey/40 shadow-sm'>
                                <FaGooglePlay className='text-black text-base'/>
                                <p className='text-black text-sm'>Google Play</p>
                            </div>
                            <div className='flex justify-center items-center gap-2 rounded-xl px-3 py-2 border-[1px] border-grey/40 shadow-sm'>
                                <FaApple  className='text-black text-lg'/>
                                <p className='text-black text-sm'>App Store</p>
                            </div>
                        </span> */}

                    </div>
                    <img src={require('../assets/QR.png')} alt='' className='w-[110px] h-[110px] p-[2px] rounded-md border-grey/40 border-[1px]' />
                </div>
            </div>
        </div>
    )
}

export default GlobalLayout
