import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { AdvertiserContext } from '../state/AdvertiserProvider'
import { toast } from 'react-toastify'
import { filterStyles } from '../utils/selectStyles'
import { BACKEND_HOST } from '../constants/Index'

function PacakgeInfluencerSelection() {
    const [users, setUsers] = useState(null)
    const [sort, setSort] = useState('all')
    const { setSelectInfluencerPopup } = useContext(AdvertiserContext)

    const search = (e) => {
        fetchUsers(e.target.value)
    }

    const fetchUsers = async (email = '') => {
        const response = await fetch(`${BACKEND_HOST}/api/user/role/influencers?email=${email}&sort=${sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        setUsers(null)
        if (response.status === 200) {
            setUsers(res)
        }
        else
            toast.error(res.message)
    }


    useEffect(() => {
        fetchUsers()
    }, [sort])
    return (
        <div className='bg-black/50 sm:w-full -ml-[20px] w-[400px] h-screen fixed top-0  z-[60]'>

            <div className='bg-white h-[60%] absolute bottom-0 z-[61] w-full rounded-t-3xl flex flex-col justify-start items-center'>
                <span onClick={() => setSelectInfluencerPopup(false)} className='flex justify-center items-center p-1 bg-red rounded-full h-[30px] w-[30px] -mt-[14px] cursor-pointer'>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.50146 5.59644L19.5005 19.5955" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.50045 19.5955L19.4995 5.59644" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>

                <div className='flex flex-col w-full px-5 py-5'>

                    {users && <>

                        <div className='flex gap-2 justify-between items-center'>
                            <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[70%]'>
                                <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                            <span className='w-[30%]'>
                            <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: 'All', label: 'All' }} options={[{ value: 'All', label: 'All' }, { value: 'ASC', label: 'A-Z' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
                            </span>
                        </div>

                        <div className='flex gap-3 flex-col mt-2 overflow-scroll h-[330px]'>
                            {users?.map((u, index) => {
                                return <Item u={u} key={index} />
                            })}
                        </div>

                    </>}
                </div>
            </div>
        </div>
    )
}

export default PacakgeInfluencerSelection


const Item = ({ u }) => {

    const { selectInfluencerPopuup, calculateFee, setSelectInfluencerPopup } = useContext(AdvertiserContext)

    const selectinfluencer = (u) => {
        selectInfluencerPopuup.influencer = u
        calculateFee()
        setSelectInfluencerPopup(false)
    }

    return ( 
        <div className='flex justify-between items-center gap-2  border-[1px] border-grey/30 rounded-lg p-2 cursor-pointer' onClick={() =>selectinfluencer(u)}>
            <div className='w-[80%] flex justify-start items-center gap-2  '>
                <img src={`${BACKEND_HOST}/${u.profile_image}`} alt='profile' className='w-[50px] h-[50px] object-cover rounded-lg bg-grey/50' />
                <div>
                    <div className='flex gap-2'>
                        <h2 className='text-black text-sm font-semibold capitalize'>{u.youtubeChannel}</h2>
                        <p className='bg-blue/20 px-2 py-[0px] leading-[20px] text-blue text-[10px] rounded-md'>{u?.category?.name}</p>
                    </div>
                    <div className='flex gap-5'>
                        <span>
                            <h2 className='text-black text-[12px] font-semibold capitalize'>Preferred</h2>
                            <p className='text-black text-[12px] leading-[10px] font-normal capitalize'>{u.preffered}</p>
                        </span>
                        <span>
                            <h2 className='text-black text-[12px] font-semibold capitalize'>Not Preferred</h2>
                            <p className='text-black text-[12px] leading-[10px] font-normal capitalize'>{u.nonpreferred}</p>
                        </span>
                    </div>
                </div>
            </div>

            <p className='w-[20%] text-blue text-[12px] font-semibold text-center'>
                    Ship to {u.fee?.area_title}
            </p>
        </div>
    )
}