import React, { useEffect } from 'react'
import PCLayout from './layouts/PCLayout'
import LoginHeader from './components/LoginHeader'
import Login from './pages/Login'
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Outlet, Route, Routes, useLocation, useNavigation } from 'react-router-dom'
import Signup from './pages/Signup'
import Admin from './layouts/Admin'
import Advertiser from './layouts/Advertiser';
import AdvertiserProvider from './state/AdvertiserProvider';
import Staff from './layouts/Staff';
import StaffProvide from './state/StaffProvide';
import Influencer from './layouts/Influencer';
import MainFooter from './components/MainFooter';

function App() {

  const token = localStorage.getItem('token')
  const role = localStorage.getItem('role')

  if (!token && window.location.pathname == '/signup'){

  }
  else if (!token && window.location.pathname !== '/')
    window.location.pathname = '/'
  else if (token && role === 'admin' && !window.location.pathname.startsWith('/admin'))
    window.location.pathname = '/admin'
  else if (token && role === 'advertiser' && !window.location.pathname.startsWith('/advertiser'))
    window.location.pathname = '/advertiser'
  else if (token && role === 'staff' && !window.location.pathname.startsWith('/staff'))
    window.location.pathname = '/staff'
  else if (token && role === 'influencer' && !window.location.pathname.startsWith('/influencer'))
    window.location.pathname = '/influencer'
  else{
    // window.location.pathname = '/login'
  }



  if (window.location.pathname === '/' || window.location.pathname === '/signup')
    return (
      <div className='px-4 overflow-auto'>
        <LoginHeader />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
        </Routes>
        <ToastContainer transition={Zoom} position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
      </div>
    )
  else {
    return (
      <div className='h-screen relative overflow-y-scroll overflow-x-hidden'>
        <Routes>
          <Route path="/admin/*" element={<Admin />} />

          <Route path="/advertiser/*" element={
            <AdvertiserProvider>
              <Advertiser />
            </AdvertiserProvider>
          } />

          <Route path='/staff/*' element={
            <StaffProvide>
              <Staff />
            </StaffProvide>
          } />

          <Route path='/influencer/*' element={<Influencer />} />
        </Routes>
        <ToastContainer transition={Zoom} position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
      </div>
    )
  }
}

export default App


// NODE_OPTIONS="--max_old_space_size=4096" npm start