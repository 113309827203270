import React, { useEffect, useState } from 'react'
import { BACKEND_HOST } from '../constants/Index'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { filterStyles } from '../utils/selectStyles'
import { toast } from 'react-toastify'
import { PiDotsThreeOutlineThin } from 'react-icons/pi'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import { extractPackages } from '../utils'
import { getSeoulTime } from '../utils/getSeoulTime'

function Verify() {
    const [packages, setPackages] = useState(null)
    const [t] = useTranslation("global")

    const [sort, setSort] = useState(1)

    const search = (e) => {
        fetchPackages(e.target.value)
    }

    const fetchPackages = async (packageid = '') => {
        const response = await fetch(`${BACKEND_HOST}/api/packages/staff/verify?packageid=${packageid}&sort=${sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            let packages = extractPackages(res.packages)
            setPackages(packages)
        }
        else
            toast.error(res.message)
    }

    useEffect(() => {
        setPackages(null)
        fetchPackages()
    }, [sort])

    return (
        <div className='min-h-[200px] max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
            {!packages && <Loader />}
            {packages && <>
                <div className='flex gap-2 justify-between items-center'>
                    <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[70%]'>
                        <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: 'des', label: 'Newest' }} options={[{ value: 'des', label: 'Newest' }, { value: 'asc', label: 'A-Z' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
                </div>
                {packages && <p className='text-black font-medium text-base'>{packages?.length} Packages</p>}

                <div className='flex flex-col gap-2'>

                    {packages?.map((p, index) => {
                        p.quantity = 0
                        p.totalInfluencers = []
                        for (let i = 0; i < p.packageItemIds.length; i++) {
                            p.quantity += p.packageItemIds[i].product_quantity
                            const influencer = p.packageItemIds[i]?.influencer;
                            if (influencer && !p.totalInfluencers.includes(influencer)) {
                                p.totalInfluencers.push(influencer);
                            }
                        }
                        p.totalInfluencers = p.totalInfluencers.length
                        return <Item p={p} key={index} />
                    })}

                </div>
            </>}
        </div>
    )
}

export default Verify


const Item = ({ p }) => {
    const [accordian, setAccordian] = useState(false)
    const [t] = useTranslation("global")

    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState('')

    const addComment = async () => {
        setLoading(true)
        const response = await fetch(`${BACKEND_HOST}/api/packages/staff/list/recieved_add_comment`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            },
            body: JSON.stringify({
                _id: p._id,
                comment: comment
            })
        })

        const res = await response.json()
        if (response.status === 200) {
            setAccordian(false)
            toast.success(res.message)
        }
        else
            toast.error(res.message)
        setLoading(false)

    }

    return (
        <div className='border-[1px] border-grey/50 p-2 flex flex-col gap-2 rounded-lg'>

            <div className='relative  rounded-lg  flex justify-start items-stretch gap-2'>
                <Link to={`/staff/verify/${p._id}`} className='w-[70px] h-[60px] rounded-md bg-grey/50'>
                    <img src={`${BACKEND_HOST}/${p.packageItemIds[0]?.product?.image}`} className='rounded-md w-[60px] h-[60px] object-cover bg-grey/20' />
                </Link>
                <div className='flex flex-col w-full gap-1'>
                    <div className='flex justify-between items-center'>
                        <span className='flex justify-start items-center gap-2'>
                            <h1 className='text-blue text-sm font-semibold capitalize'>{p.packageID}</h1>
                            <p className='text-[12px] text-grey'>{getSeoulTime(p.paymentDate)}</p>
                        </span>
                        <PiDotsThreeOutlineThin onClick={() => setAccordian(state => !state)} className='text-base cursor-pointer' />
                    </div>
                    <div className='flex justify-between items-stretch gap-1'>
                        <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
                            <p className='font-bold leading-3'>{t('packageItem.items')}</p>
                            <p>{p.items_count}</p>
                        </span>
                        <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
                            <p className='font-bold leading-3'>{t('packageItem.quantity')}</p>
                            <p>{p.quantity}</p>
                        </span>
                        <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 pt-[2px] pb-[1px] text-[10px] text-black rounded-md'>
                            <p className='font-bold leading-3'>{t('packageItem.influencer')}</p>
                            <p>{p.totalInfluencers}</p>
                        </span>
                    </div>
                    <p className='text-black text-sm leading-3 text-center border-[1px] border-blue p-1 rounded-sm'>{p.shipping_service}: {p.invoiceno}</p>
                </div>

            </div>
            <div className={`${accordian ? 'h-[120px]' : 'h-0'} flex flex-col gap-1 overflow-hidden transition-all ease-in duration-100`}>
                <textarea defaultValue={p?.comment_by_staff} className='resize-none text-sm text-grey h-[80px] p-2 border-[1px] border-blue rounded-md outline-none' onChange={(e) => setComment(e.target.value)} />
                <button onClick={addComment} disabled={loading} className='disabled:opacity-50 bg-blue flex-1 text-sm rounded-md text-white px-2 text-[12px]'>{t('packageItem.comment')}</button>
            </div>
        </div>
    )
}
