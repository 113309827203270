import React, { useState, useEffect } from 'react'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import { BACKEND_HOST } from '../constants/Index'
import { toast } from 'react-toastify'
import { portoneRefundApi } from '../utils'
import { useNavigate } from 'react-router-dom'

function RefundPopup({ refund, setRefund }) {
    const [activeBtn, setActiveBtn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [refundAmount, setRefundAmount] = useState(0)
    const [t] = useTranslation("global")
    const navigate = useNavigate()
    useEffect(() => {
        if (refundAmount > 0)
            setActiveBtn(true)
        else
            setActiveBtn(false)
    }, [refundAmount])

    const callApi = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/payments/refund`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            },
            body: JSON.stringify({ _id: refund._id, refund_amount: refundAmount })
        })

        const res = await response.json()
        if (response.status === 200) {
            redirect(true)
        }
        else
            redirect(false)
    }

 
    const doRefund = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            refund.refundAmount = refundAmount
            refund.totalPayment = Number(refund.totalPayment) - Number(refund.refund_amount || 0)
            // refund.totalPayment = 850
            const caneceled = await portoneRefundApi(refund)
            if(caneceled)
                await callApi()
            else
                redirect(false)
            setRefund(false)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const redirect = (status) => {
        navigate(`/admin/refund-confirmation?packageId=${refund.packageID}&status=${status}`)
    }
    return (
        <div className='bg-black/50 sm:w-full -ml-[20px] w-[400px] h-screen fixed top-0  z-[60]'>

            <div className='bg-white h-[40%] absolute bottom-0 z-[61] w-full rounded-t-3xl flex flex-col justify-start items-center'>
                <span onClick={() => setRefund(false)} className='flex justify-center items-center p-1 bg-red rounded-full h-[30px] w-[30px] -mt-[14px] cursor-pointer'>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.50146 5.59644L19.5005 19.5955" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.50045 19.5955L19.4995 5.59644" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>

                <form onSubmit={doRefund} className='flex flex-col h-full justify-between w-full px-5 py-5'>

                    <div className=' flex flex-col gap-4'>
                        <div className='flex justify-between items-center'>
                            <p className='text-black text-sm font-semibold'>Payment Amount</p>
                            <input className='w-[30%] py-3 px-4 bg-blue/10 outline-none rounded-md text-black text-sm font-semibold' readOnly value={refund.totalPayment} />
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='text-black text-sm font-semibold'>Refund Amount</p>
                            <input className='w-[30%] py-3 px-4 border-black/50 outline-none border-[1px] focus:border-blue rounded-md text-black text-sm font-semibold' type="number" onChange={(e) => setRefundAmount(e.target.value)} />
                        </div>
                    </div>

                    <Button title={t('sendPage.proceed')} active={activeBtn} disabled={loading} />
                </form>
            </div>
        </div>
    )
}

export default RefundPopup
