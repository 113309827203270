import React, { createContext, useState } from "react";

export const StaffContext = createContext();

export default function StaffProvide(props) {
    const [packageInvoicePopup, setPackageInvoicePopup] = useState(false)
    let contextValues = {
        packageInvoicePopup, setPackageInvoicePopup
    }
    return (
        <StaffContext.Provider value={contextValues}>
            {props.children}
        </StaffContext.Provider>
    )
}
