export const validate = (data, role, id) => {
    let ID = validateID(data.unique_id);
    let password = id? true : data.password?.length >= 5 ? true : false
    let name = data.name?.length >= 1 ? true : false
    let department = data.department?.length >= 1 ? true : false
    let companyName = data.companyName?.length >= 1 ? true : false
    let companyAddress = data.companyAddress?.length >= 1 ? true : false
    let receivingAddress = data.receivingAddress?.length >= 1 ? true : false
    let receiver = data.receiver?.length >= 1 ? true : false
    let youtubeChannel = data.youtubeChannel?.length >= 1 ? true : false
    let subscriberCount = data.subscriberCount?.length >= 1 ? true : false
    let mobile = validateMobileNumber(data.mobile)
    let email = validateEmail(data.email)
    // let businessRegNo = validateBRegNo(data.businessRegNo)
    let businessRegNo = data.businessRegNo?.length >= 1 ? true : false
    let telephone = validatePhoneNumber(data.telephone)
    let viewCount = validateURL(data.viewCount)
    let fee = data.fee ? true : false;
    let category = data.category ? true : false;


    console.log(
        ID, password, name, email, youtubeChannel, subscriberCount, receivingAddress,
        viewCount, receiver, mobile, fee, category
    );
    
    if (role === 'staff' && ID && password && name && department && mobile && email)
        return true;
    else if (role === 'advertiser' && ID && password && name && mobile && email && companyName && businessRegNo && telephone && companyAddress && receivingAddress && receiver) 
        return true
    else if( role == 'influencer' && ID && password && name && email && youtubeChannel && subscriberCount && receivingAddress
         && receiver && mobile && fee && category)
        return true
    else
        return false
}


export const validateSignUpForm = (data) => {
    let company = data?.company?.length >= 1? true : false
    let name = data?.name?.length >= 1? true : false
    let number =  validateMobileNumber(data?.number)
    let email = validateEmail(data?.email)
    console.log(company, name, number, email);
    if(company && name && number && email)
        return true;
    return false
}


const validateURL = (value = '') => {
    const urlRegex = /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}([a-zA-Z0-9/?#&=.]+)?$/;
    return urlRegex.test(value)
}

const validateBRegNo = (breg = '') => {
    const regex = /^\d{3}-\d{2}-\d{5}$/;
    return regex.test(breg)
};

const validateEmail = (email = '') => {
    const emailRegex = /(?:[!#-'*+/-9=?A-Z^-~-]+(?:\.[!#-'*+/-9=?A-Z^-~-]+)*|\"(?:\[\]!#-[^-~\t]|(\\[\t-~]))+\")@(?:(?:[!#-'*+/-9=?A-Z^-~-]+(?:\.[!#-'*+/-9=?A-Z^-~-]+)*)|\[(?:[\t-Z^-~]*)\])/;
    return emailRegex.test(email)
};

const validatePhoneNumber = (phoneNumber = '') => {
    const phoneRegex = /^(?:\+?82-?|0)(?:10|70|(?:2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-?\d{3,4}-?\d{4}$/;
    return phoneRegex.test(phoneNumber)
};

const validateMobileNumber = (phoneNumber) => {
    const mobileRegex = /^(?:\+?82-?|0)(?:10|70|(?:2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-?\d{3,4}-?\d{4}$/;
    return mobileRegex.test(phoneNumber)
  };

const validateID = (input = '') => {
    const regex = /^[a-zA-Z]{4}\d{6}$/;
    return regex.test(input)
};