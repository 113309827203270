import React, { useEffect, useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants/Index'
import { AiFillPlusCircle } from "react-icons/ai";
import Loader from '../components/Loader'

function StaffPackageForInfluencer() {
    const [packages, setPackages] = useState(null)
    const { _id } = useParams()
    const navigate = useNavigate()
    const [t] = useTranslation("global")

    const [packgesToBeBoxed, setPackagesToBeBoxed] = useState([])
    const [summary, setSummary] = useState([])
    const [loading, setLoading] = useState(false)


    const fetchPackages = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/packages/staff/influencer_packages/${_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            setPackages(res)
            if(res.length === 0)
                navigate(-1)
        }
        else
            toast.error(res.message)
    }

    const addPackageItem = (_id) => {
        const check = packgesToBeBoxed.some((x) => x === _id)
        if (check) {
            const filter = packgesToBeBoxed.filter((x) => x !== _id);
            setPackagesToBeBoxed(filter)
        }
        else
            setPackagesToBeBoxed([...packgesToBeBoxed, _id])
    }

    const packaged = async() => {
        setLoading(true)
        let influencer_name = packages[0]?.influencer?.name
        let influencer_id = packages[0]?.influencer?._id
        const response = await fetch(`${BACKEND_HOST}/api/packages/staff/boxed`,{
            method: 'POST',
            headers: {
                'Content-Type' : 'Application/json',
                token: localStorage.getItem('token')
            },
            body: JSON.stringify({packgesToBeBoxed, influencer_name, influencer_id})
        })
        const res = await response.json()
        if(response.status === 201){
            toast.success(res.message)
            setPackages(null)
            fetchPackages()
            setPackagesToBeBoxed([])
        }
        else
            toast.error(res.message)
        setLoading(false)
    }

    useEffect(() => {
        let quantity = 0;
        for (let i = 0; i < packgesToBeBoxed.length; i++) {
                const x = packages.filter((x => x._id === packgesToBeBoxed[i]))[0]
                quantity += parseInt(x.product_quantity)
            
        }

        setSummary({items:packgesToBeBoxed.length, quantity})

    }, [packgesToBeBoxed])

    useEffect(() => {
        fetchPackages()
    }, [_id])

    return (
        <div className={`top-0 fixed transition-all ease-in duration-200 w-[400px] md:right-[100px] right-[300px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `}>
            <div className='w-[400px] relative m-auto bg-white h-screen flex flex-col px-7 pb-16 sm:w-full'>
            {!packages && <Loader height={'h-screen'} />}
            {packages && <>
                <div className='flex h-[50px] w-full justify-start items-start mt-8'>
                    <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={() => navigate(-1)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <div className='flex flex-col gap-1 justify-center items-center w-full -ml-[30px] '>
                        <p className='text-black text-base font-medium text-center'>{t('packagePage.influencer')}</p>
                        {packages && <p className='text-black text-base font-medium text-center leading-[10px]'>{packages[0]?.influencer.name}</p>}
                    </div>


                </div>
                {packages && <p className='text-black font-medium text-base my-2'>{packages?.length} Items</p>}

                <div className='flex flex-col gap-2 h-[580px] sm:h-[480px] overflow-scroll'>

                    {packages?.map((p, index) => {
                        return <div className='relative flex gap-2 justify-start items-start p-2 border-[1px] border-grey/50 rounded-lg'>
                            <img src={`${BACKEND_HOST}/${p.product?.image}`} alt='' className='w-[50px] h-[50px] object-center rounded-md' />
                            <div className='flex flex-col justify-start items-start'>
                                <h4 className='text-black text-[12px] font-semibold'>{p.product.title} <span className='text-blue text-sm'>x{p.product_quantity}</span></h4>
                                <p className='text-black text-[10px] -mt-1'>{p.product.category_name}</p>
                                <p className='text-blue bg-blue/30 px-2 py-[1px] rounded-md text-[8px]'>{p.product.category_name}</p>
                                <p className='text-black text-[12px]'>{p.product.description.substr(0, 45)}</p>
                            </div>
                            <AiFillPlusCircle Plus className={` ${packgesToBeBoxed.some((x) => x === p._id) ? 'text-blue' : 'text-grey'} text-xl absolute top-2 right-2 cursor-pointer`} onClick={() => addPackageItem(p._id)} />
                        </div>
                    })}


                </div>

                <div className='w-full absolute left-0 bottom-0 sm:bottom-14'>
                    <div className='px-4 flex justify-between items-center h-[70px]'>
                        <div>
                            <p className='text-blue text-[12px] font-medium'>{summary.items} Items, {summary.quantity} quantity</p>
                        </div>

                        <button disabled={packgesToBeBoxed.length <= 0 ? true : false || loading} onClick={packaged} className='disabled:opacity-50 bg-blue px-6 py-3 rounded-3xl text-white text-sm'>
                            {t("packagePage.package")}
                        </button>

                    </div>
                </div>
            </>}
            </div>
        </div>
    )
}

export default StaffPackageForInfluencer
