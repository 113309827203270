import React, { useEffect, useState } from 'react'
import Input from '../components/Input'
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import { Link, json } from 'react-router-dom'
import { toast } from 'react-toastify';
import { BACKEND_HOST } from '../constants/Index';
import MainFooter from '../components/MainFooter';
import axios from 'axios'

function Login() {
  const [t] = useTranslation('global')
  const [logins, setLogins] = useState(null)
  const [activeButton, setActiveButton] = useState(false)
  const [loading, setLoading] = useState(false)

  const onChange = (e) => {
    setLogins({...logins, [e.target.name]: e.target.value})
  }

  const onSubmit = async(e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await axios.post(`${BACKEND_HOST}/api/user/login`, logins, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const res = response.data
      
      if(response && response.data.status == true){
          localStorage.setItem('token', res.token)
          localStorage.setItem('role', res.role)
          localStorage.setItem('name', res.name)
          localStorage.setItem('profile_image', res.profile_image)
          if(res.role === 'admin')
            window.location.pathname = '/admin'
          else if(res.role === 'advertiser'){
            localStorage.setItem('user', JSON.stringify(res.user))
            window.location.pathname = '/advertiser'
          }
          else if(res.role === 'staff')
            window.location.pathname = '/staff'
          else if(res.role === 'influencer')
            window.location.pathname = '/influencer'
          else 
            console.error('Invalid role:', res.role);
            
      }
      else{
        toast.error(res.message)
      }
      
    } catch (error) {
      console.log(error);
    }


    setLoading(false)
  }

  
  useEffect(()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(logins?.email?.length >=5 && logins?.password?.length >= 5){
      setActiveButton(true)
    } 
    else
      setActiveButton(false)
  }, [logins])
  return (
    <div className='flex flex-col gap-4 h-screen overflow-y-scroll pb-32'>
    <span className='px-3'>
      <h1 className='text-black font-bold text-2xl mt-8'>{t('loginPage.heading')}</h1>
      <p className='text-grey text-base font-semibold mt-2'>{t('loginPage.text')}</p>
    </span>

    <form onSubmit={onSubmit} className='flex flex-col gap-5 mt-10 px-3'>

      <Input label={t('loginPage.idLabel')} placeholder={t('loginPage.idplaceholder')} type="text" minLength={5} maxLength={15} name="email" required={true} onChange={onChange}> 
        <img src={require('../assets/user-square.png')} alt='company' className='w-[25px]'/>
      </Input>
      <Input label={t('loginPage.passlabel')} placeholder={t('loginPage.passPlaceholder')} type="password" name="password" required={true} onChange={onChange} minLength={5} maxLength={20}> 
         <img src={require('../assets/lock.png')} alt='company' className='w-[25px]'/>
      </Input>

        <span className='mt-2'></span>
      <Button title={t('loginPage.buttonTitle')} active={activeButton} disabled={loading}/>
    </form>

    <hr className='bg-grey h-[1px] w-full opacity-20 mt-4'/>
    <p className='text-grey text-sm font-semibold text-center my-4'>{t('loginPage.or')}</p>
    <Link to='/signup' className="px-5 py-4 mb-16 text-sm text-center text-black font-semibold bg-white shadow-sm font-medium rounded-[50px] border-[1px] border-grey/50">{t('loginPage.Request_an_Accout')}</Link>
    <MainFooter />
  </div>

  )
}

export default Login
