import React, { useContext, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { MdOutlineCategory } from "react-icons/md";
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import { AdvertiserContext } from '../state/AdvertiserProvider';

function Footer( { selectedPackageForChat } ) {
  const [t] = useTranslation("global")
  const location = useLocation()


  const handleChat = () => {
    if (window.innerWidth > 768)
      window.open("https://desk.channel.io/", '_blank');
    else {
      window.open("https://apps.apple.com/kr/app/%EC%B1%84%EB%84%90%ED%86%A1/id1088828788", '_blank');
    }
  }

  useEffect(()=>{
    
  }, [])

  useEffect(() => {
    ChannelService.loadScript();
    let user = JSON.parse(localStorage.getItem('user'))
    if (user){
      let config = {
        "pluginKey": "14f1f137-9fe3-4592-9f53-ce0846d0ec84", // fill your plugin key
        "customLauncherSelector": ".custom-button-1, #custom-button-2",
        "hideChannelButtonOnBoot": true,
        "profile": {
          "name": user?.name,
          "mobileNumber": user?.mobile,
          "landlineNumber": user?.telephone,
          "url": location.pathname,
        }
      }
      if(selectedPackageForChat){
        config.profile.package_id = selectedPackageForChat
      }
      ChannelService.boot(config);
    }
  }, [location, selectedPackageForChat])

  return (
    <div className='bg-white fixed bottom-0 sm:w-full'>
      <div className='sm:w-full w-[400px] flex justify-between items-center py-3 px-7 gap-4 sm:px-3'>

        {localStorage.getItem('role') == 'advertiser' &&
          <>
            <NavLink to='/advertiser/products' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.16992 6.93994L11.9999 12.0499L20.7699 6.96991" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 21.1099V12.0399" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.93011 1.98L4.59012 4.95003C3.38012 5.62003 2.39014 7.30001 2.39014 8.68001V14.33C2.39014 15.71 3.38012 17.39 4.59012 18.06L9.93011 21.03C11.0701 21.66 12.9401 21.66 14.0801 21.03L19.4201 18.06C20.6301 17.39 21.6201 15.71 21.6201 14.33V8.68001C21.6201 7.30001 20.6301 5.62003 19.4201 4.95003L14.0801 1.98C12.9301 1.34 11.0701 1.34 9.93011 1.98Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.9998 12.74V9.08002L7.50977 3.59998" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('advertiseNav.product')}</p>
            </NavLink>
            <NavLink to='/advertiser/package' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.25 9.5V14.5C22.25 19.5 20.25 21.5 15.25 21.5H9.25C4.25 21.5 2.25 19.5 2.25 14.5V8.5C2.25 3.5 4.25 1.5 9.25 1.5H14.25" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.25 9.5H18.25C15.25 9.5 14.25 8.5 14.25 5.5V1.5L22.25 9.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.25 12.5H13.25" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.25 16.5H11.25" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('advertiseNav.Package')}</p>
            </NavLink>
            <NavLink to='/advertiser/send' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.39969 5.82003L15.8897 2.99003C19.6997 1.72003 21.7697 3.80003 20.5097 7.61003L17.6797 16.1C15.7797 21.81 12.6597 21.81 10.7597 16.1L9.91969 13.58L7.39969 12.74C1.68969 10.84 1.68969 7.73003 7.39969 5.82003Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.1104 13.1501L13.6904 9.56006" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('advertiseNav.Send')}</p>
            </NavLink>
            <NavLink to='/advertiser/tracking' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.54004 7.27998V17.01C2.54004 18.91 3.89004 19.69 5.53004 18.75L7.88004 17.41C8.39004 17.12 9.24004 17.09 9.77004 17.36L15.02 19.99C15.55 20.25 16.4 20.23 16.91 19.94L21.24 17.46C21.79 17.14 22.25 16.36 22.25 15.72V5.98998C22.25 4.08998 20.9 3.30998 19.26 4.24998L16.91 5.58998C16.4 5.87998 15.55 5.90998 15.02 5.63998L9.77004 3.01998C9.24004 2.75998 8.39004 2.77998 7.88004 3.06998L3.55004 5.54998C2.99004 5.86998 2.54004 6.64998 2.54004 7.27998Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.80957 3.5V16.5" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.9805 6.12V19.5" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('advertiseNav.Tracking')}</p>
            </NavLink>
            <button to='/advertiser/chat' className={'custom-button-1 flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1596 10.37C12.0596 10.36 11.9396 10.36 11.8296 10.37C9.44957 10.29 7.55957 8.34 7.55957 5.94C7.55957 3.49 9.53957 1.5 11.9996 1.5C14.4496 1.5 16.4396 3.49 16.4396 5.94C16.4296 8.34 14.5396 10.29 12.1596 10.37Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.15973 14.06C4.73973 15.68 4.73973 18.32 7.15973 19.93C9.90973 21.77 14.4197 21.77 17.1697 19.93C19.5897 18.31 19.5897 15.67 17.1697 14.06C14.4297 12.23 9.91973 12.23 7.15973 14.06Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('advertiseNav.chat')}</p>
            </button>
          </>
        }
        {localStorage.getItem('role') == 'staff' &&
          <>
            <NavLink to='/staff/list' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 21.5H15C20 21.5 22 19.5 22 14.5V8.5C22 3.5 20 1.5 15 1.5H9C4 1.5 2 3.5 2 8.5V14.5C2 19.5 4 21.5 9 21.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.75 8.5H8.25" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.75 14.5H8.25" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('staffNav.list')}</p>
            </NavLink>
            <NavLink to='/staff/verify' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.1904 1.73L6.20035 3.61C5.05035 4.04 4.11035 5.4 4.11035 6.62V14.05C4.11035 15.23 4.89035 16.78 5.84035 17.49L10.1404 20.7C11.5504 21.76 13.8704 21.76 15.2804 20.7L19.5804 17.49C20.5304 16.78 21.3104 15.23 21.3104 14.05V6.62C21.3104 5.39 20.3704 4.03 19.2204 3.6L14.2304 1.73C13.3804 1.42 12.0204 1.42 11.1904 1.73Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.75 11.37L11.36 12.98L15.66 8.67999" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('staffNav.verify')}</p>
            </NavLink>
            <NavLink to='/staff/packages' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.9004 9.5V14.5C22.9004 19.5 20.9004 21.5 15.9004 21.5H9.90039C4.90039 21.5 2.90039 19.5 2.90039 14.5V8.5C2.90039 3.5 4.90039 1.5 9.90039 1.5H14.9004" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.9004 9.5H18.9004C15.9004 9.5 14.9004 8.5 14.9004 5.5V1.5L22.9004 9.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.90039 12.5H13.9004" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.90039 16.5H11.9004" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('staffNav.package')}</p>
            </NavLink>
            <NavLink to='/staff/send' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.4993 5.82L15.9893 2.99C19.7993 1.72 21.8693 3.8 20.6093 7.61L17.7793 16.1C15.8793 21.81 12.7593 21.81 10.8593 16.1L10.0193 13.58L7.4993 12.74C1.7893 10.84 1.7893 7.73 7.4993 5.82Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.21 13.15L13.79 9.56" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('staffNav.send')}</p>
            </NavLink>
            <a onClick={handleChat} className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.7999 6.66C18.7399 6.65 18.6699 6.65 18.6099 6.66C17.2299 6.61 16.1299 5.48 16.1299 4.08C16.1299 2.65 17.2799 1.5 18.7099 1.5C20.1399 1.5 21.2899 2.66 21.2899 4.08C21.2799 5.48 20.1799 6.61 18.7999 6.66Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17.7702 13.94C19.1402 14.17 20.6502 13.93 21.7102 13.22C23.1202 12.28 23.1202 10.74 21.7102 9.80001C20.6402 9.09001 19.1102 8.85 17.7402 9.09" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.77028 6.66C6.83028 6.65 6.90027 6.65 6.96027 6.66C8.34027 6.61 9.44027 5.48 9.44027 4.08C9.44027 2.65 8.29028 1.5 6.86028 1.5C5.43028 1.5 4.28027 2.66 4.28027 4.08C4.29027 5.48 5.39028 6.61 6.77028 6.66Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.80023 13.94C6.43023 14.17 4.92023 13.93 3.86023 13.22C2.45023 12.28 2.45023 10.74 3.86023 9.80001C4.93023 9.09001 6.46023 8.85 7.83023 9.09" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.7999 14.13C12.7399 14.12 12.6699 14.12 12.6099 14.13C11.2299 14.08 10.1299 12.95 10.1299 11.55C10.1299 10.12 11.2799 8.97 12.7099 8.97C14.1399 8.97 15.2899 10.13 15.2899 11.55C15.2799 12.95 14.1799 14.09 12.7999 14.13Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.88953 17.28C8.47953 18.22 8.47953 19.76 9.88953 20.7C11.4895 21.77 14.1095 21.77 15.7095 20.7C17.1195 19.76 17.1195 18.22 15.7095 17.28C14.1195 16.22 11.4895 16.22 9.88953 17.28Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('staffNav.customer')}</p>
            </a>
            <NavLink to='/staff/mybox' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.16992 6.94L11.9999 12.05L20.7699 6.97" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 21.11V12.04" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.92965 1.98001L4.58965 4.94001C3.37965 5.61001 2.38965 7.29001 2.38965 8.67001V14.32C2.38965 15.7 3.37965 17.38 4.58965 18.05L9.92965 21.02C11.0696 21.65 12.9396 21.65 14.0796 21.02L19.4196 18.05C20.6296 17.38 21.6196 15.7 21.6196 14.32V8.67001C21.6196 7.29001 20.6296 5.61001 19.4196 4.94001L14.0796 1.97001C12.9296 1.34001 11.0696 1.34001 9.92965 1.98001Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('staffNav.mybox')}</p>
            </NavLink>
          </>
        }

        {localStorage.getItem('role') == 'admin' &&
          <>
            <NavLink to='/admin/advertisers' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 21.5H15C20 21.5 22 19.5 22 14.5V8.5C22 3.5 20 1.5 15 1.5H9C4 1.5 2 3.5 2 8.5V14.5C2 19.5 4 21.5 9 21.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 9.5C10.1046 9.5 11 8.60457 11 7.5C11 6.39543 10.1046 5.5 9 5.5C7.89543 5.5 7 6.39543 7 7.5C7 8.60457 7.89543 9.5 9 9.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.66992 18.4501L7.59992 15.1401C8.38992 14.6101 9.52992 14.6701 10.2399 15.2801L10.5699 15.5701C11.3499 16.2401 12.6099 16.2401 13.3899 15.5701L17.5499 12.0001C18.3299 11.3301 19.5899 11.3301 20.3699 12.0001L21.9999 13.4001" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('adminNav.Advertiser')}</p>
            </NavLink>
            <NavLink to='/admin/influencers' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.3302 8.08003V14.92C21.3302 16.04 20.7302 17.08 19.7602 17.65L13.8202 21.08C12.8502 21.64 11.6502 21.64 10.6702 21.08L4.73016 17.65C3.76016 17.09 3.16016 16.05 3.16016 14.92V8.08003C3.16016 6.96003 3.76016 5.91999 4.73016 5.34999L10.6702 1.92C11.6402 1.36 12.8402 1.36 13.8202 1.92L19.7602 5.34999C20.7302 5.91999 21.3302 6.95003 21.3302 8.08003Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.2499 10.5001C13.5367 10.5001 14.5799 9.45687 14.5799 8.17004C14.5799 6.88322 13.5367 5.84009 12.2499 5.84009C10.9631 5.84009 9.91992 6.88322 9.91992 8.17004C9.91992 9.45687 10.9631 10.5001 12.2499 10.5001Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.25 16.1601C16.25 14.3601 14.46 12.9001 12.25 12.9001C10.04 12.9001 8.25 14.3601 8.25 16.1601" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('adminNav.Influencer')}</p>
            </NavLink>
            <NavLink to='/admin/staff' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.0001 6.66C17.9401 6.65 17.8701 6.65 17.8101 6.66C16.4301 6.61 15.3301 5.48 15.3301 4.08C15.3301 2.65 16.4801 1.5 17.9101 1.5C19.3401 1.5 20.4901 2.66 20.4901 4.08C20.4801 5.48 19.3801 6.61 18.0001 6.66Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.9704 13.94C18.3404 14.17 19.8504 13.93 20.9104 13.22C22.3204 12.28 22.3204 10.74 20.9104 9.80004C19.8404 9.09004 18.3104 8.85003 16.9404 9.09003" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.97047 6.66C6.03047 6.65 6.10047 6.65 6.16047 6.66C7.54047 6.61 8.64047 5.48 8.64047 4.08C8.64047 2.65 7.49047 1.5 6.06047 1.5C4.63047 1.5 3.48047 2.66 3.48047 4.08C3.49047 5.48 4.59047 6.61 5.97047 6.66Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.00043 13.94C5.63043 14.17 4.12043 13.93 3.06043 13.22C1.65043 12.28 1.65043 10.74 3.06043 9.80004C4.13043 9.09004 5.66043 8.85003 7.03043 9.09003" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.0001 14.13C11.9401 14.12 11.8701 14.12 11.8101 14.13C10.4301 14.08 9.33008 12.95 9.33008 11.55C9.33008 10.12 10.4801 8.96997 11.9101 8.96997C13.3401 8.96997 14.4901 10.13 14.4901 11.55C14.4801 12.95 13.3801 14.09 12.0001 14.13Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.08973 17.28C7.67973 18.22 7.67973 19.76 9.08973 20.7C10.6897 21.77 13.3097 21.77 14.9097 20.7C16.3197 19.76 16.3197 18.22 14.9097 17.28C13.3197 16.22 10.6897 16.22 9.08973 17.28Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('adminNav.Staff')}</p>
            </NavLink>
            <NavLink to='/admin/category' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <MdOutlineCategory className='text-2xl text-grey' />
              <p className='text-[12px] text-grey'>{t('adminNav.cate')}</p>
            </NavLink>
            <NavLink to='/admin/fee' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.75 5.5V7.92C22.75 9.5 21.75 10.5 20.17 10.5H16.75V3.51C16.75 2.4 17.66 1.5 18.77 1.5C19.86 1.51 20.86 1.95 21.58 2.67C22.3 3.4 22.75 4.4 22.75 5.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.75 6.5V20.5C2.75 21.33 3.68998 21.8 4.34998 21.3L6.06 20.02C6.46 19.72 7.02 19.76 7.38 20.12L9.03998 21.79C9.42998 22.18 10.07 22.18 10.46 21.79L12.14 20.11C12.49 19.76 13.05 19.72 13.44 20.02L15.15 21.3C15.81 21.79 16.75 21.32 16.75 20.5V3.5C16.75 2.4 17.65 1.5 18.75 1.5H7.75H6.75C3.75 1.5 2.75 3.29 2.75 5.5V6.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.75 8.5H12.75" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.5 12.5H12" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('adminNav.Fee')}</p>
            </NavLink>
            <NavLink to='/admin/payments' className={'flex flex-col gap-[2px] justify-center items-center'}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 20H7C4 20 2 18.5 2 15V8C2 4.5 4 3 7 3H17C20 3 22 4.5 22 8V15C22 18.5 20 20 17 20Z" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 14.5C13.6569 14.5 15 13.1569 15 11.5C15 9.84315 13.6569 8.5 12 8.5C10.3431 8.5 9 9.84315 9 11.5C9 13.1569 10.3431 14.5 12 14.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19 6.5H16" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 16.5H5" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-[12px] text-grey'>{t('adminNav.Payment')}</p>
            </NavLink>
          </>
        }

      </div>
    </div >
  )
}

export default Footer
