import React from 'react'
import Header from '../components/Header'
import { Navigate, Route, Routes } from 'react-router-dom'
import PackagesToArive from '../pages/PackagesToArive'
import Profile from '../pages/Profile'
import PackageToAriveList from '../pages/PackageToAriveList'

function Influencer() {
    return (
        <div className='relativpe overflow-x-hidden'>
            <Header />
            <div className='relative px-5 mt-[90px] py-6 mb-[100px]'>
                <Routes>
                    <Route index element={<Navigate to='/influencer/packages' />} />
                    <Route path='packages' element={<PackagesToArive />}/>
                    <Route path='packages/:_id' element={<PackageToAriveList />}/>
                    <Route path='profile' element={<Profile />}/>
                </Routes>
            </div>
        </div>
    )
}

export default Influencer
