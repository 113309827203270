import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { filterStyles } from '../utils/selectStyles'
import Loader from '../components/Loader'
import { BACKEND_HOST } from '../constants/Index'
import { toast } from 'react-toastify'
import { getSeoulDate, getSeoulTime } from '../utils/getSeoulTime'

function Payments({ setRefund, refund }) {
    const [payments, setPayments] = useState(null)
    const [sort, setSort] = useState(0)


    const fetchPayments = async (advertiserOrPackageId = '') => {
        const response = await fetch(`${BACKEND_HOST}/api/payments?adv_or_p_id=${advertiserOrPackageId}&sort=${sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            setPayments(res)
        }
        else
            toast.error(res.message)
    }

    const search = (e) => {
        fetchPayments(e.target.value)
    }

    useEffect(() => {
        setPayments([])
        fetchPayments()
    }, [sort, refund])
    return (
        <div className='relative min-h-[300px] max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
            {!payments && <Loader />}
            {payments && <div className='flex gap-2'>
                <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[65%]'>
                    <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
                <span className='w-[35%]'>
                    <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: 0, label: 'Newest' }} options={[{ value: 0, label: 'Newest' }, { value: 1, label: 'A-Z' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
                </span>
            </div>}
            <div class='overflow-x-auto w-full'>
                <table>
                    <thead class='bg-blue rounded-lg block'>
                        <tr>
                            <th class='py-1 text-white text-[12px] font-medium min-w-[100px] text-left pl-2'>Date</th>
                            <th class='py-1 text-white text-[12px] font-medium min-w-[100px] text-left '>Advertiser</th>
                            {/* <th class='py-1 text-white text-[12px] font-medium min-w-[80px] text-left '>Amount</th> */}
                            <th class='py-1 text-white text-[12px] font-medium min-w-[80px] text-left '>Left Amount</th>
                            <th class='py-1 text-white text-[12px] font-medium min-w-[70px] text-center'>Refund</th>
                            <th class='py-1 text-white text-[12px] font-medium min-w-[100px] text-left'>Package ID</th>
                            <th class='py-1 text-white text-[12px] font-medium min-w-[240px] text-left '>Payment ID</th>
                            <th class='py-1 text-white text-[12px] font-medium min-w-[80px] text-left '>Refunded</th>
                        </tr>
                    </thead>
                    <tbody class='bg-blue/10  rounded-lg block mt-1'>
                        {payments?.map((p, index) => {
                        return <tr>
                            <td class='py-1 text-black text-[12px] font-medium min-w-[100px] text-left pl-2'>{getSeoulTime(p.paymentDate)?.substr(0, 10)} <br /> {getSeoulDate(p.paymentDate)}</td>
                            <td class='py-1 text-black text-[12px] font-medium min-w-[100px] text-left '>{p.advertiser?.companyName}</td>
                            {/* <td class='py-1 text-black text-[12px] font-medium min-w-[40px] mr-[40px] text-right '>{p.totalPayment?.toLocaleString()}</td> */}
                            <td class='py-1 text-black text-[12px] font-medium min-w-[70px] mr-[40px] text-right '>{(Number(p.totalPayment) - Number(p.refund_amount))?.toLocaleString()}</td>
                            <td class='py-1 text-[12px] font-medium min-w-[100px] text-center text-white'>
                                <button onClick={()=>setRefund(p)} className='bg-blue/70 text-[10px] rounded-lg px-2 py-1 uppercase'>
                                        oKAY
                                </button>
                            </td>
                            <td class='py-1 text-black text-[12px] font-medium min-w-[100px] text-left '>{p.packageID}</td>
                            <td class='py-1 text-black text-[12px] font-medium min-w-[240px] text-left whitespace-nowrap'>{p.paymentID}</td>
                            <td class='py-1 text-black text-[12px] font-medium min-w-[80px] text-left '>{p.refund_amount?.toLocaleString() || 0}</td>
                        </tr>})}
                    </tbody>
                </table>
            </div>


        </div>
    )
}

export default Payments
