import React from 'react'

function Input2(props) {
  return (
    <div className='flex flex-col gap-1'>
    <label className='text-black text-lg font-normal'>{props.label}</label>
    <div
    className={`flex justify-start items-center gap-2 bg-white border-blue border-[1px] rounded-lg px-3 py-2 transition-all ease-in duration-150`}
    >
    {props.children}
    <input
        type={props.type}
        onChange={props.onChange}
        required={props.required}
        name={props.name}
        defaultValue={props.value || ''}
        placeholder={props.placeholder}
        className='w-full outline-none text-grey text-lg'
    />
    </div>
</div>
  )
}

export default Input2
