import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineCategory } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import Input2 from '../components/Input2'
import Button from '../components/Button'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants/Index'
import Input from '../components/Input'

function AddCategory() {
    const [t] = useTranslation("global")
    const navigate = useNavigate()

    const { id, name } = useParams()

    const [loading, setLoading] = useState(false)
    const [category, setCategory] = useState(name || '')
    const [activeBtn, setActiveBtn] = useState(false)

    const addNew = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/category`, {
            method: 'POST',
            headers: {
                "Content-Type": "Application/json",
                token: localStorage.getItem('token')
            },
            body: JSON.stringify({ name: category })
        })

        const res = await response.json()
        if (response.status === 201) {
            toast.success(res.message)
            navigate(-1)
        }
        else
            toast.error(res.message)
    }
    const update = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/category/${id}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "Application/json",
                token: localStorage.getItem('token')
            },
            body: JSON.stringify({ name: category })
        })

        const res = await response.json()
        if (response.status === 200) {
            toast.success(res.message)
            navigate(-1)
        }
        else
            toast.error(res.message)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (id)
            await update()
        else
            await addNew()
        setLoading(false)
    }

    useEffect(() => {
        if (category.length >= 1)
            setActiveBtn(true)
        else
            setActiveBtn(false)
    }, [category])
    return (
        <form className={`top-0 fixed transition-all ease-in duration-200 w-[400px] md:right-[100px] right-[300px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `} onSubmit={onSubmit}>
            <div className='w-[400px]  h-screen m-auto bg-white flex flex-col gap-4 px-7 pb-24 sm:w-full'>

                <div className='flex h-[50px] w-full justify-start items-center mt-8'>
                    <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={() => navigate(-1)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <p className='text-black text-base capitalize text-center w-full -ml-[30px]'>{t('categoryPage.addNew')}</p>
                </div>

                <Input minLength={1} value={category} maxLength={70} label={t('formLabels.cat')} type="text" name="category" required={true} onChange={(e) => setCategory(e.target.value)}>
                    <MdOutlineCategory className={`text-2xl ${category.length > 0 ? 'text-blue' : 'text-grey'}`} />
                </Input>
                <Button disabled={loading} active={activeBtn} title={id ? t("formLabels.update_btn") : t("formLabels.submitBtn")} />
            </div>
        </form>
    )
}

export default AddCategory
