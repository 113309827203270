export const filterStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      border: '0px',
      boxShadow: 'none',
      padding: '3px 0px 3px 6px',
      fontFamily: 'Outfit',
      fontSize: '14px',
      color:'#fff',
      backgroundColor:'#F9F9F9',
      cursor: 'pointer',
   
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F9F9F9' : '#F9F9F9',
      color: '#191D31', // Set the color of the option text to white
      padding: '8px 29px 8px 10px',
      fontSize: '14px',
      cursor: 'pointer',
      fontFamily: 'Outfit',
      borderBottom: '1px solid #F9F9F9',
      borderTop: 'none',
      marginBottom: '0',
      marginTop: '0',
      transition: '0.2s ease-in',
      '&:hover': {
        backgroundColor: '#F9F9F9',
        color: '#191D31'
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      background: '#F9F9F9',
      boxShadow: 'none',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      marginTop: '-7px',
      marginBottom: '0',
      borderRadius: '5px',
      minHeight: '0px',
      maxHeight: '100px',
      backgroundColor: '#F9F9F9',
      border: '1px solid #F9F9F9', // Add borderTop to the menuList
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      fontSize: '10px',
      width: '40px',
      transition: 'transform 0.3s', // Add transition for smooth rotation effect
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null, // Rotate the arrow when menu is open
      color: '#191D31'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#191D31' // Set the color of the selected text to white
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#191D31' // Set the color of the placeholder text to white
    })
  };


export const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      border: '0px',
      boxShadow: 'none',
      padding: '0px 0px',
      fontFamily: 'Outfit',
      fontSize: '14px',
      color:'#A7A9B7',
      backgroundColor:'#fff',
      cursor: 'pointer',
   
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F9F9F9' : '#F9F9F9',
      color: '#A7A9B7', // Set the color of the option text to white
      padding: '8px 29px 8px 10px',
      fontSize: '14px',
      cursor: 'pointer',
      fontFamily: 'Outfit',
      borderBottom: '1px solid #F9F9F9',
      borderTop: 'none',
      marginBottom: '0',
      marginTop: '0',
      transition: '0.2s ease-in',
      '&:hover': {
        backgroundColor: '#F9F9F9',
        color: '#A7A9B7'
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      background: '#F9F9F9',
      boxShadow: 'none',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      marginTop: '-7px',
      marginBottom: '0',
      borderRadius: '5px',
      backgroundColor: '#F9F9F9',
      border: '1px solid #F9F9F9', // Add borderTop to the menuList
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      fontSize: '10px',
      width: '40px',
      transition: 'transform 0.3s', // Add transition for smooth rotation effect
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null, // Rotate the arrow when menu is open
      color: '#191D31'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#191D31' // Set the color of the selected text to white
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A7A9B7' // Set the color of the placeholder text to white
    })
  };