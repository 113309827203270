import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants/Index'
import { AdvertiserContext } from '../state/AdvertiserProvider'
import Loader from '../components/Loader'

function VerifyPackage({ }) {
    const [t] = useTranslation("global")
    const navigate = useNavigate()


    const [packageID, setPackageID] = useState(null)
    const [packageItems, setPackagesItems] = useState(null)
    const [totalPayment, setTotalPayment] = useState(0)

    const { _id } = useParams()

    const fetchPackageToBeVerified = async () => {
        const resposne = await fetch(`${BACKEND_HOST}/api/packages/return_package_to_be_edit/${_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            },
        })

        const res = await resposne.json()
        if (resposne.status === 200) {
            setPackageID(res.packageId)
            setPackagesItems(res.items)
            setTotalPayment(res.totalPayment)
        }
        else
            toast.error(res.message)
        return false
    }


    useEffect(() => {
        fetchPackageToBeVerified()
    }, [_id])
    useEffect(() => {
    }, [packageID, packageItems, totalPayment])


    return (
        <div className={`top-0 fixed transition-all ease-in duration-200 w-[400px] md:right-[100px] md:right-[100px] right-[300px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `}>
            <div className='w-[400px] relative m-auto bg-white h-screen flex flex-col px-7 pb-16 sm:w-full'>
                {!packageItems && <Loader height={'h-screen'}/>}
               {packageItems && <>
                    <div className='flex h-[50px] w-full justify-start items-start mt-8'>
                        <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={() => navigate(-1)}>
                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <div className='flex flex-col gap-1 justify-center items-center w-full -ml-[30px] '>
                            <p className='text-black text-base font-medium text-center'>{t('packagePage.pack')}</p>
                            <p className='text-black text-base font-medium text-center leading-[10px]'>{packageID}</p>
                        </div>
                    </div>

                    <div className='flex justify-between mt-5 items-center gap-1 px-3 -mx-4 pb-2'>
                        <p className='text-black text-base font-medium'>{t('packagePage.product')} *</p>
                        <p className='text-black text-base font-medium w-[120px]'>{t('packagePage.to')} *</p>
                    </div>

                    <div className='flex flex-col gap-3 h-[600px] pb-[70px] overflow-scroll -mx-4 px-2'>
                        {packageItems?.map((x, index) => {
                            return <Item key={index} index={index} item={x}  package_id={packageID} setPackagesItems={setPackagesItems}/>
                        })}
                    </div>
                </>}
            </div>
        </div>
    )
}

export default VerifyPackage



const Item = ({ item, package_id, setPackagesItems }) => {
    const [t] = useTranslation("global")
    const [commentSection, setCommentSection] = useState(false)
    const [loading, setLoading] = useState(false)


    const statusUpdate = async(status) => {
        setLoading(true)
        const resposne = await fetch(`${BACKEND_HOST}/api/packages/staff/item_status`, {
            method: 'PUT', // Fix the typo here
            headers: {
                'Content-Type': 'application/json', // Fix the typo here (should be 'application/json')
                token: localStorage.getItem('token')
            },
            body: JSON.stringify({ item_id: item._id, staff_status: status, package_id })
        });

        const res = await resposne.json()
        if (resposne.status === 200) {
            setPackagesItems(res.items)
            toast.success(res.message)
        }
        else
            toast.error(res.message)
        setLoading(false)
    }



    return (
        <div className='px-3 pt-2 pb-3 shadow-lg rounded-lg mt-1 relative'>
            <form onSubmit={(e) => e.preventDefault()} className='flex flex-col gap-4  '>
                <div className='flex justify-between items-center gap-1'>
                    <div className='cursor-pointer flex flex-col gap-1 justify-center items-center border-[1px] bg-[#F2F4F9] border-blue border-dashed rounded-lg w-[120px] h-[120px]'>
                        {item.product ?
                            <>
                                <img src={`${BACKEND_HOST}/${item.product.image}`} alt='product' className='w-[46px] h-[46px] rounded-md object-cover' />
                                <p className='text-[12px] text-grey'>{item.product.title}</p>
                                <p className='text-sm text-black font-semibold -mt-1'>{item.product_quantity}</p>
                            </>
                            :
                            <>
                                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M36.4187 43.6042C33.7354 43.6042 31.2246 42.4351 29.4996 40.3842C29.4804 40.3459 29.3654 40.25 29.2696 40.0967C29.0587 39.8859 28.8096 39.5025 28.5987 39.1384C27.7554 37.7775 27.2954 36.1675 27.2954 34.4809C27.2954 31.6825 28.5412 29.0951 30.7071 27.3701C32.3171 26.086 34.3487 25.3767 36.3996 25.3767C38.6229 25.3767 40.6737 26.1434 42.3221 27.5809C42.5521 27.7343 42.8204 28.0217 43.0696 28.2901C44.6221 29.9959 45.4846 32.1809 45.4846 34.4617C45.4846 36.1292 45.0246 37.7584 44.1621 39.1575C43.7021 39.9625 43.0696 40.6909 42.3412 41.3042C40.7887 42.7992 38.6612 43.6042 36.4187 43.6042ZM36.4187 28.2709C35.0004 28.2709 33.6587 28.75 32.5279 29.6508C31.0521 30.82 30.1896 32.6026 30.1896 34.5001C30.1896 35.6309 30.4962 36.7425 31.0904 37.7009C31.2437 37.9692 31.3971 38.1801 31.5696 38.391C31.6079 38.4293 31.7229 38.5442 31.8187 38.6976C32.8729 39.9434 34.5979 40.7484 36.4187 40.7484C37.9329 40.7484 39.3896 40.1926 40.5012 39.1959C40.9996 38.7743 41.4212 38.2759 41.7279 37.7392C42.3412 36.7617 42.6479 35.6501 42.6479 34.5192C42.6479 32.9667 42.0537 31.4525 40.9804 30.2834C40.8462 30.13 40.6929 29.9768 40.5396 29.8618C39.3321 28.7884 37.9329 28.2709 36.4187 28.2709Z" fill="#8C8E98" />
                                    <path d="M39.2738 35.8992H33.543C32.7571 35.8992 32.1055 35.2475 32.1055 34.4617C32.1055 33.6758 32.7571 33.0242 33.543 33.0242H39.2738C40.0596 33.0242 40.7113 33.6758 40.7113 34.4617C40.7113 35.2475 40.0788 35.8992 39.2738 35.8992Z" fill="#8C8E98" />
                                    <path d="M36.418 38.8316C35.6321 38.8316 34.9805 38.18 34.9805 37.3941V31.6633C34.9805 30.8775 35.6321 30.2258 36.418 30.2258C37.2038 30.2258 37.8555 30.8775 37.8555 31.6633V37.3941C37.8555 38.1991 37.2038 38.8316 36.418 38.8316Z" fill="#8C8E98" />
                                    <path d="M23.0014 25.4914C22.7522 25.4914 22.503 25.4339 22.273 25.2997L5.34884 15.5056C4.65884 15.1031 4.42886 14.2214 4.83136 13.5314C5.23386 12.8414 6.11551 12.6113 6.78634 13.0138L22.9822 22.3864L39.0822 13.0714C39.7722 12.6689 40.6539 12.9181 41.0372 13.5889C41.4397 14.2789 41.1905 15.1605 40.5197 15.563L23.7105 25.2997C23.4997 25.4147 23.2505 25.4914 23.0014 25.4914Z" fill="#8C8E98" />
                                    <path d="M23.0015 42.8563C22.2156 42.8563 21.564 42.2047 21.564 41.4188V24.0347C21.564 23.2488 22.2156 22.5972 23.0015 22.5972C23.7873 22.5972 24.439 23.2488 24.439 24.0347V41.4188C24.439 42.2047 23.7873 42.8563 23.0015 42.8563Z" fill="#8C8E98" />
                                    <path d="M23.0013 43.6041C21.3146 43.6041 19.628 43.2399 18.3246 42.4924L8.08965 36.8191C5.31048 35.2858 3.12549 31.5866 3.12549 28.4049V17.5565C3.12549 14.3748 5.31048 10.6949 8.08965 9.14243L18.3246 3.46906C20.9313 1.99322 25.033 1.99322 27.6588 3.46906L37.8938 9.14243C40.673 10.6758 42.858 14.3748 42.858 17.5565V28.4049C42.858 28.5966 42.858 28.7498 42.8196 28.9415C42.7238 29.4398 42.3597 29.8615 41.8805 30.0148C41.4013 30.1873 40.8646 30.0724 40.4621 29.7466C38.258 27.8299 34.8463 27.7533 32.5271 29.6124C31.0513 30.7816 30.1888 32.564 30.1888 34.4615C30.1888 35.5924 30.4955 36.7041 31.0896 37.6624C31.243 37.9308 31.3963 38.1416 31.5688 38.3524C31.8563 38.6782 31.9713 39.1191 31.8946 39.5407C31.818 39.9624 31.5496 40.3266 31.1663 40.5374L27.6588 42.4732C26.3555 43.2398 24.688 43.6041 23.0013 43.6041ZM23.0013 5.27073C21.813 5.27073 20.6055 5.5199 19.743 5.99907L9.50799 11.6724C7.64883 12.6883 6.0388 15.4482 6.0388 17.5565V28.4049C6.0388 30.5132 7.66799 33.2733 9.50799 34.2891L19.743 39.9623C21.4872 40.9398 24.5347 40.9398 26.2788 39.9623L28.4255 38.774C27.7163 37.4899 27.333 35.9949 27.333 34.4615C27.333 31.6632 28.5788 29.0758 30.7446 27.3508C33.3513 25.2616 37.0888 24.8207 40.0021 26.0665V17.5183C40.0021 15.4099 38.373 12.6499 36.533 11.6341L26.298 5.9607C25.3971 5.51987 24.1896 5.27073 23.0013 5.27073Z" fill="#8C8E98" />
                                </svg>
                                <p className='text-[12px] text-grey'>{(t("packagePage.selectPro"))}</p>

                            </>
                        }
                    </div>
                    <span className='p-3 border-blue/50 border-[1px] bg-[#F2F4F9] rounded-full flex justify-center items-center'>
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2275 5.61255L18.7917 11.1767L13.2275 16.7409" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.2085 11.1768H18.636" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <div className='cursor-pointer flex flex-col gap-1 justify-center items-center border-[1px] bg-[#F2F4F9] border-blue border-dashed rounded-lg w-[120px] h-[120px]'>
                        {item.influencer ?
                            <>
                                <img src={`${BACKEND_HOST}/${item.influencer.profile_image}`} alt='product' className='w-[46px] h-[46px] rounded-md object-cover' />
                                <p className='text-[12px] text-grey'>{item.influencer.name}</p>
                                <p className='text-sm text-black font-semibold -mt-1'>{item.influencer.fee?.area_title}</p>
                            </>
                            :
                            <>
                                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.9984 24.4375C16.9225 24.4375 11.9775 19.4925 11.9775 13.4167C11.9775 7.34087 16.9225 2.39587 22.9984 2.39587C29.0742 2.39587 34.0192 7.34087 34.0192 13.4167C34.0192 19.4925 29.0742 24.4375 22.9984 24.4375ZM22.9984 5.27087C18.5134 5.27087 14.8525 8.93171 14.8525 13.4167C14.8525 17.9017 18.5134 21.5625 22.9984 21.5625C27.4834 21.5625 31.1442 17.9017 31.1442 13.4167C31.1442 8.93171 27.4834 5.27087 22.9984 5.27087Z" fill="#8C8E98" />
                                    <path d="M6.53467 43.6042C5.74883 43.6042 5.09717 42.9525 5.09717 42.1667C5.09717 33.9825 13.128 27.3125 22.9988 27.3125C24.9347 27.3125 26.8321 27.5617 28.6721 28.0792C29.4388 28.29 29.8797 29.0758 29.6688 29.8425C29.458 30.6092 28.6722 31.0501 27.9055 30.8392C26.3338 30.3984 24.6855 30.1875 22.9988 30.1875C14.7188 30.1875 7.97217 35.5542 7.97217 42.1667C7.97217 42.9525 7.3205 43.6042 6.53467 43.6042Z" fill="#8C8E98" />
                                    <path d="M34.4987 43.6042C32.237 43.6042 30.0903 42.7609 28.4228 41.2467C27.752 40.6717 27.1579 39.9625 26.6979 39.1767C25.8545 37.7967 25.3945 36.1675 25.3945 34.5C25.3945 32.1042 26.3145 29.8617 27.9629 28.1559C29.6879 26.3734 32.007 25.3959 34.4987 25.3959C37.1054 25.3959 39.5779 26.5076 41.2645 28.4242C42.7595 30.0917 43.6029 32.2384 43.6029 34.5C43.6029 35.2284 43.507 35.9567 43.3154 36.6467C43.1237 37.5092 42.7595 38.4101 42.2612 39.1959C40.6703 41.9176 37.6804 43.6042 34.4987 43.6042ZM34.4987 28.2709C32.7929 28.2709 31.2212 28.9417 30.0329 30.1492C28.9021 31.3183 28.2695 32.8517 28.2695 34.5C28.2695 35.6309 28.5762 36.7425 29.1704 37.7008C29.477 38.2375 29.8795 38.7167 30.3395 39.1192C31.4895 40.1733 32.9654 40.7484 34.4987 40.7484C36.6645 40.7484 38.7154 39.5984 39.827 37.7392C40.1529 37.2025 40.402 36.5893 40.5362 35.9951C40.6704 35.4968 40.7279 35.0176 40.7279 34.5192C40.7279 32.9859 40.1529 31.5101 39.1179 30.3601C37.9679 29.0184 36.2812 28.2709 34.4987 28.2709Z" fill="#8C8E98" />
                                    <path d="M37.3729 35.8992H31.6421C30.8563 35.8992 30.2046 35.2475 30.2046 34.4617C30.2046 33.6758 30.8563 33.0242 31.6421 33.0242H37.3729C38.1587 33.0242 38.8104 33.6758 38.8104 34.4617C38.8104 35.2475 38.1587 35.8992 37.3729 35.8992Z" fill="#8C8E98" />
                                    <path d="M34.4985 38.8316C33.7127 38.8316 33.061 38.18 33.061 37.3941V31.6633C33.061 30.8775 33.7127 30.2258 34.4985 30.2258C35.2844 30.2258 35.936 30.8775 35.936 31.6633V37.3941C35.936 38.1991 35.2844 38.8316 34.4985 38.8316Z" fill="#8C8E98" />
                                </svg>
                                <p className='text-[12px] text-grey'>{(t("packagePage.selectInflu"))}</p>

                            </>
                        }
                    </div>
                </div>

                <div className='relative overflow-hidden '>
                    <div className='flex justify-between items-center cursor-pointer'>
                        <span className='flex justify-between items-center gap-3 w-full'>
                            <h2 className='text-blue text-sm font-medium' onClick={() => setCommentSection(state => !state)}>{t('packagePage.comment')}</h2>
                            <div className='flex justify-end items-center gap-4'>
                                <span className='flex justify-start items-center gap-1'>
                                    <input readOnly type='radio' name="return" checked={item.returnOrLeave === 'return'} value={"return"} required />
                                    <p className='text-black text-sm font-medium'>Return</p>
                                </span>
                                <span className='flex justify-start items-center gap-1'>
                                    <input readOnly type='radio' checked={item.returnOrLeave === 'leave'} name="return" value={"leave"} />
                                    <p className='text-black text-sm font-medium'>Leave</p>
                                </span>
                            </div>
                        </span>
                    </div>
                    <div className={` ${commentSection ? 'h-[100px]' : 'h-0 overflow-hidden'} mt-2 transition-all ease-in duration-200`}>
                        <textarea defaultValue={item?.comments} readOnly className='border-[1px] border-blue rounded-lg h-[100px] w-full outline-none resize-none text-sm p-2'></textarea>
                    </div>
                </div>
            </form>

            {item._id && localStorage.getItem('role') === 'staff' &&
                <div className='flex justify-between gap-4 mt-2'>
                    <button disabled={loading} onClick={() => statusUpdate('okay')} className='disabled:opacity-50 flex justify-center items-center gap-1 rounded-lg shadow-xl cursor-pointer bg-white flex-1 px-3 py-2'>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6665 2.00011C7.1565 2.00011 2.6665 6.49011 2.6665 12.0001C2.6665 17.5101 7.1565 22.0001 12.6665 22.0001C18.1765 22.0001 22.6665 17.5101 22.6665 12.0001C22.6665 6.49011 18.1765 2.00011 12.6665 2.00011ZM17.4465 9.70011L11.7765 15.3701C11.6365 15.5101 11.4465 15.5901 11.2465 15.5901C11.0465 15.5901 10.8565 15.5101 10.7165 15.3701L7.8865 12.5401C7.5965 12.2501 7.5965 11.7701 7.8865 11.4801C8.1765 11.1901 8.6565 11.1901 8.9465 11.4801L11.2465 13.7801L16.3865 8.64011C16.6765 8.35011 17.1565 8.35011 17.4465 8.64011C17.7365 8.93011 17.7365 9.40011 17.4465 9.70011Z" fill="#133BB7" />
                        </svg>
                        <p className='text-sm font-medium text-black'>{t('packagePage.okay')}</p>
                    </button>
                    <button disabled={loading} onClick={() => statusUpdate('refuse')} className='disabled:opacity-50 flex justify-center items-center gap-1 rounded-lg shadow-xl cursor-pointer bg-white flex-1 px-3 py-2'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5099 5.85011L13.5699 2.42011C12.5999 1.86011 11.3999 1.86011 10.4199 2.42011L4.48992 5.85011C3.51992 6.41011 2.91992 7.45011 2.91992 8.58011V15.4201C2.91992 16.5401 3.51992 17.5801 4.48992 18.1501L10.4299 21.5801C11.3999 22.1401 12.5999 22.1401 13.5799 21.5801L19.5199 18.1501C20.4899 17.5901 21.0899 16.5501 21.0899 15.4201V8.58011C21.0799 7.45011 20.4799 6.42011 19.5099 5.85011ZM11.2499 7.75011C11.2499 7.34011 11.5899 7.00011 11.9999 7.00011C12.4099 7.00011 12.7499 7.34011 12.7499 7.75011V13.0001C12.7499 13.4101 12.4099 13.7501 11.9999 13.7501C11.5899 13.7501 11.2499 13.4101 11.2499 13.0001V7.75011ZM12.9199 16.6301C12.8699 16.7501 12.7999 16.8601 12.7099 16.9601C12.5199 17.1501 12.2699 17.2501 11.9999 17.2501C11.8699 17.2501 11.7399 17.2201 11.6199 17.1701C11.4899 17.1201 11.3899 17.0501 11.2899 16.9601C11.1999 16.8601 11.1299 16.7501 11.0699 16.6301C11.0199 16.5101 10.9999 16.3801 10.9999 16.2501C10.9999 15.9901 11.0999 15.7301 11.2899 15.5401C11.3899 15.4501 11.4899 15.3801 11.6199 15.3301C11.9899 15.1701 12.4299 15.2601 12.7099 15.5401C12.7999 15.6401 12.8699 15.7401 12.9199 15.8701C12.9699 15.9901 12.9999 16.1201 12.9999 16.2501C12.9999 16.3801 12.9699 16.5101 12.9199 16.6301Z" fill="#E50000" />
                        </svg>
                        <p className='text-sm font-medium text-black'>{t('packagePage.refuse')}</p>
                    </button>
                    <button disabled={loading}  onClick={() => statusUpdate('none')} className='disabled:opacity-50 flex justify-center items-center gap-1 rounded-lg shadow-xl cursor-pointer bg-white flex-1 px-3 py-2'>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.363 5.53011L6.36301 19.5301C6.34301 19.5501 6.33301 19.5601 6.31301 19.5701C5.93301 19.2501 5.58301 18.9001 5.26301 18.5201C3.74301 16.7701 2.83301 14.4901 2.83301 12.0001C2.83301 6.48011 7.31301 2.00011 12.833 2.00011C15.323 2.00011 17.603 2.91011 19.353 4.43011C19.733 4.75011 20.083 5.10011 20.403 5.48011C20.393 5.50011 20.383 5.51011 20.363 5.53011Z" fill="#E68C00" />
                            <path d="M22.8329 12.0001C22.8329 17.4901 18.3229 22.0001 12.8329 22.0001C11.3329 22.0001 9.91287 21.6701 8.63287 21.0701C8.01287 20.7801 7.89287 19.9501 8.37287 19.4601L20.2929 7.54012C20.7729 7.06012 21.6129 7.18012 21.9029 7.80012C22.5029 9.07012 22.8329 10.5001 22.8329 12.0001Z" fill="#E68C00" />
                            <path d="M22.6029 2.23012C22.3029 1.93012 21.8129 1.93012 21.5129 2.23012L3.06289 20.6901C2.76289 20.9901 2.76289 21.4801 3.06289 21.7801C3.21289 21.9201 3.40289 22.0001 3.60289 22.0001C3.80289 22.0001 3.99289 21.9201 4.14289 21.7701L22.6029 3.31012C22.9129 3.01012 22.9129 2.53012 22.6029 2.23012Z" fill="#E68C00" />
                        </svg>
                        <p className='text-sm font-medium text-black'>{t('packagePage.none')}</p>
                    </button>
                </div>
            }
            <p className={`${item.staff_status === 'okay' && 'bg-blue'} ${item.staff_status === 'refuse' && 'bg-[#E50000]'} ${item.staff_status === 'none' && 'bg-[#E68C00]'} px-2 py-[2px] text-[12px] absolute top-0 left-[43%] rounded-b-lg text-white uppercase`}>{item.staff_status}</p>
        </div>

    )
}

