import React, { useEffect, useState } from 'react'
import Empty from '../components/Empty'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { SlArrowDown } from 'react-icons/sl'
import Select from 'react-select'
import { filterStyles } from '../utils/selectStyles'
import Loader from '../components/Loader'
import { BACKEND_HOST } from '../constants/Index'
import { PiDotsThreeOutlineThin } from 'react-icons/pi'

function Users({ role }) {
  const [users, setUsers] = useState(null)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [t] = useTranslation("global")

  const fetchUsers = async (email = '') => {
    const response = await fetch(`${BACKEND_HOST}/api/user/${role}?email=${email}&sort=${sort}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        token: localStorage.getItem('token')
      }
    })

    const res = await response.json()
    if (response.status === 200) {
      setCount(res.count);
      setUsers(res.users)
    }
    else
      toast.error(res.message)
  }

  const search = (e) => {
    fetchUsers(e.target.value)
  }


  useEffect(() => {

  }, [users])

  useEffect(() => {
    setUsers([])
    fetchUsers()
  }, [role, sort, refresh])


  return (
    <div className='relative min-h-[300px] max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
      {!users && <Loader />}
      {users && <div className='flex gap-2'>
        <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[65%]'>
          <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
        <span className='w-[35%]'>
          <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: '0', label: 'Newest' }} options={[{ value: '0', label: 'Newest' }, { value: '1', label: 'A-Z' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
        </span>
      </div>}


      {count == 0 && <Empty />}

      {users?.map((u, index) => {
        let height = 0
        if (role === 'advertiser')
          height = 'h-[600px]'
        else if (role === 'influencer')
          height = 'h-[710px]'
        else
          height = 'h-[275px]'
        return <div className='relative'>

          <User user={u} key={index} height={height} setRefresh={setRefresh}/>
        </div>
      })}

      <Link to={`/admin/adduser/${role}`} className='bg-white p-2 w-[55px] shadow-2xl rounded-lg flex justify-center items-center fixed bottom-24 ml-[300px] sm:right-5'>
        <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M19.5 35.821C28.4746 35.821 35.75 28.5457 35.75 19.571C35.75 10.5964 28.4746 3.32104 19.5 3.32104C10.5254 3.32104 3.25 10.5964 3.25 19.571C3.25 28.5457 10.5254 35.821 19.5 35.821Z" fill="#133BB7" />
          <path d="M26 18.3523H20.7188V13.071C20.7188 12.4048 20.1663 11.8523 19.5 11.8523C18.8337 11.8523 18.2812 12.4048 18.2812 13.071V18.3523H13C12.3338 18.3523 11.7812 18.9048 11.7812 19.571C11.7812 20.2373 12.3338 20.7898 13 20.7898H18.2812V26.071C18.2812 26.7373 18.8337 27.2898 19.5 27.2898C20.1663 27.2898 20.7188 26.7373 20.7188 26.071V20.7898H26C26.6663 20.7898 27.2188 20.2373 27.2188 19.571C27.2188 18.9048 26.6663 18.3523 26 18.3523Z" fill="#133BB7" />
        </svg>
      </Link>
    </div>
  )
}

export default Users


const User = ({ user, height, setRefresh }) => {
  const [t] = useTranslation("global")
  const [accordian, setAccordian] = useState(false)

  const [actions, setActions] = useState(false)

  const deleteUser = async (_id) => {
    const confirm = window.confirm("Are you suredeleting this?")
    if (!confirm)
      return
    const response = await fetch(`${BACKEND_HOST}/api/user/delete/${_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'Application/json',
        token: localStorage.getItem('token')
      }
    })
    const res = await response.json()
    if (response.status === 200) {
      toast.success(res.message)
    }
    else
      toast.error(res.message)
    setActions(false)
    setRefresh(s => !s)
  }

  const onChange = (e) => {
    console.log(e.target.value);
  }
  return (

    <div className='relative overflow-hidden flex flex-col bg-[#F9F9F9] p-2 pb-4 border-blue border-dashed border-[1px] rounded-lg'>
      <PiDotsThreeOutlineThin className='text-base cursor-pointer absolute right-2 top-2 z-40' onClick={() => setActions(true)} />
      {actions && <div className='absolute bg-white z-[60] border-grey border-2 w-[120px] flex flex-col gap-[6px] p-2 top-7 right-5 rounded-s-2xl rounded-b-2xl' >
        <Link to={`/admin/edit-user/${user.role}/${user._id}`} onClick={() => setActions(false)} className='flex justify-start items-center gap-3 cursor-pointer'>
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5577 0.603027H6.17768C2.53768 0.603027 0.367676 2.77303 0.367676 6.41303V14.783C0.367676 18.433 2.53768 20.603 6.17768 20.603H14.5477C18.1877 20.603 20.3577 18.433 20.3577 14.793V6.41303C20.3677 2.77303 18.1977 0.603027 14.5577 0.603027ZM9.31768 16.113C9.02768 16.403 8.47768 16.683 8.07768 16.743L5.61768 17.093C5.52768 17.103 5.43768 17.113 5.34768 17.113C4.93768 17.113 4.55768 16.973 4.28768 16.703C3.95768 16.373 3.81768 15.893 3.89768 15.363L4.24768 12.903C4.30768 12.493 4.57768 11.953 4.87768 11.663L9.33768 7.20303C9.41768 7.41303 9.49768 7.62303 9.60768 7.86303C9.70768 8.07303 9.81768 8.29303 9.93768 8.49303C10.0377 8.66303 10.1477 8.82303 10.2377 8.94303C10.3477 9.11303 10.4777 9.27303 10.5577 9.36303C10.6077 9.43303 10.6477 9.48303 10.6677 9.50303C10.9177 9.80303 11.2077 10.083 11.4577 10.293C11.5277 10.363 11.5677 10.403 11.5877 10.413C11.7377 10.533 11.8877 10.653 12.0177 10.743C12.1777 10.863 12.3377 10.973 12.5077 11.063C12.7077 11.183 12.9277 11.293 13.1477 11.403C13.3777 11.503 13.5877 11.593 13.7977 11.663L9.31768 16.113ZM15.7377 9.69303L14.8177 10.623C14.7577 10.683 14.6777 10.713 14.5977 10.713C14.5677 10.713 14.5277 10.713 14.5077 10.703C12.4777 10.123 10.8577 8.50303 10.2777 6.47303C10.2477 6.36303 10.2777 6.24303 10.3577 6.17303L11.2877 5.24303C12.8077 3.72303 14.2577 3.75303 15.7477 5.24303C16.5077 6.00303 16.8777 6.73303 16.8777 7.49303C16.8677 8.21303 16.4977 8.93303 15.7377 9.69303Z" fill="#133BB7" />
          </svg>
          <p className='text-sm font-medium text-black'>{t('productPage.edit')}</p>
        </Link>

        <hr className='h-[1px] border-b-[1px] border-grey/30 w-full' />
        <div className='flex justify-start items-center gap-2  cursor-pointer' onClick={() => deleteUser(user._id)}>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.4378 6.14406C19.8278 5.98406 18.2178 5.86406 16.5978 5.77406V5.76406L16.3778 4.46406C16.2278 3.54406 16.0078 2.16406 13.6678 2.16406H11.0478C8.71783 2.16406 8.49783 3.48406 8.33783 4.45406L8.12783 5.73406C7.19783 5.79406 6.26783 5.85406 5.33783 5.94406L3.29783 6.14406C2.87783 6.18406 2.57783 6.55406 2.61783 6.96406C2.65783 7.37406 3.01783 7.67406 3.43783 7.63406L5.47783 7.43406C10.7178 6.91406 15.9978 7.11406 21.2978 7.64406C21.3278 7.64406 21.3478 7.64406 21.3778 7.64406C21.7578 7.64406 22.0878 7.35406 22.1278 6.96406C22.1578 6.55406 21.8578 6.18406 21.4378 6.14406Z" fill="#E50000" />
            <path d="M19.5979 9.05406C19.3579 8.80406 19.0279 8.66406 18.6879 8.66406H6.04791C5.70791 8.66406 5.36791 8.80406 5.13791 9.05406C4.90791 9.30406 4.77791 9.64406 4.79791 9.99406L5.41791 20.2541C5.52791 21.7741 5.66791 23.6741 9.15791 23.6741H15.5779C19.0679 23.6741 19.2079 21.7841 19.3179 20.2541L19.9379 10.0041C19.9579 9.64406 19.8279 9.30406 19.5979 9.05406ZM14.0279 18.6641H10.6979C10.2879 18.6641 9.94791 18.3241 9.94791 17.9141C9.94791 17.5041 10.2879 17.1641 10.6979 17.1641H14.0279C14.4379 17.1641 14.7779 17.5041 14.7779 17.9141C14.7779 18.3241 14.4379 18.6641 14.0279 18.6641ZM14.8679 14.6641H9.86791C9.45791 14.6641 9.11791 14.3241 9.11791 13.9141C9.11791 13.5041 9.45791 13.1641 9.86791 13.1641H14.8679C15.2779 13.1641 15.6179 13.5041 15.6179 13.9141C15.6179 14.3241 15.2779 14.6641 14.8679 14.6641Z" fill="#E50000" />
          </svg>
          <p className='text-sm font-medium text-black'>{t('productPage.delete')}</p>
        </div>
      </div>}
      {actions && <div className='fixed w-[400px] h-screen z-[51] top-0 -ml-[29px] sm:w-full bg-black/40 ' onClick={() => setActions(false)}></div>}

      <div className={`${accordian ? height : 'h-[293px] overflow-hidden'} transition-all ease-in duration-200 flex flex-col gap-1`}>
        {user.role == 'advertiser' && <>
          <Field label={t('formLabels.cname')} type="text" name="companyName" required={true} value={user.companyName} onChange={onChange}>
            <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_32_1833)">
                <path d="M10.9998 22.5H18.9998C23.0198 22.5 23.7398 20.89 23.9498 18.93L24.6998 10.93C24.9698 8.49 24.2698 6.5 19.9998 6.5H9.99983C5.72983 6.5 5.02983 8.49 5.29983 10.93L6.04983 18.93C6.25983 20.89 6.97983 22.5 10.9998 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 6.5V5.7C11 3.93 11 2.5 14.2 2.5H15.8C19 2.5 19 3.93 19 5.7V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17 13.5V14.5C17 14.51 17 14.51 17 14.52C17 15.61 16.99 16.5 15 16.5C13.02 16.5 13 15.62 13 14.53V13.5C13 12.5 13 12.5 14 12.5H16C17 12.5 17 12.5 17 13.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.65 11.5C22.34 13.18 19.7 14.18 17 14.52" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.62012 11.77C7.87012 13.31 10.4101 14.24 13.0001 14.53" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <filter id="filter0_d_32_1833" x="-1" y="0.5" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_32_1833" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_32_1833" result="shape" />
                </filter>
              </defs>
            </svg>

          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.cAdd')} type="text" name="companyAddress" required={true} value={user.companyAddress} onChange={onChange}>
            <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_32_1833)">
                <path d="M10.9998 22.5H18.9998C23.0198 22.5 23.7398 20.89 23.9498 18.93L24.6998 10.93C24.9698 8.49 24.2698 6.5 19.9998 6.5H9.99983C5.72983 6.5 5.02983 8.49 5.29983 10.93L6.04983 18.93C6.25983 20.89 6.97983 22.5 10.9998 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 6.5V5.7C11 3.93 11 2.5 14.2 2.5H15.8C19 2.5 19 3.93 19 5.7V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17 13.5V14.5C17 14.51 17 14.51 17 14.52C17 15.61 16.99 16.5 15 16.5C13.02 16.5 13 15.62 13 14.53V13.5C13 12.5 13 12.5 14 12.5H16C17 12.5 17 12.5 17 13.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.65 11.5C22.34 13.18 19.7 14.18 17 14.52" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.62012 11.77C7.87012 13.31 10.4101 14.24 13.0001 14.53" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <filter id="filter0_d_32_1833" x="-1" y="0.5" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_32_1833" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_32_1833" result="shape" />
                </filter>
              </defs>
            </svg>

          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.breg')} type="text" name="businessRegNo" required={true} value={user.businessRegNo} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 21.5H7C3 21.5 2 20.5 2 16.5V8.5C2 4.5 3 3.5 7 3.5H17C21 3.5 22 4.5 22 8.5V16.5C22 20.5 21 21.5 17 21.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 8.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 12.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 16.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.50043 11.79C9.50007 11.79 10.3104 10.9797 10.3104 9.98004C10.3104 8.98041 9.50007 8.17004 8.50043 8.17004C7.50079 8.17004 6.69043 8.98041 6.69043 9.98004C6.69043 10.9797 7.50079 11.79 8.50043 11.79Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 16.83C11.86 15.38 10.71 14.24 9.26 14.11C8.76 14.06 8.25 14.06 7.74 14.11C6.29 14.25 5.14 15.38 5 16.83" stroke="#151B33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.name3')} type="text" name="name" required={true} value={user.name} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.tel')} type="text" name="telephone" required={true} value={user.telephone} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.97 18.83C21.97 19.19 21.89 19.56 21.72 19.92C21.55 20.28 21.33 20.62 21.04 20.94C20.55 21.48 20.01 21.87 19.4 22.12C18.8 22.37 18.15 22.5 17.45 22.5C16.43 22.5 15.34 22.26 14.19 21.77C13.04 21.28 11.89 20.62 10.75 19.79C9.6 18.95 8.51 18.02 7.47 16.99C6.44 15.95 5.51 14.86 4.68 13.72C3.86 12.58 3.2 11.44 2.72 10.31C2.24 9.17 2 8.08 2 7.04C2 6.36 2.12 5.71 2.36 5.11C2.6 4.5 2.98 3.94 3.51 3.44C4.15 2.81 4.85 2.5 5.59 2.5C5.87 2.5 6.15 2.56 6.4 2.68C6.66 2.8 6.89 2.98 7.07 3.24L9.39 6.51C9.57 6.76 9.7 6.99 9.79 7.21C9.88 7.42 9.93 7.63 9.93 7.82C9.93 8.06 9.86 8.3 9.72 8.53C9.59 8.76 9.4 9 9.16 9.24L8.4 10.03C8.29 10.14 8.24 10.27 8.24 10.43C8.24 10.51 8.25 10.58 8.27 10.66C8.3 10.74 8.33 10.8 8.35 10.86C8.53 11.19 8.84 11.62 9.28 12.14C9.73 12.66 10.21 13.19 10.73 13.72C11.27 14.25 11.79 14.74 12.32 15.19C12.84 15.63 13.27 15.93 13.61 16.11C13.66 16.13 13.72 16.16 13.79 16.19C13.87 16.22 13.95 16.23 14.04 16.23C14.21 16.23 14.34 16.17 14.45 16.06L15.21 15.31C15.46 15.06 15.7 14.87 15.93 14.75C16.16 14.61 16.39 14.54 16.64 14.54C16.83 14.54 17.03 14.58 17.25 14.67C17.47 14.76 17.7 14.89 17.95 15.06L21.26 17.41C21.52 17.59 21.7 17.8 21.81 18.05C21.91 18.3 21.97 18.55 21.97 18.83Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" />
              <path d="M18.5 9.5C18.5 8.9 18.03 7.98 17.33 7.23C16.69 6.54 15.84 6 15 6" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M22 9.5C22 5.63 18.87 2.5 15 2.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.mob')} type="text" name="mobile" required={true} value={user.mobile} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.mail')} type="text" name="email" required={true} value={user.email} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.id')} type="text" name="Id" required={true} value={user.unique_id} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.radd')} type="text" name="receivingAddress" value={user.receivingAddress} required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z" stroke="#133BB7" stroke-width="1.5" />
              <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#133BB7" stroke-width="1.5" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.rec')} type="text" name="receiver" required={true} value={user.receiver} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
        </>}

        {user.role === 'influencer' && <>
          <Field label={t('formLabels.id')} type="text" name="unique_id" value={user.unique_id} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.channel')} type="text" name="youtubeChannel" value={user.youtubeChannel} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 15.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5H15C20 22.5 22 20.5 22 15.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.51953 7.60999H21.4795" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.51953 2.60999V7.46999" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.4805 2.60999V7.01999" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.75 14.95V13.75C9.75 12.21 10.84 11.58 12.17 12.35L13.21 12.95L14.25 13.55C15.58 14.32 15.58 15.58 14.25 16.35L13.21 16.95L12.17 17.55C10.84 18.32 9.75 17.69 9.75 16.15V14.95V14.95Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.scount')} type="text" name="subscriberCount" value={user.subscriberCount} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.0001 7.66C17.9401 7.65 17.8701 7.65 17.8101 7.66C16.4301 7.61 15.3301 6.48 15.3301 5.08C15.3301 3.65 16.4801 2.5 17.9101 2.5C19.3401 2.5 20.4901 3.66 20.4901 5.08C20.4801 6.48 19.3801 7.61 18.0001 7.66Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M16.9704 14.94C18.3404 15.17 19.8504 14.93 20.9104 14.22C22.3204 13.28 22.3204 11.74 20.9104 10.8C19.8404 10.09 18.3104 9.85003 16.9404 10.09" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.97047 7.66C6.03047 7.65 6.10047 7.65 6.16047 7.66C7.54047 7.61 8.64047 6.48 8.64047 5.08C8.64047 3.65 7.49047 2.5 6.06047 2.5C4.63047 2.5 3.48047 3.66 3.48047 5.08C3.49047 6.48 4.59047 7.61 5.97047 7.66Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.00043 14.94C5.63043 15.17 4.12043 14.93 3.06043 14.22C1.65043 13.28 1.65043 11.74 3.06043 10.8C4.13043 10.09 5.66043 9.85003 7.03043 10.09" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0001 15.13C11.9401 15.12 11.8701 15.12 11.8101 15.13C10.4301 15.08 9.33008 13.95 9.33008 12.55C9.33008 11.12 10.4801 9.96997 11.9101 9.96997C13.3401 9.96997 14.4901 11.13 14.4901 12.55C14.4801 13.95 13.3801 15.09 12.0001 15.13Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.08973 18.28C7.67973 19.22 7.67973 20.76 9.08973 21.7C10.6897 22.77 13.3097 22.77 14.9097 21.7C16.3197 20.76 16.3197 19.22 14.9097 18.28C13.3197 17.22 10.6897 17.22 9.08973 18.28Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.viewCount')} type="text" name="viewCount" value={user.viewCount} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5799 12.5C15.5799 14.48 13.9799 16.08 11.9999 16.08C10.0199 16.08 8.41992 14.48 8.41992 12.5C8.41992 10.52 10.0199 8.92004 11.9999 8.92004C13.9799 8.92004 15.5799 10.52 15.5799 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11.9998 20.77C15.5298 20.77 18.8198 18.69 21.1098 15.09C22.0098 13.68 22.0098 11.31 21.1098 9.89997C18.8198 6.29997 15.5298 4.21997 11.9998 4.21997C8.46984 4.21997 5.17984 6.29997 2.88984 9.89997C1.98984 11.31 1.98984 13.68 2.88984 15.09C5.17984 18.69 8.46984 20.77 11.9998 20.77Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.name')} type="text" name="name" value={user.name} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.radd')} type="text" name="receivingAddress" value={user.receivingAddress} required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z" stroke="#133BB7" stroke-width="1.5" />
              <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#133BB7" stroke-width="1.5" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.rec')} type="text" name="receiver" value={user.receiver} required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.mob')} type="text" name="mobile" value={user.mobile} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.mail')} type="text" name="email" value={user.email} required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.cat')} type="text" name="category" value={user.category?.name} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.99983 22.5H15.9998C20.0198 22.5 20.7398 20.89 20.9498 18.93L21.6998 10.93C21.9698 8.49 21.2698 6.5 16.9998 6.5H6.99983C2.72983 6.5 2.02983 8.49 2.29983 10.93L3.04983 18.93C3.25983 20.89 3.97983 22.5 7.99983 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8 6.5V5.7C8 3.93 8 2.5 11.2 2.5H12.8C16 2.5 16 3.93 16 5.7V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 13.5V14.5C14 14.51 14 14.51 14 14.52C14 15.61 13.99 16.5 12 16.5C10.02 16.5 10 15.62 10 14.53V13.5C10 12.5 10 12.5 11 12.5H13C14 12.5 14 12.5 14 13.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M21.65 11.5C19.34 13.18 16.7 14.18 14 14.52" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.62012 11.77C4.87012 13.31 7.41012 14.24 10.0001 14.53" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.preffered')} type="text" name="preffered" value={user.preffered} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.75 12.4999L10.58 15.3299L16.25 9.66992" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.nonpreferred')} type="text" name="nonpreferred" value={user.nonpreferred} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.75 12.4999L10.58 15.3299L16.25 9.66992" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>

        </>}

        {user.role == 'staff' && <>
          <Field label={t('formLabels.id')} type="text" name="Id" value={user.unique_id} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.name3')} type="text" name="name" value={user.name} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.mob')} type="text" name="mobile" value={user.mobile} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.mail')} type="text" name="email" value={user.email} required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
          <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
          <Field label={t('formLabels.department')} type="text" name="department" value={user.department} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.70001 18.5H4.15002C2.72002 18.5 2 17.78 2 16.35V4.65002C2 3.22002 2.72002 2.5 4.15002 2.5H8.45001C9.88001 2.5 10.6 3.22002 10.6 4.65002V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.3702 8.91998V20.08C17.3702 21.69 16.5702 22.5 14.9602 22.5H9.12018C7.51018 22.5 6.7002 21.69 6.7002 20.08V8.91998C6.7002 7.30998 7.51018 6.5 9.12018 6.5H14.9602C16.5702 6.5 17.3702 7.30998 17.3702 8.91998Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13.3999 6.5V4.65002C13.3999 3.22002 14.1199 2.5 15.5499 2.5H19.8499C21.2799 2.5 21.9999 3.22002 21.9999 4.65002V16.35C21.9999 17.78 21.2799 18.5 19.8499 18.5H17.3699" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 11.5H14" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 14.5H14" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 22.5V19.5" stroke="#151B33" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Field>
        </>}
      </div>
      <button className='h-[30px] bg-white flex justify-center items-center gap-2 py-[2px] text-grey text-sm w-full rounded-lg' onClick={() => setAccordian(state => !state)}>
        {accordian ? t('userpage.less') : t('userpage.more')}
        <SlArrowDown className={`text-blue ${accordian && 'rotate-180'}`} />
      </button>
    </div>

  )
}



const Field = (props) => {
  return (
    <div className='flex justify-start items-center gap-2'>
      <div className='flex justify-center items-center rounded-md w-[50px] h-[50px] bg-white'>
        {props.children}
      </div>
      <div className='flex flex-col gap-1'>
        <p className='text-grey text-sm'>{props.label}</p>
        <p className='text-black text-sm'>{props.value}</p>
        {/* <input className='text-black text-sm font-medium outline-none bg-transparent' defaultValue={props.value} onChange={props.onChange} /> */}
      </div>
    </div>
  )
}