import React, { useContext } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Navigate, Route, Routes } from 'react-router-dom'
import List from '../pages/List'
import Verify from '../pages/Verify'
import VerifyPackage from '../pages/VerifyPackage'
import Profile from '../pages/Profile'
import PackagesGroupbyInfluencers from '../pages/PackagesGroupbyInfluencers'
import StaffPackageForInfluencer from '../pages/StaffPackageForInfluencer'
import StaffSend from '../pages/StaffSend'
import { StaffContext } from '../state/StaffProvide'
import PackageInvoicePopup2 from '../components/PackageInvoicePopup2'
import MyBox from '../pages/MyBox'
import PackageToAriveList from '../pages/PackageToAriveList'

function Staff() {
  const { packageInvoicePopup } = useContext(StaffContext)
  return (
    <div className='relativpe overflow-x-hidden'>
    <Header />
    <div className='relative px-5 mt-[90px] py-6 mb-[100px]'>
        <Routes>
            <Route index element={<Navigate to='/staff/list' />} />
            <Route path='list' element={<List />} />
            <Route path='verify' element={<Verify />} />
            <Route path='verify/:_id' element={<VerifyPackage />} />
            <Route path='packages' element={<PackagesGroupbyInfluencers />} />
            <Route path='influencer-packages/:_id' element={<StaffPackageForInfluencer />} />
            <Route path='send' element={<StaffSend />} />
            <Route path='send/:_id' element={<PackageToAriveList />} />
            <Route path='mybox' element={<MyBox />} />
            <Route path='profile' element={<Profile />} />
        </Routes>
        {packageInvoicePopup && <PackageInvoicePopup2 />}
    </div>
    <Footer />
</div>
  )
}

export default Staff
