import React, { useEffect, useState } from 'react'
import { BACKEND_HOST } from '../constants/Index'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { filterStyles } from '../utils/selectStyles'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import { PiDotsThreeOutlineThin } from 'react-icons/pi'

function MyBox() {

    const [packages, setPackages] = useState(null)
    const [t] = useTranslation("global")

    const [sort, setSort] = useState(1)
    const [refresh, setRefresh] = useState(false)
 
    const search = (e) => {
        fetchPackages(e.target.value)
    }

    const fetchPackages = async (name = '') => {
        const response = await fetch(`${BACKEND_HOST}/api/packages/staff/my-box?name=${name}&sort=${sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            setPackages(res)
        }
        else
            toast.error(res.message)
    }


    useEffect(() => {
        setPackages(null)
        fetchPackages()
    }, [sort, refresh])
    return (
        <div className='min-h-[200px] max-h-auto z-0  flex flex-col gap-4'>
             {!packages && <Loader />}
             {packages && <>
                <div className='flex gap-2 justify-between items-center'>
                    <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[70%]'>
                        <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: 'des', label: 'Newest' }} options={[{ value: 'des', label: 'Newest' }, { value: 'asc', label: 'Oldest' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
                </div>
                {packages && <p className='text-black font-medium text-base'>{packages?.length} Packages</p>}

                <div className='flex flex-col gap-2'>

                    {packages?.map((p, index) => {
                        return <Item p={p} key={index} setRefresh={setRefresh}/>
                    })}
                </div>
             </>}
        </div>
    )
}

export default MyBox



const Item = ({ p, setRefresh }) => {
    const [t] = useTranslation("global")
    const [actions, setActions] = useState(false)
    const [comment, setComment] = useState(false)
    const [commentBox, setCommentBox] = useState(false)
    const [loading, setLoading] = useState(false)


    const addComment = async() => {
      setLoading(true)
      const response = await fetch(`${BACKEND_HOST}/api/packages/staff/my-box/comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          token: localStorage.getItem('token')
        },
        body: JSON.stringify({
          comment_my_box: comment,
          item_id: p._id
        })
      })
  
      const res = await response.json()
      if (response.status === 200) {
        toast.success(res.message)
        setRefresh(state => !state)
        setComment(false)
      }
      else
        toast.error(res.message)
      setLoading(false)
    }

    const deleteProduct = async(_id) => {
        const confirm = window.confirm('Are you sure deleting this?')
        if(!confirm)
          return;
        setLoading(true)
        const response = await fetch(`${BACKEND_HOST}/api/packages/staff/my-box/${p._id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'Application/json',
            token: localStorage.getItem('token')
          },
        })
    
        const res = await response.json()
        if (response.status === 200) {
          toast.success(res.message)
          setRefresh(state => !state)
          setActions(false)
        }
        else
          toast.error(res.message)
          setLoading(false)
      }

    return (
        // <div className='border-[1px] border-grey/50 p-2 flex flex-col gap-2 rounded-lg'>

        //     <div className='relative  rounded-lg  flex justify-start items-stretch gap-2'>
        //         <img src={`${BACKEND_HOST}/${p.items[0]?.advertiser?.profile_image}`} className='w-[50px] h-[50px] rounded-md object-cover' />
        //         <div className='flex flex-col w-full gap-1'>
        //             <div className='flex justify-between items-center'>
        //                 <h1 className='text-blue text-sm font-semibold capitalize'>{p.advertiser}</h1>
        //             </div>
        //             <div className='flex justify-between items-stretch gap-1'>
        //                 <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
        //                     <p className='font-bold leading-3'>{t('packageItem.items')}</p>
        //                     <p>{p.quantity}</p>
        //                 </span>
        //                 <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
        //                     <p className='font-bold leading-3'>{t('packageItem.ammount')}</p>
        //                     <p>{p.totalPayment}</p>
        //                 </span>
        //             </div>
        //         </div>

        //     </div>
        // </div>

        <div className='flex flex-col px-2 py-2 border-grey/20 border-[1px]'>
        <div className='relative rounded-md flex justify-start items-start gap-2 '>
          <div className='w-[70px] h-[60px] rounded-md bg-grey/50'>
          <img src={`${BACKEND_HOST}/${p.product?.image}`} alt='product' className='rounded-md w-[60px] h-[60px] object-cover bg-grey/20' />
          </div>
          <div className='flex justify-start items-start flex-col gap-[2px] w-full'>
            <span className='flex justify-between items-center w-full'>
              <h1 className='text-black text-sm font-bold capitalize'>{p.product?.title} <span className='font-base lowercase text-grey'>x {p.product_quantity}</span> </h1>
              <span className='flex justify-end items-center gap-2'>
                <a href={p.url} target='_blank'>
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.27 12C2.48 11.05 2 9.83 2 8.5C2 5.48 4.47 3 7.5 3H12.5C15.52 3 18 5.48 18 8.5C18 11.52 15.53 14 12.5 14H10" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20.73 12C21.52 12.95 22 14.17 22 15.5C22 18.52 19.53 21 16.5 21H11.5C8.48 21 6 18.52 6 15.5C6 12.48 8.47 10 11.5 10H14" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
                <PiDotsThreeOutlineThin className='text-base cursor-pointer' onClick={() => setActions(true)} />
  
                {actions && <div className='absolute bg-white z-[60] border-grey border-2 w-[120px] flex flex-col gap-[6px] p-2 top-7 right-5 rounded-s-2xl rounded-b-2xl' >
                  <div className='flex justify-start items-center gap-2  cursor-pointer' onClick={()=>{setActions(false); setCommentBox(!commentBox)}}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.3677 13.6585V17.8585C16.3677 21.3585 14.9677 22.7585 11.4677 22.7585H7.26768C3.76768 22.7585 2.36768 21.3585 2.36768 17.8585V13.6585C2.36768 10.1585 3.76768 8.75854 7.26768 8.75854H11.4677C14.9677 8.75854 16.3677 10.1585 16.3677 13.6585Z" fill="#133BB7" />
                      <path d="M17.4674 2.75854H13.2674C10.1844 2.75854 8.73842 3.85263 8.43718 6.49756C8.37416 7.05089 8.83243 7.50854 9.38935 7.50854H11.4674C15.6674 7.50854 17.6174 9.45854 17.6174 13.6585V15.7366C17.6174 16.2936 18.0751 16.7518 18.6284 16.6888C21.2734 16.3876 22.3674 14.9416 22.3674 11.8585V7.65855C22.3674 4.15855 20.9674 2.75854 17.4674 2.75854Z" fill="#133BB7" />
                    </svg>
                    <p className='text-sm font-medium text-black'>{t('packagePage.comment')}</p>
                  </div>
                  <hr className='h-[1px] border-b-[1px] border-grey/30 w-full' />
                  <div className='flex justify-start items-center gap-2  cursor-pointer' onClick={()=> {if(!loading) deleteProduct(p._id)}}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.4378 6.14406C19.8278 5.98406 18.2178 5.86406 16.5978 5.77406V5.76406L16.3778 4.46406C16.2278 3.54406 16.0078 2.16406 13.6678 2.16406H11.0478C8.71783 2.16406 8.49783 3.48406 8.33783 4.45406L8.12783 5.73406C7.19783 5.79406 6.26783 5.85406 5.33783 5.94406L3.29783 6.14406C2.87783 6.18406 2.57783 6.55406 2.61783 6.96406C2.65783 7.37406 3.01783 7.67406 3.43783 7.63406L5.47783 7.43406C10.7178 6.91406 15.9978 7.11406 21.2978 7.64406C21.3278 7.64406 21.3478 7.64406 21.3778 7.64406C21.7578 7.64406 22.0878 7.35406 22.1278 6.96406C22.1578 6.55406 21.8578 6.18406 21.4378 6.14406Z" fill="#E50000" />
                      <path d="M19.5979 9.05406C19.3579 8.80406 19.0279 8.66406 18.6879 8.66406H6.04791C5.70791 8.66406 5.36791 8.80406 5.13791 9.05406C4.90791 9.30406 4.77791 9.64406 4.79791 9.99406L5.41791 20.2541C5.52791 21.7741 5.66791 23.6741 9.15791 23.6741H15.5779C19.0679 23.6741 19.2079 21.7841 19.3179 20.2541L19.9379 10.0041C19.9579 9.64406 19.8279 9.30406 19.5979 9.05406ZM14.0279 18.6641H10.6979C10.2879 18.6641 9.94791 18.3241 9.94791 17.9141C9.94791 17.5041 10.2879 17.1641 10.6979 17.1641H14.0279C14.4379 17.1641 14.7779 17.5041 14.7779 17.9141C14.7779 18.3241 14.4379 18.6641 14.0279 18.6641ZM14.8679 14.6641H9.86791C9.45791 14.6641 9.11791 14.3241 9.11791 13.9141C9.11791 13.5041 9.45791 13.1641 9.86791 13.1641H14.8679C15.2779 13.1641 15.6179 13.5041 15.6179 13.9141C15.6179 14.3241 15.2779 14.6641 14.8679 14.6641Z" fill="#E50000" />
                    </svg>
                    <p className='text-sm font-medium text-black'>{t('productPage.delete')}</p>
                  </div>
                </div>}
              </span>
            </span>
            <span className='px-4 py-[1px] rounded-md bg-blue/20 text-[11px] bg-blue capitalize'>
              {p.product.color}
            </span>
            <p className='text-black text-sm'>{p.product?.description?.substr(0, 40)}</p>
          </div>
        {actions && <div className='fixed w-[400px] h-screen z-[51] top-0 -ml-[29px] sm:w-full bg-black/40 ' onClick={() => setActions(false)}></div>}
        </div>
          <div className={`${commentBox? 'h-[120px] mt-2' : 'h-0'} flex flex-col gap-1 overflow-hidden transition-all ease-in duration-100`}>
                <textarea defaultValue={p?.comment_my_box} className='resize-none text-sm text-grey h-[80px] p-2 border-[1px] border-blue rounded-md outline-none' onChange={(e) => setComment(e.target.value)} />
                <button onClick={addComment} disabled={loading} className='disabled:opacity-50 bg-blue flex-1 text-sm rounded-md text-white px-2 text-[12px]'>{t('packageItem.comment')}</button>
            </div>
      </div>
    )
}