import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { filterStyles } from '../utils/selectStyles'
import { BACKEND_HOST } from '../constants/Index'
import { PiDotsThreeOutlineThin } from 'react-icons/pi'
import Loader from '../components/Loader'
import { extractPackages } from '../utils'
import { getSeoulTime } from '../utils/getSeoulTime'

export default function Packages() {
  const [user_packages, setuser_packages] = useState(0)
  const [packages, setPackages] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [t] = useTranslation("global")

  const [sort, setSort] = useState(0)

  const search = (e) => {
    fetchPackages(e.target.value)
  }

  const fetchPackages = async (packageid = '') => {
    const response = await fetch(`${BACKEND_HOST}/api/packages?packageid=${packageid}&sort=${sort}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        token: localStorage.getItem('token')
      }
    })

    const res = await response.json()
    if (response.status === 200) {
      setuser_packages(res.couunt)
      let packages = extractPackages(res.packages)
      setPackages(packages)
    }
    else
      toast.error(res.message)
  }


  useEffect(() => {
    setPackages(null)
    fetchPackages()
  }, [sort, refresh])

  return (
    <div className=' max-h-auto z-0 flex flex-col gap-4'>
      {!packages && <Loader />}
      {user_packages == 0 && packages &&
        <Link to='/advertiser/addpackage'>
          <div className='border-dashed border-[1px] border-blue rounded-xl bg-[#F2F4F9] h-[150px] flex flex-col justify-center items-center cursor-pointer'>
            <p className='text-base text-black font-semibold'>{t('packagePage.Create')}</p>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.4" d="M31.531 4.62732H15.4693C8.49266 4.62732 4.3335 8.78649 4.3335 15.7632V31.8057C4.3335 38.8015 8.49266 42.9607 15.4693 42.9607H31.5118C38.4885 42.9607 42.6477 38.8015 42.6477 31.8248V15.7632C42.6668 8.78649 38.5077 4.62732 31.531 4.62732Z" fill="#133BB7" />
              <path d="M31.1668 22.3565H24.9377V16.1273C24.9377 15.3415 24.286 14.6898 23.5002 14.6898C22.7143 14.6898 22.0627 15.3415 22.0627 16.1273V22.3565H15.8335C15.0477 22.3565 14.396 23.0082 14.396 23.794C14.396 24.5798 15.0477 25.2315 15.8335 25.2315H22.0627V31.4607C22.0627 32.2465 22.7143 32.8982 23.5002 32.8982C24.286 32.8982 24.9377 32.2465 24.9377 31.4607V25.2315H31.1668C31.9527 25.2315 32.6043 24.5798 32.6043 23.794C32.6043 23.0082 31.9527 22.3565 31.1668 22.3565Z" fill="#133BB7" />
            </svg>
          </div>
          <p className='text-center text-blue text-sm font-bold mt-2 w-[70%] m-auto'>{t('packagePage.text1')}</p>
          <p className='text-center text-blue text-sm font-bold  w-[70%] m-auto -mt-[2px]'>{t('packagePage.text2')}</p>
        </Link>}

      {user_packages !== 0 && packages && <>

        <div className='flex gap-2 justify-between items-center'>
          <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[70%]'>
            <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>

          <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: '0', label: 'Newest' }} options={[{ value: '0', label: 'Newest' }, { value: '1', label: 'A-Z' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
        </div>

        <p className='text-black font-medium text-base'>{packages?.length} Packages</p>


        <div className='flex flex-col gap-2'>

          {packages?.map((p, index) => {
            p.quantity = 0
            for (let i = 0; i < p.packageItemIds.length; i++) {
              p.quantity += p.packageItemIds[i].product_quantity

            }
            return <Item key={index} p={p} setRefresh={setRefresh}/>
          })}

        </div>

        <Link to={`/advertiser/addpackage`} className='bg-white p-2 w-[55px] shadow-2xl rounded-lg flex justify-center items-center fixed bottom-24 ml-[300px] sm:right-4'>
          <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M19.5 35.821C28.4746 35.821 35.75 28.5457 35.75 19.571C35.75 10.5964 28.4746 3.32104 19.5 3.32104C10.5254 3.32104 3.25 10.5964 3.25 19.571C3.25 28.5457 10.5254 35.821 19.5 35.821Z" fill="#133BB7" />
            <path d="M26 18.3523H20.7188V13.071C20.7188 12.4048 20.1663 11.8523 19.5 11.8523C18.8337 11.8523 18.2812 12.4048 18.2812 13.071V18.3523H13C12.3338 18.3523 11.7812 18.9048 11.7812 19.571C11.7812 20.2373 12.3338 20.7898 13 20.7898H18.2812V26.071C18.2812 26.7373 18.8337 27.2898 19.5 27.2898C20.1663 27.2898 20.7188 26.7373 20.7188 26.071V20.7898H26C26.6663 20.7898 27.2188 20.2373 27.2188 19.571C27.2188 18.9048 26.6663 18.3523 26 18.3523Z" fill="#133BB7" />
          </svg>
        </Link>
      </>}

    </div>
  )
}



const Item = ({ p, setRefresh }) => {
  const [actions, setActions] = useState(false)
  const [loading, setLoading] = useState(false)
  const [t] = useTranslation("global")

  const dublicate = async() => {
    setLoading(true)
     const response = await fetch(`${BACKEND_HOST}/api/packages/duplicate_package`,{
      method: 'POST',
      headers:{
        'Content-Type':'Application/json',
        token: localStorage.getItem('token')
      },
      body: JSON.stringify({_id:p._id})
     })
     const res = await response.json()
     if(response.status === 201){
       setRefresh(s => !s)
       toast.success(res.message)
       setActions(false)
     }
     else
      toast.error(res.message)
      setLoading(false)
  }

  const deletePackage = async() => {
    const confirm = window.confirm('Are you sure deleting this?')
    if(!confirm)
        return false;
    setLoading(true)
     const response = await fetch(`${BACKEND_HOST}/api/packages/delete_package`,{
      method: 'POST',
      headers:{
        'Content-Type':'Application/json',
        token: localStorage.getItem('token')
      },
      body: JSON.stringify({_id:p._id})
     })
     const res = await response.json()
     if(response.status === 200){
       setRefresh(s => !s)
       toast.success(res.message)
       setActions(false)
     }
     else
      toast.error(res.message)
      setLoading(false)
  }

  return (
    <div className='p-2 rounded-lg border-[1px] border-grey/50 flex justify-start items-stretch gap-2'>
      <Link to={`/advertiser/editpackage/${p._id}`} className='w-[70px] h-[60px] rounded-md bg-grey/50'>
        <img src={`${BACKEND_HOST}/${p.packageItemIds[0]?.product?.image}`} alt='product' className='rounded-md w-[60px] h-[60px] object-cover bg-grey/20' />
      </Link>
      <div className='flex flex-col w-full gap-1 relative'>
        <div className='flex justify-between items-center'>
        <span className='flex justify-start items-center gap-2'>
                            <h1 className='text-blue text-sm font-semibold capitalize'>{p.packageID}</h1>
                            <p className='text-[12px] text-grey'>{getSeoulTime(p.createdAt)}</p>
                        </span>
          <PiDotsThreeOutlineThin onClick={() => { setActions(true) }} className='text-base cursor-pointer' />
          {actions && <div className='absolute bg-white z-[60] border-grey border-2 w-[120px] flex flex-col gap-[6px] p-2 top-4 right-5 rounded-s-2xl rounded-b-2xl' >
            <button disabled={loading} onClick={dublicate} className='flex justify-start items-center gap-3 cursor-pointer'>
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.3677 13.6585V17.8585C16.3677 21.3585 14.9677 22.7585 11.4677 22.7585H7.26768C3.76768 22.7585 2.36768 21.3585 2.36768 17.8585V13.6585C2.36768 10.1585 3.76768 8.75854 7.26768 8.75854H11.4677C14.9677 8.75854 16.3677 10.1585 16.3677 13.6585Z" fill="#133BB7" />
                <path d="M17.4674 2.75854H13.2674C10.1844 2.75854 8.73842 3.85263 8.43718 6.49756C8.37416 7.05089 8.83243 7.50854 9.38935 7.50854H11.4674C15.6674 7.50854 17.6174 9.45854 17.6174 13.6585V15.7366C17.6174 16.2936 18.0751 16.7518 18.6284 16.6888C21.2734 16.3876 22.3674 14.9416 22.3674 11.8585V7.65855C22.3674 4.15855 20.9674 2.75854 17.4674 2.75854Z" fill="#133BB7" />
              </svg>
              <p className='text-sm font-medium text-black'>{t('productPage.duplicate')}</p>
            </button>
            <div className='flex justify-start items-center gap-2  cursor-pointer' onClick={()=>deletePackage(p._id)}>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.4378 6.14406C19.8278 5.98406 18.2178 5.86406 16.5978 5.77406V5.76406L16.3778 4.46406C16.2278 3.54406 16.0078 2.16406 13.6678 2.16406H11.0478C8.71783 2.16406 8.49783 3.48406 8.33783 4.45406L8.12783 5.73406C7.19783 5.79406 6.26783 5.85406 5.33783 5.94406L3.29783 6.14406C2.87783 6.18406 2.57783 6.55406 2.61783 6.96406C2.65783 7.37406 3.01783 7.67406 3.43783 7.63406L5.47783 7.43406C10.7178 6.91406 15.9978 7.11406 21.2978 7.64406C21.3278 7.64406 21.3478 7.64406 21.3778 7.64406C21.7578 7.64406 22.0878 7.35406 22.1278 6.96406C22.1578 6.55406 21.8578 6.18406 21.4378 6.14406Z" fill="#E50000" />
                    <path d="M19.5979 9.05406C19.3579 8.80406 19.0279 8.66406 18.6879 8.66406H6.04791C5.70791 8.66406 5.36791 8.80406 5.13791 9.05406C4.90791 9.30406 4.77791 9.64406 4.79791 9.99406L5.41791 20.2541C5.52791 21.7741 5.66791 23.6741 9.15791 23.6741H15.5779C19.0679 23.6741 19.2079 21.7841 19.3179 20.2541L19.9379 10.0041C19.9579 9.64406 19.8279 9.30406 19.5979 9.05406ZM14.0279 18.6641H10.6979C10.2879 18.6641 9.94791 18.3241 9.94791 17.9141C9.94791 17.5041 10.2879 17.1641 10.6979 17.1641H14.0279C14.4379 17.1641 14.7779 17.5041 14.7779 17.9141C14.7779 18.3241 14.4379 18.6641 14.0279 18.6641ZM14.8679 14.6641H9.86791C9.45791 14.6641 9.11791 14.3241 9.11791 13.9141C9.11791 13.5041 9.45791 13.1641 9.86791 13.1641H14.8679C15.2779 13.1641 15.6179 13.5041 15.6179 13.9141C15.6179 14.3241 15.2779 14.6641 14.8679 14.6641Z" fill="#E50000" />
                  </svg>
                  <p className='text-sm font-medium text-black'>{t('productPage.delete')}</p>
                </div>
          </div>}
        </div>
        <div className='flex justify-between items-stretch gap-1'>
          <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
            <p className='font-bold leading-3'>{t('packageItem.items')}</p>
            <p>{p.items_count}</p>
          </span>
          <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
            <p className='font-bold leading-3'>{t('packageItem.quantity')}</p>
            <p>{p.quantity}</p>
          </span>
          <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 pt-[2px] pb-[1px] text-[10px] text-black rounded-md'>
            <p className='font-bold leading-3'>{t('packageItem.influencer')}</p>
            <p>{p.influencer_count}</p>
          </span>
        </div>
      </div>
      {actions && <div className='fixed w-[400px] h-screen z-[51] top-0 -ml-[29px] sm:w-full bg-black/40 ' onClick={() => setActions(false)}></div>}
    </div>
  )
}