
import React, { useEffect, useState } from 'react';

function Textarea(props) {
  const [isFocused, setIsFocused] = useState(false);
  const [valid, setValid] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  

  const handleChange = (e) => {
    if (props.maxLength) {
      setValid(e.target.value.length >= props.minLength ? true : false)
      document.getElementById(props.name).value = e.target.value.slice(0, props.maxLength)
      if (e.target.value.length < props.maxLength)
        props.onChange(e)
      return;
    }
  }

  useEffect(() => {
    const svg = document.getElementById(props.name).previousElementSibling
    if(svg){
        const childNodes = svg.childNodes;
        childNodes.forEach(node => {
          if (node.style && valid) {
            node.style.fill = '#133BB7';
          }
          else
          node.style.fill = '#A7A9B7';
        });
    }
  }, [valid])

  useEffect(()=>{
    setValid(props.value?.length >= props.minLength ? true : false)
  }, [props.value])

  return (
    <div className='flex flex-col gap-1'>
        <label className='text-black text-lg font-normal'>{props.label}</label>
        <div
        className={`flex justify-start items-start gap-2 bg-white ${valid ? 'border-blue' : 'border-grey/50'} border-[1px] rounded-lg px-3 py-2 transition-all ease-in duration-150 ${valid && 'border-blue'}`}
        >
        {props.children}
        <textarea
            id={props.name}
            type={props.type}
            onChange={handleChange}
            required={props.required}
            name={props.name}
            defaultValue={props.value || ''}
            placeholder={props.placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`w-full outline-none text-black text-lg resize-none ${props.height? props.height: 'h-[100px]'} leading-[20px]`}
        />
        </div>
    </div>

  );
}

export default Textarea;

