import React from 'react'

function Loader({ height }) {
    return (
        <div className={`flex justify-center items-center w-full ${height? height : 'h-[600px] sm:h-[520px]'}`}>
            <img src={require('../assets/loading.webp')} className='w-[70px]' />
        </div>
    )
}

export default Loader
