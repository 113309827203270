import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Input2 from '../components/Input2'
import Input from '../components/Input'
import Button from '../components/Button'
import { toast } from 'react-toastify'
import { convertBase64 } from '../utils'
import Select from 'react-select'
import { selectStyles } from '../utils/selectStyles'
import { MdOutlineCategory } from 'react-icons/md'
import { BACKEND_HOST } from '../constants/Index'
import Loader from '../components/Loader'
import imageCompression from 'browser-image-compression';
import { validate } from '../utils/validation'

function AddUser({ }) {
  const { role, id } = useParams()
  const [t] = useTranslation("global")
  const [formData, setFormData] = useState({})
  const [activeButton, setActiveButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uniqueId, setuniqueId] = useState(null)
  const [img, setImg] = useState(null)
  const [image, setImage] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [defaultValueFee, setDefaultValueFee] = useState({})
  const navigate = useNavigate()

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const [fees, setFees] = useState(null)
  const [categories, setCategories] = useState(null)

  const fetchCatgories = async () => {
    const response = await fetch(`${BACKEND_HOST}/api/category`, {
      method: 'GET',
      headers: {
        "Content-Type": "Application/json",
        token: localStorage.getItem('token')
      },
    })

    const res = await response.json()

    if (response.status === 200) {
      let options = []
      for (let i = 0; i < res.length; i++) {
        options.push({ value: res[i]._id, label: res[i].name })
      }
      setCategories(options)
    }
    else
      toast.error(res.message)
  }

  const fetchFees = async () => {
    const response = await fetch(`${BACKEND_HOST}/api/fee`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        token: localStorage.getItem('token')
      }
    })
    const res = await response.json()
    if (response.status === 200) {
      let options = []
      for (let i = 0; i < res.length; i++) {
        options.push({ value: res[i]._id, label: res[i].area_title })
      }
      setFees(options)
    }
    else {
      toast.error(res.message)
    }
  }

  const handleImg = async (e) => {
    e.stopPropagation();
    if (e.target.files[0]?.size >= 10000000) {
      toast.error('Image size should be less than 10MB')
      return;
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true
    };

    const compressedFile = await imageCompression(e.target.files[0], options);

    setImg(compressedFile)
    const base64 = await convertBase64(e.target.files[0])
    setImage(base64)
  }

  const fetchUniqueId = async () => {
    const response = await fetch(`${BACKEND_HOST}/api/user/unique_id`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        token: localStorage.getItem('token')
      },
    })

    const res = await response.json()
    if (response.status === 200) {
      formData.unique_id = res
      setuniqueId(res)
    }
    else {
      toast.error(res.message)
    }
  }

  const addNew = async(fd) => {
    const response = await fetch(`${BACKEND_HOST}/api/user/create-user`, {
      method: 'POST',
      maxBodyLength: "Infinity",
      headers: {
        token: localStorage.getItem('token')
      },
      body: fd
    })

    const res = await response.json()
    if (response.status === 201) {
      toast.success(res.message)
      navigate(-1)
    }
    else {
      toast.error(res.message)
    }
  }

  const update = async(fd) => {
    const response = await fetch(`${BACKEND_HOST}/api/user/update-user`, {
      method: 'POST',
      maxBodyLength: "Infinity",
      headers: {
        token: localStorage.getItem('token')
      },
      body: fd
    })

    const res = await response.json()
    if (response.status === 201) {
      toast.success(res.message)
      navigate(-1)
    }
    else {
      toast.error(res.message)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const fd = new FormData();

    console.log(typeof formData.fee);

    formData.fee = typeof(formData.fee) == 'string' ? formData.fee : formData?.fee?._id
    formData.category = typeof(formData.category) == 'string' ? formData.category : formData?.category?._id

    for (const [key, value] of Object.entries(formData)) {
      fd.append(key, value);
    }
    if(img)
    fd.append('profile', img);

    if(id)
      await update(fd)
    else
      await addNew(fd)

    setLoading(false)
  }

  useEffect(() => {
    let x = id? true : (img || true)
    if (validate(formData, role, id) && x) {
      setActiveButton(true)
    }
    else
      setActiveButton(false)
  })

  const fetchUserToBeEdit = async (_id) => {
    const response = await fetch(`${BACKEND_HOST}/api/user/get-user/${_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'Application/json',
        token: localStorage.getItem('token')
      }
    })
    const res = await response.json()
    if (response.status === 200) {
      setImage(`${BACKEND_HOST}/${res.profile_image}`)
      setDefaultValueFee({
        value: res?.fee?._id,
        lable: res?.fee?.area_title
      })

      setFormData(res)
      console.log(res)
    }
    else
      toast.error(res.message)
  }

  useEffect(()=>{
  }, [formData, defaultValueFee])


  useEffect(() => {
    if(id)
      fetchUserToBeEdit(id)
    else{
      formData.role = role
      fetchUniqueId()
    }
    fetchFees()
    fetchCatgories()
  }, [role, id])
  return (
    <form className={`top-0 fixed transition-all ease-in duration-200 w-[400px] right-[300px] md:right-[100px]  sm:overflow-x-hidden sm:right-0 sm:w-full  h-screen overflow-y-scroll z-[51] `} onSubmit={onSubmit}>
      <div className='w-[400px] m-auto bg-white flex flex-col gap-4 px-7 pb-24 sm:w-full'>
       {id && !formData._id ?
        <Loader /> 
       
       :<>
        <div className='flex h-[50px] w-full justify-start items-center mt-8'>
          <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={() => navigate(-1)}>
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <p className='text-black text-base capitalize text-center w-full -ml-[30px]'>{role}</p>
        </div>

        <div onClick={() => document.getElementById('img').click()} className='relative flex flex-col justify-center items-center gap-1 border-blue border-dashed border-[1px] rounded-lg bg-[#F2F4F9] h-[134px] cursor-pointer'>
          {image ?
            <img src={image} alt='profile' className='w-[100px] h-[100px] object-cover rounded-full' />
            :
            <>
              <img src={require('../assets/gallery.png')} alt='' className='w-[46px]' />
              <p className='text-grey text-center text-sm'>PNG, JPG, GIF (0.5M or less then 10M)</p>
            </>
          }
          <input required={id? false : false} type='file' accept='image/*' id='img' className='absolute left-0 opacity-0' onChange={handleImg} onClick={(e) => e.stopPropagation()} />
        </div>

        {role === 'advertiser' && <>
          <Input label={t('formLabels.id')} type="text" name="unique_id" unique_id={true} value={uniqueId || formData.unique_id} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          {!id && <Input label={t('formLabels.pw')} type="text" name="password" minLength={5} maxLength={50} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 10.5V8.5C6 5.19 7 2.5 12 2.5C17 2.5 18 5.19 18 8.5V10.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 19C13.3807 19 14.5 17.8807 14.5 16.5C14.5 15.1193 13.3807 14 12 14C10.6193 14 9.5 15.1193 9.5 16.5C9.5 17.8807 10.6193 19 12 19Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 22.5H7C3 22.5 2 21.5 2 17.5V15.5C2 11.5 3 10.5 7 10.5H17C21 10.5 22 11.5 22 15.5V17.5C22 21.5 21 22.5 17 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>}
          <Input label={t('formLabels.cname')} type="text" minLength={1} maxLength={50} value={formData?.companyName || ''} name="companyName" required={true} onChange={onChange}>
            <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_32_1833)">
                <path d="M10.9998 22.5H18.9998C23.0198 22.5 23.7398 20.89 23.9498 18.93L24.6998 10.93C24.9698 8.49 24.2698 6.5 19.9998 6.5H9.99983C5.72983 6.5 5.02983 8.49 5.29983 10.93L6.04983 18.93C6.25983 20.89 6.97983 22.5 10.9998 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 6.5V5.7C11 3.93 11 2.5 14.2 2.5H15.8C19 2.5 19 3.93 19 5.7V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17 13.5V14.5C17 14.51 17 14.51 17 14.52C17 15.61 16.99 16.5 15 16.5C13.02 16.5 13 15.62 13 14.53V13.5C13 12.5 13 12.5 14 12.5H16C17 12.5 17 12.5 17 13.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.65 11.5C22.34 13.18 19.7 14.18 17 14.52" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.62012 11.77C7.87012 13.31 10.4101 14.24 13.0001 14.53" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <filter id="filter0_d_32_1833" x="-1" y="0.5" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_32_1833" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_32_1833" result="shape" />
                </filter>
              </defs>
            </svg>

          </Input>
          <Input label={t('formLabels.cAdd')} type="text" minLength={1} maxLength={100} value={formData?.companyAddress || ''} name="companyAddress" required={true} onChange={onChange}>
            <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_32_1833)">
                <path d="M10.9998 22.5H18.9998C23.0198 22.5 23.7398 20.89 23.9498 18.93L24.6998 10.93C24.9698 8.49 24.2698 6.5 19.9998 6.5H9.99983C5.72983 6.5 5.02983 8.49 5.29983 10.93L6.04983 18.93C6.25983 20.89 6.97983 22.5 10.9998 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 6.5V5.7C11 3.93 11 2.5 14.2 2.5H15.8C19 2.5 19 3.93 19 5.7V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17 13.5V14.5C17 14.51 17 14.51 17 14.52C17 15.61 16.99 16.5 15 16.5C13.02 16.5 13 15.62 13 14.53V13.5C13 12.5 13 12.5 14 12.5H16C17 12.5 17 12.5 17 13.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.65 11.5C22.34 13.18 19.7 14.18 17 14.52" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.62012 11.77C7.87012 13.31 10.4101 14.24 13.0001 14.53" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <filter id="filter0_d_32_1833" x="-1" y="0.5" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_32_1833" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_32_1833" result="shape" />
                </filter>
              </defs>
            </svg>

          </Input>
          <Input label={t('formLabels.breg')} brgNo={false} minLength={1} maxLength={100} value={formData?.businessRegNo || ''} type="text" name="businessRegNo" required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 21.5H7C3 21.5 2 20.5 2 16.5V8.5C2 4.5 3 3.5 7 3.5H17C21 3.5 22 4.5 22 8.5V16.5C22 20.5 21 21.5 17 21.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 8.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 12.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 16.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.50043 11.79C9.50007 11.79 10.3104 10.9797 10.3104 9.98004C10.3104 8.98041 9.50007 8.17004 8.50043 8.17004C7.50079 8.17004 6.69043 8.98041 6.69043 9.98004C6.69043 10.9797 7.50079 11.79 8.50043 11.79Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 16.83C11.86 15.38 10.71 14.24 9.26 14.11C8.76 14.06 8.25 14.06 7.74 14.11C6.29 14.25 5.14 15.38 5 16.83" stroke="#151B33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.name2')} minLength={1} maxLength={100} type="text" value={formData?.name || ''} name="name" required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.tel')} telephone={true} type="text" name="telephone" value={formData?.telephone || ''} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.97 18.83C21.97 19.19 21.89 19.56 21.72 19.92C21.55 20.28 21.33 20.62 21.04 20.94C20.55 21.48 20.01 21.87 19.4 22.12C18.8 22.37 18.15 22.5 17.45 22.5C16.43 22.5 15.34 22.26 14.19 21.77C13.04 21.28 11.89 20.62 10.75 19.79C9.6 18.95 8.51 18.02 7.47 16.99C6.44 15.95 5.51 14.86 4.68 13.72C3.86 12.58 3.2 11.44 2.72 10.31C2.24 9.17 2 8.08 2 7.04C2 6.36 2.12 5.71 2.36 5.11C2.6 4.5 2.98 3.94 3.51 3.44C4.15 2.81 4.85 2.5 5.59 2.5C5.87 2.5 6.15 2.56 6.4 2.68C6.66 2.8 6.89 2.98 7.07 3.24L9.39 6.51C9.57 6.76 9.7 6.99 9.79 7.21C9.88 7.42 9.93 7.63 9.93 7.82C9.93 8.06 9.86 8.3 9.72 8.53C9.59 8.76 9.4 9 9.16 9.24L8.4 10.03C8.29 10.14 8.24 10.27 8.24 10.43C8.24 10.51 8.25 10.58 8.27 10.66C8.3 10.74 8.33 10.8 8.35 10.86C8.53 11.19 8.84 11.62 9.28 12.14C9.73 12.66 10.21 13.19 10.73 13.72C11.27 14.25 11.79 14.74 12.32 15.19C12.84 15.63 13.27 15.93 13.61 16.11C13.66 16.13 13.72 16.16 13.79 16.19C13.87 16.22 13.95 16.23 14.04 16.23C14.21 16.23 14.34 16.17 14.45 16.06L15.21 15.31C15.46 15.06 15.7 14.87 15.93 14.75C16.16 14.61 16.39 14.54 16.64 14.54C16.83 14.54 17.03 14.58 17.25 14.67C17.47 14.76 17.7 14.89 17.95 15.06L21.26 17.41C21.52 17.59 21.7 17.8 21.81 18.05C21.91 18.3 21.97 18.55 21.97 18.83Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" />
              <path d="M18.5 9.5C18.5 8.9 18.03 7.98 17.33 7.23C16.69 6.54 15.84 6 15 6" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M22 9.5C22 5.63 18.87 2.5 15 2.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.mob')} mobile={true} type="text" name="mobile" value={formData?.mobile || ''} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.mail')} type="email" name="email" required={true} value={formData?.email || ''} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.radd')} minLength={1} maxLength={100} type="text" value={formData?.receivingAddress || ''} name="receivingAddress" required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z" stroke="#133BB7" stroke-width="1.5" />
              <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#133BB7" stroke-width="1.5" />
            </svg>
          </Input>
          <Input label={t('formLabels.rec')} type="text" minLength={1} maxLength={100} value={formData?.receiver || ''} name="receiver" required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
        </>}

        {role === 'influencer' && <>
          <Input label={t('formLabels.id')} type="text"  unique_id={true} name="unique_id" value={uniqueId || formData?.unique_id} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          {!id && <Input label={t('formLabels.pw')} type="text"  name="password" minLength={5} maxLength={50}  required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 10.5V8.5C6 5.19 7 2.5 12 2.5C17 2.5 18 5.19 18 8.5V10.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 19C13.3807 19 14.5 17.8807 14.5 16.5C14.5 15.1193 13.3807 14 12 14C10.6193 14 9.5 15.1193 9.5 16.5C9.5 17.8807 10.6193 19 12 19Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 22.5H7C3 22.5 2 21.5 2 17.5V15.5C2 11.5 3 10.5 7 10.5H17C21 10.5 22 11.5 22 15.5V17.5C22 21.5 21 22.5 17 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>}
          <Input label={t('formLabels.channel')} type="text" minLength={1} maxLength={50} value={formData?.youtubeChannel || ''} name="youtubeChannel" required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 15.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5H15C20 22.5 22 20.5 22 15.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.51953 7.60999H21.4795" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.51953 2.60999V7.46999" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.4805 2.60999V7.01999" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.75 14.95V13.75C9.75 12.21 10.84 11.58 12.17 12.35L13.21 12.95L14.25 13.55C15.58 14.32 15.58 15.58 14.25 16.35L13.21 16.95L12.17 17.55C10.84 18.32 9.75 17.69 9.75 16.15V14.95V14.95Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.scount')} type="number" minLength={1} maxLength={10000000000000000} value={formData?.subscriberCount || ''} name="subscriberCount" required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.0001 7.66C17.9401 7.65 17.8701 7.65 17.8101 7.66C16.4301 7.61 15.3301 6.48 15.3301 5.08C15.3301 3.65 16.4801 2.5 17.9101 2.5C19.3401 2.5 20.4901 3.66 20.4901 5.08C20.4801 6.48 19.3801 7.61 18.0001 7.66Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M16.9704 14.94C18.3404 15.17 19.8504 14.93 20.9104 14.22C22.3204 13.28 22.3204 11.74 20.9104 10.8C19.8404 10.09 18.3104 9.85003 16.9404 10.09" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.97047 7.66C6.03047 7.65 6.10047 7.65 6.16047 7.66C7.54047 7.61 8.64047 6.48 8.64047 5.08C8.64047 3.65 7.49047 2.5 6.06047 2.5C4.63047 2.5 3.48047 3.66 3.48047 5.08C3.49047 6.48 4.59047 7.61 5.97047 7.66Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.00043 14.94C5.63043 15.17 4.12043 14.93 3.06043 14.22C1.65043 13.28 1.65043 11.74 3.06043 10.8C4.13043 10.09 5.66043 9.85003 7.03043 10.09" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0001 15.13C11.9401 15.12 11.8701 15.12 11.8101 15.13C10.4301 15.08 9.33008 13.95 9.33008 12.55C9.33008 11.12 10.4801 9.96997 11.9101 9.96997C13.3401 9.96997 14.4901 11.13 14.4901 12.55C14.4801 13.95 13.3801 15.09 12.0001 15.13Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.08973 18.28C7.67973 19.22 7.67973 20.76 9.08973 21.7C10.6897 22.77 13.3097 22.77 14.9097 21.7C16.3197 20.76 16.3197 19.22 14.9097 18.28C13.3197 17.22 10.6897 17.22 9.08973 18.28Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.viewCount')} type="url" name="viewCount" required={false} value={formData?.viewCount || ''} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5799 12.5C15.5799 14.48 13.9799 16.08 11.9999 16.08C10.0199 16.08 8.41992 14.48 8.41992 12.5C8.41992 10.52 10.0199 8.92004 11.9999 8.92004C13.9799 8.92004 15.5799 10.52 15.5799 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11.9998 20.77C15.5298 20.77 18.8198 18.69 21.1098 15.09C22.0098 13.68 22.0098 11.31 21.1098 9.89997C18.8198 6.29997 15.5298 4.21997 11.9998 4.21997C8.46984 4.21997 5.17984 6.29997 2.88984 9.89997C1.98984 11.31 1.98984 13.68 2.88984 15.09C5.17984 18.69 8.46984 20.77 11.9998 20.77Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.name')} minLength={1} maxLength={50} type="text" name="name" value={formData?.name || ''} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.radd')} minLength={1} maxLength={100} type="text" name="receivingAddress" value={formData?.receivingAddress || ''} required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z" stroke="#133BB7" stroke-width="1.5" />
              <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#133BB7" stroke-width="1.5" />
            </svg>
          </Input>
          <Input label={t('formLabels.rec')} minLength={1} maxLength={50} type="text" name="receiver" value={formData?.receiver || ''} required={true} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.mob')} mobile={true} type="text" name="mobile" required={true} value={formData?.mobile || ''} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.mail')} type="email" name="email" required={true} value={formData?.email || ''} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <div className='flex flex-col gap-1'>
            <label className='text-black text-lg font-normal'>{t('formLabels.select_area')}</label>
            <div className={`flex justify-start items-start gap-2 bg-white border-blue border-[1px] rounded-lg px-3 py-1 transition-all ease-in duration-150`}>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className='mt-1'>
                <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span className='w-full'>
                <Select onChange={(opt) => {
                  formData.fee = opt.value
                  setRefresh(s => !s)
                }} styles={selectStyles} defaultValue={{value:formData?.fee?._id || '', label: formData?.fee?.area_title|| ''}} options={fees} components={{ IndicatorSeparator: () => null }} isSearchable={true} />
              </span>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <label className='text-black text-lg font-normal'>{t('formLabels.cat')}</label>
            <div className={`flex justify-start items-start gap-2 bg-white border-blue border-[1px] rounded-lg px-3 py-1 transition-all ease-in duration-150`}>
              <MdOutlineCategory className='text-2xl mt-2 text-blue' />
              <span className='w-full'>
                <Select onChange={(opt) => {
                  formData.category = opt.value
                  setRefresh(s => !s)
                }} styles={selectStyles} defaultValue={{value:formData?.category || '', label: formData?.category?.name|| ''}}  options={categories} components={{ IndicatorSeparator: () => null }} isSearchable={true} />
              </span>
            </div>
          </div>
          <Input label={t('formLabels.preffered')} minLength={1} maxLength={50}  type="text" name="preffered" value={formData?.preffered || ''} required={false} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.75 12.4999L10.58 15.3299L16.25 9.66992" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.nonpreferred')} minLength={1} maxLength={50}  type="text" name="nonpreferred" value={formData?.nonpreferred || ''} required={false} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.75 12.4999L10.58 15.3299L16.25 9.66992" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.info')} minLength={1} maxLength={150}  type="text" name="p_info" value={formData?.p_info || ''} required={false} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.75 12.4999L10.58 15.3299L16.25 9.66992" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>

        </>}

        {role === 'staff' && <>
          <Input label={t('formLabels.id')} type="text" unique_id={true} name="unique_id"  value={uniqueId || formData.unique_id} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          {!id && <Input label={t('formLabels.pw')} minLength={5} maxLength={50} type="text" name="password" required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 10.5V8.5C6 5.19 7 2.5 12 2.5C17 2.5 18 5.19 18 8.5V10.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 19C13.3807 19 14.5 17.8807 14.5 16.5C14.5 15.1193 13.3807 14 12 14C10.6193 14 9.5 15.1193 9.5 16.5C9.5 17.8807 10.6193 19 12 19Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 22.5H7C3 22.5 2 21.5 2 17.5V15.5C2 11.5 3 10.5 7 10.5H17C21 10.5 22 11.5 22 15.5V17.5C22 21.5 21 22.5 17 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>}
          <Input label={t('formLabels.name2')} minLength={1} maxLength={50} type="text" name="name" value={formData?.name || ''} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.mob')} mobile={true} type="text" name="mobile" value={formData?.mobile || ''} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.mail')} type="email" name="email" required={true} value={formData?.email} onChange={onChange}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
          <Input label={t('formLabels.department')} minLength={1} maxLength={50} type="text" name="department" value={formData?.department || ''} required={true} onChange={onChange}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.70001 18.5H4.15002C2.72002 18.5 2 17.78 2 16.35V4.65002C2 3.22002 2.72002 2.5 4.15002 2.5H8.45001C9.88001 2.5 10.6 3.22002 10.6 4.65002V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.3702 8.91998V20.08C17.3702 21.69 16.5702 22.5 14.9602 22.5H9.12018C7.51018 22.5 6.7002 21.69 6.7002 20.08V8.91998C6.7002 7.30998 7.51018 6.5 9.12018 6.5H14.9602C16.5702 6.5 17.3702 7.30998 17.3702 8.91998Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13.3999 6.5V4.65002C13.3999 3.22002 14.1199 2.5 15.5499 2.5H19.8499C21.2799 2.5 21.9999 3.22002 21.9999 4.65002V16.35C21.9999 17.78 21.2799 18.5 19.8499 18.5H17.3699" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 11.5H14" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 14.5H14" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 22.5V19.5" stroke="#151B33" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Input>
        </>}

        <Button title={id? t('formLabels.update_btn'): t('formLabels.register')} active={activeButton} disabled={loading} />
        </>}
      </div>
    </form>
  )
}

export default AddUser
