import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Empty from '../components/Empty'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IoIosArrowDown } from "react-icons/io";
import { FaTrash } from 'react-icons/fa'
import { AiFillEdit } from "react-icons/ai";
import { BACKEND_HOST } from '../constants/Index'

function Faqs() {
    const [t] = useTranslation('global')
    const [faqs, setFaqs] = useState(null)

    const fetchFaqs = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/faqs`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json'
            },
        })

        const res = await response.json()
        if (response.status === 200) {
            setFaqs(res)
        }
        else
            toast.error(res.messsage)
    }

    useEffect(() => {
        fetchFaqs()
    }, [])

    return (
        <div className='relative min-h-[350px] max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
            {faqs?.length === 0 && <Empty />}
            {!faqs && <Loader />}
            {faqs && <>
                {faqs?.length !== 0 && <h1 className='text-black font-semibold text-base'>{t('faq')}</h1>}

                <div className='flex flex-col gap-2'>
                    {faqs.map((f, index) => {
                        return <Faq key={index} f={f} setFaqs={setFaqs} fetchFaqs={fetchFaqs} />
                    })}
                </div>

                <Link to={`/admin/add-faq`} className='bg-white p-2 w-[55px] shadow-2xl rounded-lg flex justify-center items-center fixed bottom-24 ml-[300px] sm:ml-[260px]  sm:right-5'>
                    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M19.5 35.821C28.4746 35.821 35.75 28.5457 35.75 19.571C35.75 10.5964 28.4746 3.32104 19.5 3.32104C10.5254 3.32104 3.25 10.5964 3.25 19.571C3.25 28.5457 10.5254 35.821 19.5 35.821Z" fill="#133BB7" />
                        <path d="M26 18.3523H20.7188V13.071C20.7188 12.4048 20.1663 11.8523 19.5 11.8523C18.8337 11.8523 18.2812 12.4048 18.2812 13.071V18.3523H13C12.3338 18.3523 11.7812 18.9048 11.7812 19.571C11.7812 20.2373 12.3338 20.7898 13 20.7898H18.2812V26.071C18.2812 26.7373 18.8337 27.2898 19.5 27.2898C20.1663 27.2898 20.7188 26.7373 20.7188 26.071V20.7898H26C26.6663 20.7898 27.2188 20.2373 27.2188 19.571C27.2188 18.9048 26.6663 18.3523 26 18.3523Z" fill="#133BB7" />
                    </svg>
                </Link>
            </>
            }
        </div>
    )
}

export default Faqs


function Faq({ f, setFaqs, fetchFaqs }) {
    const [state, setState] = useState(false)
    const deleteFaq = async (_id) => {
        const confirm = window.confirm('Are you sure deleting this?')
        if (!confirm)
            return;
        const response = await fetch(`${BACKEND_HOST}/api/faqs/${_id}`, {
            method: 'Delete',
            headers: {
                'Content-Type': 'Application/json'
            },
        })

        const res = await response.json()
        if (response.status === 200) {
            setFaqs(null)
            fetchFaqs()
            toast.success(res.message)
        }
        else
            toast.error(res.message)
    }
    return (
        <div className='relative overflow-hidden border-[1px] border-grey/50 rounded-lg flex flex-col'>
            <div className='px-3 py-2 flex justify-between items-center'>
                <div className='flex gap-2 justify-start items-center cursor-pointer' onClick={() => setState(!state)}>
                    <h1 className='text-black font-medium text-sm capitalize'>{f.question}</h1>
                    <IoIosArrowDown className={`text-black text-base transition-all ease-in duration-75 ${!state && 'rotate-180'}`} />
                </div>
                <div className='flex gap-3 justify-start items-center cursor-pointer'>
                    <Link to={`/admin/edit-faq/${f._id}`}>
                        <AiFillEdit className={`text-black cursor-pointer text-lg transition-all ease-in duration-75 `} />
                    </Link>
                    <FaTrash onClick={() => deleteFaq(f._id)} className={`text-red text-base cursor-pointer transition-all ease-in duration-75 `} />
                </div>
            </div>
            <div className={`overflow-hidden transition-all ease-in duration-150 bg-grey/20 ${state ? 'max-h-[0px] opacity-0' : 'max-h-[1000px] opacity-100'}`}>
                <p className='text-black font-normal px-3 py-2 text-sm capitalize'>{f.answer}</p>
            </div>
        </div>

    )
}


