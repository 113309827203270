import React, { useContext, useLayoutEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Products from '../pages/Products'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import AddProduct from '../pages/AddProduct'
import Packages from '../pages/Packages'
import AddPackage from '../pages/AddPackage'
import PackageProductSelection from '../components/PackageProductSelection'
import { AdvertiserContext } from '../state/AdvertiserProvider'
import PacakgeInfluencerSelection from '../components/PacakgeInfluencerSelection'
import Profile from '../pages/Profile'
import Send from '../pages/Send'
import PackageInvoicePopup from '../components/PackageInvoicePopup'
import AddPackage2 from '../pages/AddPackage2'
import Tracking from '../pages/Tracking'
import VerifyPackage from '../pages/VerifyPackage'
import AdvertiserFaq from '../pages/AdvertiserFaq'
import AdvertiserNotices from '../pages/AdvertiserNotices'
import PaymentConfirmation from '../pages/PaymentConfirmation'
import RefundConfirmation from '../pages/RefundConfirmation'

function Advertiser() {
  const { selectProductPopuup, selectInfluencerPopuup, packageInvoicePopup } = useContext(AdvertiserContext)
  const [selectedPackageForChat, setSelectedPackageForInvoice] = useState(null)
  return (
    <div className='relativpe overflow-x-hidden'>
    <Header />
    <div className='relative px-5 mt-[90px] py-6 mb-[100px]'>
        <Routes>
            <Route index element={<Navigate to='/advertiser/products' />} />
            <Route path='products' element={<Products />} />
            <Route path='addproduct' element={<AddProduct />} />
            <Route path='editproduct/:p_id' element={<AddProduct />} />
            <Route path='package' element={<Packages />} />
            <Route path='AddPackage' element={<AddPackage2 />} />
            <Route path='editpackage/:_id' element={<AddPackage2 />} />
            <Route path='profile' element={<Profile />} />
            <Route path='send' element={<Send />} />
            <Route path='tracking' element={<Tracking />} />
            <Route path='verify/:_id' element={<VerifyPackage setSelectedPackageForInvoice={setSelectedPackageForInvoice}/>} />
            <Route path='chat' element={<Goback/>} />
            <Route path='faqs' element={<AdvertiserFaq/>} />
            <Route path='notices' element={<AdvertiserNotices/>} />
            <Route path='payment-confirmation' element={<PaymentConfirmation/>} />
            <Route path='refund-confirmation' element={<RefundConfirmation />} />
        </Routes>
       {selectProductPopuup && <PackageProductSelection/>}
       {selectInfluencerPopuup && <PacakgeInfluencerSelection />}
       {packageInvoicePopup && <PackageInvoicePopup />}
    </div>
    <Footer selectedPackageForChat={selectedPackageForChat}/>
</div>
  )
}

export default Advertiser


const Goback = () => {
  const navigate = useNavigate()
  useLayoutEffect(()=>{
    navigate(-1)
  }, [])
}