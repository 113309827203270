

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Empty from '../components/Empty'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IoIosArrowDown } from "react-icons/io";
import { FaTrash } from 'react-icons/fa'
import { AiFillEdit } from "react-icons/ai";
import { BACKEND_HOST } from '../constants/Index'

function AdvertiserNotices() {
    const [t] = useTranslation('global')
    const [faqs, setFaqs] = useState(null)

    const fetchFaqs = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/notices`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json'
            },
        })

        const res = await response.json()
        if (response.status === 200) {
            setFaqs(res)
        }
        else
            toast.error(res.messsage)
    }

    useEffect(() => {
        fetchFaqs()
    }, [])

    return (
        <div className='relative min-h-[350px] max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
            {faqs?.length === 0 && <Empty />}
            {!faqs && <Loader />}
            {faqs && <>
                {faqs?.length !== 0 && <h1 className='text-black font-semibold text-base'>{t('notice')}</h1>}

                <div className='flex flex-col gap-2'>
                    {faqs.map((f, index) => {
                        return <Faq key={index} f={f} setFaqs={setFaqs} fetchFaqs={fetchFaqs} />
                    })}
                </div>
            </>
            }
        </div>
    )
}

export default AdvertiserNotices


function Faq({ f, setFaqs, fetchFaqs }) {
    const [state, setState] = useState(false)

    return (
        <div className='relative overflow-hidden border-[1px] border-grey/50 rounded-lg flex flex-col'>
            <div className='px-3 py-2 flex justify-between items-center' onClick={() => setState(!state)}>
                <h1 className='text-black font-medium text-sm capitalize'>{f.question}</h1>
                <IoIosArrowDown className={`text-black text-base transition-all ease-in duration-75 ${!state && 'rotate-180'}`} />
            </div>
            <div className={`overflow-hidden transition-all ease-in duration-150 bg-grey/20 ${state ? 'max-h-[0px] opacity-0' : 'max-h-[1000px] opacity-100'}`}>
                <p className='text-black font-normal px-3 py-2 text-sm capitalize'>{f.answer}</p>
            </div>
        </div>

    )
}


