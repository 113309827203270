import React from 'react'
import { useTranslation } from 'react-i18next'

function Empty() {
    const [t] = useTranslation('global')
  return (
    <>
    <div className='border-dashed border-[1px] border-blue rounded-xl bg-[#F2F4F9] h-[150px] flex justify-center items-center'>
        <p className='text-base text-black font-semibold'>{t('empty')}</p>
    </div>
    <p className='text-center text-blue text-sm font-bold mt-2'>{t('emptyText')}</p>
    </>
  )
}

export default Empty
