import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { filterStyles } from '../utils/selectStyles'
import { BACKEND_HOST } from '../constants/Index'
import { AdvertiserContext } from '../state/AdvertiserProvider'

function PackageProductSelection() {
    const [products, setProducts] = useState([])
    const [user_products, setuserProducts] = useState(1)
    const [sort, setSort] = useState(1)
    const { setSelectProductPopup } = useContext(AdvertiserContext)

    const search = (e) => {
        fetchProducts(e.target.value)
    }

    const fetchProducts = async (title = '') => {
        const response = await fetch(`${BACKEND_HOST}/api/products?title=${title}&sort=${sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            setuserProducts(res.user_products)
            setProducts(res.products)
        }
        else
            toast.error(res.message)
    }

    useEffect(() => {
        fetchProducts()
    }, [sort])
    return (
        <div className='bg-black/50 sm:w-full -ml-[20px] w-[400px] h-screen fixed top-0  z-[60]'>

            <div className='bg-white h-[60%] absolute bottom-0 z-[61] w-full rounded-t-3xl flex flex-col justify-start items-center'>
                <span onClick={()=>setSelectProductPopup(false)} className='flex justify-center items-center p-1 bg-red rounded-full h-[30px] w-[30px] -mt-[14px] cursor-pointer'>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.50146 5.59644L19.5005 19.5955" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.50045 19.5955L19.4995 5.59644" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>

                <div className='flex flex-col w-full px-5 py-5'>

                    {products && user_products !== 0 && <>

                        <div className='flex gap-2 justify-between items-center'>
                            <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[70%]'>
                                <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>

                            <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: 'des', label: 'Newest' }} options={[{ value: 'des', label: 'Newest' }, { value: 'asc', label: 'Oldest' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
                        </div>

                        <div className='flex flex-col gap-3 mt-2 overflow-scroll h-[330px]'>

                            {products?.map((p, index) => {
                                return <Item key={index} p={p} />
                            })}

                        </div>
                    </>}
                </div>
            </div>
        </div>
    )
}

export default PackageProductSelection


const Item = ({ p }) => {
    const [quantity, setQuantity] = useState(1);

    const { selectProductPopuup, setRefresh, setSelectProductPopup } = useContext(AdvertiserContext)

    const increment = () => {
        if (quantity < 99) {
            setQuantity(quantity + 1);
        }
    };

    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const selectProduct = (p) => {
        selectProductPopuup.product = p
        setRefresh(state => !state)
        setSelectProductPopup(false)
    }

    useEffect(()=> {
        p.product_quantity = quantity
    }, [quantity])


    return (
        <>
            <div className='flex justify-between items-center border-[1px] border-grey/30 rounded-lg p-2'>
                <div className='w-[70%] flex justify-start items-start cursor-pointer gap-2'  onClick={() => selectProduct(p)}>
                <div className='w-[50px] h-[50px] rounded-md bg-grey/50'>
        <img src={`${BACKEND_HOST}/${p.image}`} alt='product' className='rounded-md w-[50px] h-[50px] object-cover bg-grey/20' />
        </div>
                    {/* <img src={`${BACKEND_HOST}/${p.image}`} alt='product' className='bg-grey/50 w-[50px] h-[50px] object-cover rounded-lg' /> */}
                    <div className='flex justify-center items-start flex-col w-[70%]'>
                        <h1 className='text-black text-sm font-bold capitalize'>{p.title}</h1>
                        <p className='text-black text-sm'>{p.description.substr(0, 27)}</p>
                    </div>
                </div>

                <div className='flex gap-1 items-center justify-center'>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className='cursor-pointer' onClick={decrement}>
                        <path d="M14.2579 0.618408H5.87787C2.23787 0.618408 0.0678711 2.78841 0.0678711 6.42841V14.7984C0.0678711 18.4484 2.23787 20.6184 5.87787 20.6184H14.2479C17.8879 20.6184 20.0579 18.4484 20.0579 14.8084V6.42841C20.0679 2.78841 17.8979 0.618408 14.2579 0.618408ZM14.0679 11.3684H6.06787C5.65787 11.3684 5.31787 11.0284 5.31787 10.6184C5.31787 10.2084 5.65787 9.86841 6.06787 9.86841H14.0679C14.4779 9.86841 14.8179 10.2084 14.8179 10.6184C14.8179 11.0284 14.4779 11.3684 14.0679 11.3684Z" fill="#E50000" />
                    </svg>
                    <input type='number' value={quantity} className='bg-[#F9F9F9] outline-none  font-semibold text-sm text-center w-[20px]' />
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className='cursor-pointer' onClick={increment}>
                        <path d="M16.3379 2.61841H7.95795C4.31795 2.61841 2.14795 4.78841 2.14795 8.42841V16.7984C2.14795 20.4484 4.31795 22.6184 7.95795 22.6184H16.3279C19.9679 22.6184 22.1379 20.4484 22.1379 16.8084V8.42841C22.1479 4.78841 19.9779 2.61841 16.3379 2.61841ZM16.1479 13.3684H12.8979V16.6184C12.8979 17.0284 12.5579 17.3684 12.1479 17.3684C11.7379 17.3684 11.3979 17.0284 11.3979 16.6184V13.3684H8.14795C7.73795 13.3684 7.39795 13.0284 7.39795 12.6184C7.39795 12.2084 7.73795 11.8684 8.14795 11.8684H11.3979V8.61841C11.3979 8.20841 11.7379 7.86841 12.1479 7.86841C12.5579 7.86841 12.8979 8.20841 12.8979 8.61841V11.8684H16.1479C16.5579 11.8684 16.8979 12.2084 16.8979 12.6184C16.8979 13.0284 16.5579 13.3684 16.1479 13.3684Z" fill="#133BB7" />
                    </svg>
                </div>
            </div>
        </>
    )
}