import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Input2 from '../components/Input2'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { filterStyles, selectStyles } from '../utils/selectStyles'
import Button from '../components/Button'
import { BACKEND_HOST } from '../constants/Index'
import Input from '../components/Input'

function AddFee() {
    const [t] = useTranslation("global")
    const navigate = useNavigate()
    const [influencers, setInfluencers] = useState(null)
    const [fee, setFee] = useState(null)
    const [activeBtn, setActiveBtn] = useState(false)
    const [loading, setLoadfing] = useState(false)

    const { id } = useParams()

    const update = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/fee`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            },
            body: JSON.stringify(fee)
        })
        const res = await response.json()
        if (response.status === 200) {
            toast.success(res.message)
        }
        else {
            toast.error(res.message)
        }
    }

    const add = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/fee`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            },
            body: JSON.stringify(fee)
        })
        const res = await response.json()
        if (response.status === 201) {
            toast.success(res.message)
            setFee(res.fee)
        }
        else {
            toast.error(res.message)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoadfing(true)
        if (fee?._id)
            await update()
        else
            await add()
        navigate(-1)
        setLoadfing(false)
    }

    const onChange = (e) => {
        setFee({ ...fee, [e.target.name]: e.target.value })
        console.log(fee);
    }

    const fetchInful = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/user/influencer?email=&sort=all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })
        const res = await response.json()
        if (response.status === 200) {
            let options = []
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]._id,
                    label: res[i].name
                })
            }
            setInfluencers(options)
        }
        else {
            toast.error(res.message)
        }
    }


    const fetchFee = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/fee/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })
        const res = await response.json()
        if (response.status === 200) {
            console.log(res);
            setFee(res)
        }
        else {
            toast.error(res.message)
        }
    }

    useEffect(() => {
        if(id)
            fetchFee()
        fetchInful()
    }, [id])

    useEffect(() => {
        if (fee?.area_title.length >= 1 && fee?.area_fee > 0)
            setActiveBtn(true)
        else
            setActiveBtn(false)
    }, [fee])


    return (
        <form className={`top-0 fixed transition-all ease-in duration-200 w-[400px] right-[300px] md:right-[100px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `} onSubmit={onSubmit}>
            <div className='w-[400px]  h-screen m-auto bg-white flex flex-col gap-4 px-7 pb-24 sm:w-full'>

                <div className='flex h-[50px] w-full justify-start items-center mt-8'>
                    <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={() => navigate(-1)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <p className='text-black text-base capitalize text-center w-full -ml-[30px]'>{id ? t('feePage.edit') : t('feePage.add')}</p>
                </div>

                <Input minLength={1} maxLength={100} label={t('formLabels.area_title')} type="text" name="area_title" value={fee?.area_title} required={true} onChange={onChange}>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Input>
                <Input minLength={1} maxLength={100} label={t('formLabels.area_fee')} type="number" name="area_fee" value={fee?.area_fee} required={true} onChange={onChange}>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Input>
                <Button disabled={loading} active={activeBtn} title={fee?._id ? t('formLabels.update_btn') : t('formLabels.submitBtn')} />
            </div>
        </form>
    )
}

export default AddFee
