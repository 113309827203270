import React, { useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Users from '../pages/Users'
import AddUser from '../pages/AddUser'
import Profile from '../pages/Profile'
import Fee from '../pages/Fee'
import AddFee from '../pages/AddFee'
import Category from '../pages/Category'
import AddCategory from '../pages/AddCategory'
import Notices from '../pages/Notices'
import Faqs from '../pages/Faqs'
import AddFaq from '../pages/AddFaq'
import AddNotice from '../pages/AddNotice'
import Payments from '../pages/Payments'
import RefundPopup from '../components/RefundPopup'
import RefundConfirmation from '../pages/RefundConfirmation'

function Admin() {
    const [refund, setRefund] = useState(false)
    return (
        <div className='relative overflow-x-hidden'>
            <Header />
            <div className='px-5 mt-[90px] py-5 mb-[100px]'>
                <Routes>
                    <Route index element={<Navigate to='/admin/advertisers' />} />
                    <Route path='advertisers' element={<Users role="advertiser" />} />
                    <Route path='influencers' element={<Users role="influencer" />} />
                    <Route path='staff' element={<Users role="staff" />} />
                    <Route path='adduser/:role' element={<AddUser />} />
                    <Route path='edit-user/:role/:id' element={<AddUser />} />
                    <Route path='fee' element={<Fee />} />
                    <Route path='addfee' element={<AddFee />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path='editfee/:id' element={<AddFee />} />
                    <Route path='category' element={<Category />} />
                    <Route path='add-category' element={<AddCategory />} />
                    <Route path='editcategory/:id/:name' element={<AddCategory />} />
                    <Route path='notices' element={<Notices />} />
                    <Route path='add-notice' element={<AddNotice />} />
                    <Route path='edit-notice/:_id' element={<AddNotice />} />
                    <Route path='faqs' element={<Faqs />} />
                    <Route path='add-faq' element={<AddFaq />} />
                    <Route path='edit-faq/:_id' element={<AddFaq />} />
                    <Route path='payments' element={<Payments setRefund={setRefund} refund={refund} />} />
                    <Route path='refund-confirmation' element={<RefundConfirmation />} />
                </Routes>
                {refund && <RefundPopup refund={refund} setRefund={setRefund} />}
            </div>
            <Footer />
        </div>
    )
}

export default Admin
