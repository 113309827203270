import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import Input from '../components/Input'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants/Index'
import MainFooter from '../components/MainFooter'
import { validateSignUpForm } from '../utils/validation'

function Signup() {
    const [t] = useTranslation('global')
    const [data, setData] = useState(null)
    const [activeButton, setActiveButton] = useState(false)
    const [loading, setLoading] = useState(false)

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const response = await fetch(`${BACKEND_HOST}/api/user/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify(data)
        })

        const res = await response.json()
        if (response.status === 200) {
            toast.success('Your request has been mailed to admin, he will contact you soon.')
            setData(null)
            document.getElementById('company').value = ''
            document.getElementById('number').value = ''
            document.getElementById('name').value = ''
            document.getElementById('email').value = ''
        }
        else {
            toast.error(res.message)
        }

        setLoading(false)
    }

    useEffect(() => {
        if (validateSignUpForm(data)) {
            setActiveButton(true)
        }
        else
            setActiveButton(false)
    }, [data])


    return (
        <div className='flex flex-col gap-4 pb-32 h-screen overflow-y-scroll'>
            <span className='px-3'>
                <h1 className='text-black font-bold text-2xl mt-8'>{t('signupPage.heading')}</h1>
                <p className='text-grey text-base sm:text-sm font-semibold mt-2'>{t('signupPage.text')}</p>
            </span>

            <form onSubmit={onSubmit} className='flex flex-col gap-5 mt-10 px-3'>

                <Input label={t('signupPage.companyLabel')} placeholder={t('signupPage.companyPlaceholder')} type="text" name="company" required={true} onChange={onChange} minLength={1} maxLength={20}>
                    <img src={require('../assets/briefcase.png')} alt='company' className='w-[25px]'/>
                </Input>
                <Input label={t('signupPage.nameLabel')} placeholder={t('signupPage.namePlaceholder')} type="text" name="name" required={true} onChange={onChange} minLength={1} maxLength={20}>
                    <img src={require('../assets/user.png')} alt='company' className='w-[25px]'/>
                </Input>
                <Input mobile={true} label={t('signupPage.numberLabel')} placeholder={t('signupPage.numberPlaceholder')} type="text" name="number" required={true} onChange={onChange}>
                    <img src={require('../assets/call-calling.png')} alt='company' className='w-[25px]'/>
                </Input>
                <Input label={t('signupPage.emailLabel')} placeholder={t('signupPage.emailPlaceholder')} type="email" name="email" required={true} onChange={onChange}>
                    <img src={require('../assets/sms.png')} alt='company' className='w-[25px]'/>
                </Input>

                <span className='mt-2'></span>
                <Button title={t('signupPage.buttonTitle')} active={activeButton} disabled={loading} />
            </form>

            <hr className='bg-grey h-[1px] w-full opacity-20 mt-4' />
            <p className='text-grey text-sm font-semibold text-center my-4'>{t('loginPage.or')}</p>
            <Link to='/' className="px-5 py-4 mb-20 text-sm text-center text-black bg-white shadow-sm font-medium rounded-[50px] border-[1px] border-grey/50">{t('signupPage.login')}</Link>
            <MainFooter />
        </div >
    )
}

export default Signup
