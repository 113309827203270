import React, { useContext, useEffect, useState } from 'react'
import { AdvertiserContext } from '../state/AdvertiserProvider'
import Select from 'react-select'
import { filterStyles } from '../utils/selectStyles'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants/Index'

function PackageInvoicePopup() {
  const [t] = useTranslation("global")
  const { packageInvoicePopup, setPackageInvoicePopup, setRefresh } = useContext(AdvertiserContext)
  const [shipment, setShipment] = useState({package_id: packageInvoicePopup._id})
  const [activeBtn, setActiveBtn] = useState(false)
  const [refresh, setRefreshh] = useState(false)
  const [loading, setLoading] = useState(false)

  const shippings = [
    {
      value: 'UPS',
      label: 'UPS'
    },
    {
      value: 'Fedex',
      label: 'Fedex'
    },
    {
      value: 'CJ',
      label: 'CJ'
    },
  ]

  const onSubmit = async(e) => {
    e.preventDefault()
    setLoading(true)
    const response = await fetch(`${BACKEND_HOST}/api/packages/invoice`,{
      method: 'Post',
      headers: {
        'Content-Type': 'Application/json',
        token: localStorage.getItem('token')
      },
      body:JSON.stringify(shipment)
    })

    const res = await response.json()
    if(response.status === 200){
      toast.success(res.message)
      setRefresh(state => !state)
      setPackageInvoicePopup(false)
    }
    else
      toast.error(res.message)
    setLoading(false)
  }

  useEffect(()=>{
      if(shipment.invoiceno?.length > 1 && shipment.service)
        setActiveBtn(true)
      else
        setActiveBtn(false)
  }, [refresh])

  return (
    <div className='bg-black/50 sm:w-full -ml-[20px] w-[400px] h-screen fixed top-0  z-[60]'>

      <div className='bg-white h-[32%] absolute bottom-0 sm:bottom-14 z-[61] w-full rounded-t-3xl flex flex-col justify-start items-center'>
        <span onClick={() => setPackageInvoicePopup(false)} className='flex justify-center items-center p-1 bg-red rounded-full h-[30px] w-[30px] -mt-[14px] cursor-pointer'>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.50146 5.59644L19.5005 19.5955" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.50045 19.5955L19.4995 5.59644" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </span>
          <form onSubmit={onSubmit} className='flex flex-col w-full px-5 py-5 justify-between h-full'>
            <div className='flex gap-2 justify-between items-center'>
              <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[50%]'>
                <input onChange={(e) => {setShipment({...shipment, [e.target.name]: e.target.value}); setRefreshh(!refresh)}} name='invoiceno' type='text' className='bg-transparent text-sm text-grey w-full outline-none' placeholder={t('sendPage.place')}  />
              </span>
              <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[50%]'>
                <input onChange={(e) => {setShipment({...shipment, [e.target.name]: e.target.value}); setRefreshh(!refresh)}} name='service' type='text' className='bg-transparent text-sm text-grey w-full outline-none' placeholder={t('sendPage.shipping')}  />
              </span>


              {/* <Select onChange={(opt) => {setShipment({...shipment, 'service': opt.value}); setRefreshh(!refresh)} } options={shippings} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} /> */}
            </div>
              <p className='text-sm text-grey'>{t('sendPage.address')}</p>
              <p className='text-sm text-grey'>(수신인:시딩플러스, 010-7193-0618) 서울시 용산구 서빙고로 17, 센트럴파크타워 업무시설동 4층 아이스크리에이티브</p>

            <Button title={t('sendPage.proceed')} active={activeBtn} disabled={loading}/>
          </form>

      </div>
    </div>
  )
}

export default PackageInvoicePopup
