import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'
import { BACKEND_HOST } from '../constants/Index'

function Fee() {
    const [t] = useTranslation("global")
    const [fees, setFees] = useState(null)

    const fetchFees = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/fee`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })
        const res = await response.json()
        if (response.status === 200) {
            setFees(res)
        }
        else {
            toast.error(res.message)
        }
    }

    useEffect(() => {
        fetchFees()
    }, [])
    return (
        <div className='relative min-h-[350px] max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
            <h1 className='text-black font-semibold text-base'>{t('feePage.heading')}</h1>
            {!fees && <Loader />}
            <div className='flex flex-col gap-1'>
                {fees?.map((f, index) => {
                    return <div key={index} className='border-[1px] border-grey/30 rounded-lg px-3 py-2 flex justify-between items-center'>
                        <h1 className='text-black font-medium text-sm'>{f.area_title}</h1>
                        <div className='flex justify-end items-center gap-2'>
                            <h1 className='text-black font-medium text-sm'>{f.area_fee?.toLocaleString()}</h1>
                            <Link to={`/admin/editfee/${f._id}`}>
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" className='cursor-pointer'>
                                    <path d="M14.841 2.76367H7.15933C3.82266 2.76367 1.8335 4.75284 1.8335 8.0895V15.762C1.8335 19.1078 3.82266 21.097 7.15933 21.097H14.8318C18.1685 21.097 20.1577 19.1078 20.1577 15.7712V8.0895C20.1668 4.75284 18.1777 2.76367 14.841 2.76367ZM10.0377 16.9812C9.77183 17.247 9.26766 17.5037 8.901 17.5587L6.646 17.8795C6.5635 17.8887 6.481 17.8978 6.3985 17.8978C6.02266 17.8978 5.67433 17.7695 5.42683 17.522C5.12433 17.2195 4.996 16.7795 5.06933 16.2937L5.39016 14.0387C5.44516 13.6628 5.69266 13.1678 5.96766 12.902L10.056 8.81367C10.1293 9.00617 10.2027 9.19867 10.3035 9.41867C10.3952 9.61117 10.496 9.81284 10.606 9.99617C10.6977 10.152 10.7985 10.2987 10.881 10.4087C10.9818 10.5645 11.101 10.7112 11.1743 10.7937C11.2202 10.8578 11.2568 10.9037 11.2752 10.922C11.5043 11.197 11.7702 11.4537 11.9993 11.6462C12.0635 11.7103 12.1002 11.747 12.1185 11.7562C12.256 11.8662 12.3935 11.9762 12.5127 12.0587C12.6593 12.1687 12.806 12.2695 12.9618 12.352C13.1452 12.462 13.3468 12.5628 13.5485 12.6637C13.7593 12.7553 13.9518 12.8378 14.1443 12.902L10.0377 16.9812ZM15.9227 11.0962L15.0793 11.9487C15.0243 12.0037 14.951 12.0312 14.8777 12.0312C14.8502 12.0312 14.8135 12.0312 14.7952 12.022C12.9343 11.4903 11.4493 10.0053 10.9177 8.14451C10.8902 8.04367 10.9177 7.93367 10.991 7.8695L11.8435 7.017C13.2368 5.62367 14.566 5.65117 15.9318 7.017C16.6285 7.71367 16.9677 8.38284 16.9677 9.07951C16.9585 9.73951 16.6193 10.3995 15.9227 11.0962Z" fill="#133BB7" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                })}
            </div>

            <Link to={`/admin/addfee`} className='bg-white p-2 w-[55px] shadow-2xl rounded-lg flex justify-center items-center fixed bottom-24 ml-[300px] sm:ml-[260px]  sm:right-5'>
                <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M19.5 35.821C28.4746 35.821 35.75 28.5457 35.75 19.571C35.75 10.5964 28.4746 3.32104 19.5 3.32104C10.5254 3.32104 3.25 10.5964 3.25 19.571C3.25 28.5457 10.5254 35.821 19.5 35.821Z" fill="#133BB7" />
                    <path d="M26 18.3523H20.7188V13.071C20.7188 12.4048 20.1663 11.8523 19.5 11.8523C18.8337 11.8523 18.2812 12.4048 18.2812 13.071V18.3523H13C12.3338 18.3523 11.7812 18.9048 11.7812 19.571C11.7812 20.2373 12.3338 20.7898 13 20.7898H18.2812V26.071C18.2812 26.7373 18.8337 27.2898 19.5 27.2898C20.1663 27.2898 20.7188 26.7373 20.7188 26.071V20.7898H26C26.6663 20.7898 27.2188 20.2373 27.2188 19.571C27.2188 18.9048 26.6663 18.3523 26 18.3523Z" fill="#133BB7" />
                </svg>
            </Link>
        </div>
    )
}

export default Fee
