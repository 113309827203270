import { toast } from "react-toastify";
import axios from 'axios'
import { PORTONE_API_SECRET } from "../constants/Index";

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}

export const generateRandomID = () => {
  const currentYear = new Date().getFullYear() % 100;
  const currentDate = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const currentHours = new Date().getHours();
  const currentMinutes = new Date().getMinutes();

  const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
  const formattedDate = currentDate < 10 ? `0${currentDate}` : currentDate;
  const formattedHours = currentHours < 10 ? `0${currentHours}` : currentHours;
  const formattedMinutes = currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes;
  const randomID = `${currentYear}${formattedMonth}${formattedDate}${formattedHours}${formattedMinutes}${Math.floor(Math.random() * 100)}`.padEnd(11, '0');

  return randomID;
}

export const extractPackages = (packages) => {

  
  for (let i = 0; i < packages.length; i++) {
    let items = [];
    let influencers = [];
    
    for (let j = 0; j < packages[i]?.packageItemIds?.length; j++) {
      console.log( packages[i]?.packageItemIds[j]);
      if (!items.some(x => x === packages[i]?.packageItemIds[j]?.product?._id))
        items.push(packages[i]?.packageItemIds[j]?.product?._id)
      if (!influencers.some(x => x === packages[i]?.packageItemIds[j].influencer?._id))
        influencers.push(packages[i]?.packageItemIds[j].influencer?._id)
    }

    packages[i].items_count = items.length;
    packages[i].influencer_count = influencers.length;
  }

  return packages;
}


const getAuthorzation = async () => {
  const config = {
      url: 'https://api.portone.io/login/api-secret',
      method: 'post',
      data: {
          "apiSecret": PORTONE_API_SECRET
      },
      headers: {
          'Content-Type': 'application/json', 
      },
      timeout: 5000,
  };

  try {
      const response = await axios(config);
      return response.data.accessToken
  } catch (error) {
      console.error(error);
  }
  return null
}

export const portoneRefundApi = async (refund) => {
  const token = await getAuthorzation()
  if(!token)
      throw new Error("Access token could not be fetched from portone")

  const config = {
      url:   `https://api.portone.io/payments/${refund.paymentID}/cancel`,
      method: 'post',
      data: {
          "paymentId": refund.paymentID,
          "reason": "Customer Request",
          "amount": Number(refund.refundAmount),
          "currentCancellableAmount": Number(refund.totalPayment),
      },
      headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`
      },
      timeout: 5000,
  };

  try {
      const response = await axios(config);

      console.log(response.status);
      if(response.status === 200)
          return true;
      else
          toast.error("PortOne: ", response.data.message)
  } catch (error) {
      console.error(error);
  }
  
  return false;

}