import React from 'react'

function PCLayout({URL}) {
  return (
    <div className='sm:hidden w-full bg-black h-screen fixed top-0'>
         <iframe src={URL} width="420" height="700" className='border-[1px] border-yellow-500 h-screen absolute left-[40%]'></iframe>
    </div>
  )
}

export default PCLayout
