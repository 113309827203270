

import React, { useEffect, useState } from 'react'
import Input2 from '../components/Input2'
import { noticeuestionCircle } from "react-icons/fa";
import Button from '../components/Button';
import Textarea from '../components/Textarea';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { RiQuestionAnswerFill } from "react-icons/ri";
import { toast } from 'react-toastify'
import Loader from '../components/Loader';
import { BACKEND_HOST } from '../constants/Index';
import Input from '../components/Input';


function AddNotice() {
    const [t] = useTranslation('global')
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [notice, setNotice] = useState(null)
    const [activeBtn, setActiveBtn] = useState(false)

    const { _id } = useParams()

    const onChange = (e) => {
        setNotice({ ...notice, [e.target.name]: e.target.value })
        console.log(notice);
    }

    const add = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/notices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify(notice)
        })

        const res = await response.json()
        if (response.status === 201) {
            toast.success(res.message)
            navigate(-1)
        }
        else
            toast.error(res.message)
    }
    const update = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/notices`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify(notice)
        })

        const res = await response.json()
        if (response.status === 200) {
            toast.success(res.message)
            navigate(-1)
        }
        else
            toast.error(res.message)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (_id)
            await update()
        else
            await add()
        setLoading(false)
    }

    const fetchnotice = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/notices/${_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json'
            },
        })

        const res = await response.json()
        if (response.status === 200) {
            setNotice(res)
        }
        else
            toast.error(res.messsage)
    }

    useEffect(() => {
        fetchnotice()
    }, [_id])

    useEffect(() => {
        if (notice?.question?.length >= 1 && notice?.answer?.length >= 1)
            setActiveBtn(true)
        else
            setActiveBtn(false)
    }, [notice])
    return (
        <form className={`top-0 fixed transition-all ease-in duration-200 w-[400px] md:right-[100px] right-[300px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `} onSubmit={onSubmit}>
            <div className='w-[400px]  h-screen m-auto bg-white flex flex-col gap-4 px-7 pb-24 sm:w-full'>
                {_id && !notice ?
                    <Loader />
                    :
                    <>
                        <div className='flex h-[50px] w-full justify-start items-center mt-8'>
                            <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={() => navigate(-1)}>
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                            <p className='text-black text-base capitalize text-center w-full -ml-[30px]'>{_id ? t('notices') : t('notices')}</p>
                        </div>

                        <Input minLength={1} maxLength={1000} label={t('formLabels.Title')} type="text" value={notice?.question} name="question" required={true} onChange={onChange}>
                            <noticeuestionCircle className='text-2xl text-blue' />
                        </Input>
                        <Textarea label={t("formLabels.Contents")} minLength={1}  maxLength={1000} value={notice?.answer || ''} type="text" name="answer" maxLength={2000} required={true} onChange={onChange}>
                            <RiQuestionAnswerFill className='text-2xl text-blue' />
                        </Textarea>
                        <Button disabled={loading} active={activeBtn} title={t("formLabels.submitBtn")} />
                    </>
                }
            </div>
        </form>
    )
}

export default AddNotice

