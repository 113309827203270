import React from 'react'

function Button({title, active, disabled}) {
  return (
    <button disabled={disabled || !active} className={`${active? 'bg-blue':  'bg-[#8C8E98] cursor-not-allowed'} ${disabled && 'cursor-wait opacity-50'} px-5 py-2 text-lg text-white font-medium rounded-[50px] transition-all ease-in duration-200`}>
        {title}
    </button>
  )
}

export default Button
