import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PiDotsThreeOutlineThin } from 'react-icons/pi'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants/Index'
import { filterStyles } from '../utils/selectStyles'
import { AdvertiserContext } from '../state/AdvertiserProvider'
import Loader from '../components/Loader'
import { extractPackages, portoneRefundApi } from '../utils'
import { useNavigate } from 'react-router-dom'
import { getSeoulTime } from '../utils/getSeoulTime'

function Send() {
    const [user_packages, setuser_packages] = useState(0)
    const [packages, setPackages] = useState(null)
    const { refresh } = useContext(AdvertiserContext)
    const [t] = useTranslation("global")

    const [sort, setSort] = useState(0)

    const search = (e) => {
        fetchPackages(e.target.value)
    }

    const fetchPackages = async (packageid = '') => {
        const response = await fetch(`${BACKEND_HOST}/api/packages/processed_payment_packages?packageid=${packageid}&sort=${sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            setuser_packages(res.couunt)
            let packages = extractPackages(res.packages)
            setPackages(packages)
        }
        else
            toast.error(res.message)
    }


    useEffect(() => {
        setPackages(null)
        fetchPackages()
    }, [sort, refresh])
    return (
        <div className='max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
            {!packages && <Loader />}
            {user_packages == 0 && packages &&
                <div>
                    <div className='border-dashed border-[1px] border-blue rounded-xl bg-[#F2F4F9] h-[150px] flex flex-col justify-center items-center cursor-pointer'>
                        <p className='text-base text-black font-semibold'>{t('sendPage.no_pack')}</p>
                    </div>
                    <p className='text-center text-blue text-sm font-bold mt-2 w-[70%] m-auto'>{t('sendPage.no_pack_text')}</p>
                </div>}

            {user_packages !== 0 && packages && <>

                <div className='flex gap-2 justify-between items-center'>
                    <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[70%]'>
                        <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: '0', label: 'Newest' }} options={[{ value: '0', label: 'Newest' }, { value: '1', label: 'A-Z' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
                </div>

                <p className='text-black font-medium text-base'>{packages?.length} Packages</p>


                <div className='flex flex-col gap-2'>

                    {packages?.map((p, index) => {
                        p.quantity = 0
                        for (let i = 0; i < p.packageItemIds.length; i++) {
                            p.quantity += p.packageItemIds[i].product_quantity

                        }
                        return <Item key={index} p={p} />
                    })}

                </div>


            </>}

        </div>
    )
}

export default Send


const Item = ({ p }) => {
    const [t] = useTranslation("global")
    const { setPackageInvoicePopup } = useContext(AdvertiserContext)
    const [actions, setActions] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()


    const callApi = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/payments/refund`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            },
            body: JSON.stringify({ _id: p._id, refund_amount: p.totalPayment })
        })

        const res = await response.json()
        if (response.status === 200) {
            redirect(true)
        }
        else
            redirect(false)
    }

    const refund = async () => {
        const confirm = window.confirm("Are you sure asking for refund?")
        if (!confirm)
            return;
        setLoading(true)
        try {
            p.refundAmount = p.totalPayment
            const caneceled = await portoneRefundApi(p)
            if (caneceled)
                await callApi()
            else
                redirect(false)

            setActions(false)
        } catch (error) {

        }
        setLoading(false)
    }

    const redirect = (status) => {
        navigate(`/advertiser/refund-confirmation?packageId=${p.packageID}&status=${status}`)
    }

    return (
        <div className='p-2 rounded-lg border-[1px] border-grey/50 flex justify-start items-stretch gap-2'>
            <div className='w-[70px] h-[60px] rounded-md bg-grey/50'>
                <img src={`${BACKEND_HOST}/${p.packageItemIds[0]?.product?.image}`} className='rounded-md w-[60px] h-[60px] object-cover bg-grey/20' />
            </div>
            <div className='flex flex-col w-full gap-1 relative'>
                <div className='flex justify-between items-center'>
                <span className='flex justify-start items-center gap-2'>
                            <h1 className='text-blue text-sm font-semibold capitalize'>{p.packageID}</h1>
                            <p className='text-[12px] text-grey'>{getSeoulTime(p.createdAt)}</p>
                        </span>
                    <PiDotsThreeOutlineThin onClick={() => { setActions(true) }} className='text-base cursor-pointer' />
                    {/* {actions && <div className='absolute bg-white z-[60] border-grey border-2 w-[120px] flex flex-col gap-[6px] p-2 top-0 right-5 rounded-s-2xl rounded-b-2xl' >
                        <div onClick={()=>{setActions(false); setPackageInvoicePopup(p)}}  className='flex justify-start items-center gap-3 cursor-pointer'>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5577 0.603027H6.17768C2.53768 0.603027 0.367676 2.77303 0.367676 6.41303V14.783C0.367676 18.433 2.53768 20.603 6.17768 20.603H14.5477C18.1877 20.603 20.3577 18.433 20.3577 14.793V6.41303C20.3677 2.77303 18.1977 0.603027 14.5577 0.603027ZM9.31768 16.113C9.02768 16.403 8.47768 16.683 8.07768 16.743L5.61768 17.093C5.52768 17.103 5.43768 17.113 5.34768 17.113C4.93768 17.113 4.55768 16.973 4.28768 16.703C3.95768 16.373 3.81768 15.893 3.89768 15.363L4.24768 12.903C4.30768 12.493 4.57768 11.953 4.87768 11.663L9.33768 7.20303C9.41768 7.41303 9.49768 7.62303 9.60768 7.86303C9.70768 8.07303 9.81768 8.29303 9.93768 8.49303C10.0377 8.66303 10.1477 8.82303 10.2377 8.94303C10.3477 9.11303 10.4777 9.27303 10.5577 9.36303C10.6077 9.43303 10.6477 9.48303 10.6677 9.50303C10.9177 9.80303 11.2077 10.083 11.4577 10.293C11.5277 10.363 11.5677 10.403 11.5877 10.413C11.7377 10.533 11.8877 10.653 12.0177 10.743C12.1777 10.863 12.3377 10.973 12.5077 11.063C12.7077 11.183 12.9277 11.293 13.1477 11.403C13.3777 11.503 13.5877 11.593 13.7977 11.663L9.31768 16.113ZM15.7377 9.69303L14.8177 10.623C14.7577 10.683 14.6777 10.713 14.5977 10.713C14.5677 10.713 14.5277 10.713 14.5077 10.703C12.4777 10.123 10.8577 8.50303 10.2777 6.47303C10.2477 6.36303 10.2777 6.24303 10.3577 6.17303L11.2877 5.24303C12.8077 3.72303 14.2577 3.75303 15.7477 5.24303C16.5077 6.00303 16.8777 6.73303 16.8777 7.49303C16.8677 8.21303 16.4977 8.93303 15.7377 9.69303Z" fill="#133BB7" />
                        </svg>
                        <p className='text-sm font-medium text-black'>{t('sendPage.INVOICE')}</p>
                        </div>
                        <hr className='h-[1px] border-b-[1px] border-grey/30 w-full' />
                        <button disabled={loading} onClick={refund} className='flex justify-start items-center gap-2  cursor-pointer'>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.4378 6.14406C19.8278 5.98406 18.2178 5.86406 16.5978 5.77406V5.76406L16.3778 4.46406C16.2278 3.54406 16.0078 2.16406 13.6678 2.16406H11.0478C8.71783 2.16406 8.49783 3.48406 8.33783 4.45406L8.12783 5.73406C7.19783 5.79406 6.26783 5.85406 5.33783 5.94406L3.29783 6.14406C2.87783 6.18406 2.57783 6.55406 2.61783 6.96406C2.65783 7.37406 3.01783 7.67406 3.43783 7.63406L5.47783 7.43406C10.7178 6.91406 15.9978 7.11406 21.2978 7.64406C21.3278 7.64406 21.3478 7.64406 21.3778 7.64406C21.7578 7.64406 22.0878 7.35406 22.1278 6.96406C22.1578 6.55406 21.8578 6.18406 21.4378 6.14406Z" fill="#E50000" />
                            <path d="M19.5979 9.05406C19.3579 8.80406 19.0279 8.66406 18.6879 8.66406H6.04791C5.70791 8.66406 5.36791 8.80406 5.13791 9.05406C4.90791 9.30406 4.77791 9.64406 4.79791 9.99406L5.41791 20.2541C5.52791 21.7741 5.66791 23.6741 9.15791 23.6741H15.5779C19.0679 23.6741 19.2079 21.7841 19.3179 20.2541L19.9379 10.0041C19.9579 9.64406 19.8279 9.30406 19.5979 9.05406ZM14.0279 18.6641H10.6979C10.2879 18.6641 9.94791 18.3241 9.94791 17.9141C9.94791 17.5041 10.2879 17.1641 10.6979 17.1641H14.0279C14.4379 17.1641 14.7779 17.5041 14.7779 17.9141C14.7779 18.3241 14.4379 18.6641 14.0279 18.6641ZM14.8679 14.6641H9.86791C9.45791 14.6641 9.11791 14.3241 9.11791 13.9141C9.11791 13.5041 9.45791 13.1641 9.86791 13.1641H14.8679C15.2779 13.1641 15.6179 13.5041 15.6179 13.9141C15.6179 14.3241 15.2779 14.6641 14.8679 14.6641Z" fill="#E50000" />
                        </svg>
                        <p className='text-sm font-medium text-black'>{t('sendPage.CANCEL')}</p>
                        </button>
        
                    </div>} */}
                </div>
                <div className='flex justify-between items-stretch gap-1'>
                    <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
                        <p className='font-bold leading-3'>{t('packageItem.items')}</p>
                        <p>{p.items_count}</p>
                    </span>
                    <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
                        <p className='font-bold leading-3'>{t('packageItem.quantity')}</p>
                        <p>{p.quantity}</p>
                    </span>
                    <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 pt-[2px] pb-[1px] text-[10px] text-black rounded-md'>
                        <p className='font-bold leading-3'>{t('packageItem.influencer')}</p>
                        <p>{p.influencer_count}</p>
                    </span>
                </div>



                <div className='flex justify-start items-center gap-4 mt-2'>
                    <button onClick={()=>{setActions(false); setPackageInvoicePopup(p)}}  className='flex items-center justify-center rounded-xl gap-2 px-4 py-2 border-grey/40 border-[1px]'>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5577 0.603027H6.17768C2.53768 0.603027 0.367676 2.77303 0.367676 6.41303V14.783C0.367676 18.433 2.53768 20.603 6.17768 20.603H14.5477C18.1877 20.603 20.3577 18.433 20.3577 14.793V6.41303C20.3677 2.77303 18.1977 0.603027 14.5577 0.603027ZM9.31768 16.113C9.02768 16.403 8.47768 16.683 8.07768 16.743L5.61768 17.093C5.52768 17.103 5.43768 17.113 5.34768 17.113C4.93768 17.113 4.55768 16.973 4.28768 16.703C3.95768 16.373 3.81768 15.893 3.89768 15.363L4.24768 12.903C4.30768 12.493 4.57768 11.953 4.87768 11.663L9.33768 7.20303C9.41768 7.41303 9.49768 7.62303 9.60768 7.86303C9.70768 8.07303 9.81768 8.29303 9.93768 8.49303C10.0377 8.66303 10.1477 8.82303 10.2377 8.94303C10.3477 9.11303 10.4777 9.27303 10.5577 9.36303C10.6077 9.43303 10.6477 9.48303 10.6677 9.50303C10.9177 9.80303 11.2077 10.083 11.4577 10.293C11.5277 10.363 11.5677 10.403 11.5877 10.413C11.7377 10.533 11.8877 10.653 12.0177 10.743C12.1777 10.863 12.3377 10.973 12.5077 11.063C12.7077 11.183 12.9277 11.293 13.1477 11.403C13.3777 11.503 13.5877 11.593 13.7977 11.663L9.31768 16.113ZM15.7377 9.69303L14.8177 10.623C14.7577 10.683 14.6777 10.713 14.5977 10.713C14.5677 10.713 14.5277 10.713 14.5077 10.703C12.4777 10.123 10.8577 8.50303 10.2777 6.47303C10.2477 6.36303 10.2777 6.24303 10.3577 6.17303L11.2877 5.24303C12.8077 3.72303 14.2577 3.75303 15.7477 5.24303C16.5077 6.00303 16.8777 6.73303 16.8777 7.49303C16.8677 8.21303 16.4977 8.93303 15.7377 9.69303Z" fill="#133BB7" />
                        </svg>
                        <p className='text-black text-sm font-semibold'>{t("sendPage.INVOICE")}</p>
                    </button>
                    <button disabled={loading} onClick={refund} className='flex items-center justify-center rounded-xl gap-2 px-4 py-2 border-grey/40 border-[1px]'>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.4378 6.14406C19.8278 5.98406 18.2178 5.86406 16.5978 5.77406V5.76406L16.3778 4.46406C16.2278 3.54406 16.0078 2.16406 13.6678 2.16406H11.0478C8.71783 2.16406 8.49783 3.48406 8.33783 4.45406L8.12783 5.73406C7.19783 5.79406 6.26783 5.85406 5.33783 5.94406L3.29783 6.14406C2.87783 6.18406 2.57783 6.55406 2.61783 6.96406C2.65783 7.37406 3.01783 7.67406 3.43783 7.63406L5.47783 7.43406C10.7178 6.91406 15.9978 7.11406 21.2978 7.64406C21.3278 7.64406 21.3478 7.64406 21.3778 7.64406C21.7578 7.64406 22.0878 7.35406 22.1278 6.96406C22.1578 6.55406 21.8578 6.18406 21.4378 6.14406Z" fill="#E50000" />
                            <path d="M19.5979 9.05406C19.3579 8.80406 19.0279 8.66406 18.6879 8.66406H6.04791C5.70791 8.66406 5.36791 8.80406 5.13791 9.05406C4.90791 9.30406 4.77791 9.64406 4.79791 9.99406L5.41791 20.2541C5.52791 21.7741 5.66791 23.6741 9.15791 23.6741H15.5779C19.0679 23.6741 19.2079 21.7841 19.3179 20.2541L19.9379 10.0041C19.9579 9.64406 19.8279 9.30406 19.5979 9.05406ZM14.0279 18.6641H10.6979C10.2879 18.6641 9.94791 18.3241 9.94791 17.9141C9.94791 17.5041 10.2879 17.1641 10.6979 17.1641H14.0279C14.4379 17.1641 14.7779 17.5041 14.7779 17.9141C14.7779 18.3241 14.4379 18.6641 14.0279 18.6641ZM14.8679 14.6641H9.86791C9.45791 14.6641 9.11791 14.3241 9.11791 13.9141C9.11791 13.5041 9.45791 13.1641 9.86791 13.1641H14.8679C15.2779 13.1641 15.6179 13.5041 15.6179 13.9141C15.6179 14.3241 15.2779 14.6641 14.8679 14.6641Z" fill="#E50000" />
                        </svg>
                        <p className='text-black text-sm font-semibold'>{t('sendPage.CANCEL')}</p>
                    </button>

                </div>
            </div>
            {/* {actions && <div className='fixed w-[400px] h-screen z-[51] top-0 -ml-[29px] sm:w-full bg-black/40 ' onClick={() => setActions(false)}></div>} */}
        </div>
    )
} 