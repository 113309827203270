import moment from 'moment-timezone';

export const getSeoulTime = (date) => {

     // Convert the provided date string to a Date object
     const originalDate = new Date(date);
    
     // Add 9 hours to the original date to get Seoul's local time
     const seoulTime = new Date(originalDate.getTime() + (9 * 60 * 60 * 1000));
     
     // Return the Seoul local time in the format 'YYYY-MM-DD HH:mm'
     return seoulTime.toISOString().replace('T', ' ').substr(0, 16);
    // console.log(seoulTime.toISOString().replace('T', ' ').substr(0, 16));
    return date.replace('T', ' ').substr(0, 16)

}

export const getSeoulDate = (date) => {

     // Convert the provided date string to a Date object
     const originalDate = new Date(date);
    
     // Add 9 hours to the original date to get Seoul's local time
     const seoulTime = new Date(originalDate.getTime() + (9 * 60 * 60 * 1000));
     
     // Return the Seoul local time in the format 'YYYY-MM-DD HH:mm'
     return seoulTime.toISOString().replace('T', ' ').substr(10, 9);
    // console.log(seoulTime.toISOString().replace('T', ' ').substr(0, 16));
    return date.replace('T', ' ').substr(0, 16)

}
