import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'
import { Link, json } from 'react-router-dom'
import { BACKEND_HOST } from '../constants/Index'
import Input from '../components/Input'
import Button from '../components/Button'

function Profile() {

    const [user, setUser] = useState()

    const [t] = useTranslation("global")

    const onChange = () => {
    }

    const logout = () => {
        localStorage.clear()
        window.location.pathname = '/'
    }

    const fetchMyprofile = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/user/profile/myprofile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            setUser(res)
        }
        else
            toast.error(res.message)
    }

    useEffect(() => {
        fetchMyprofile()
    }, [])

    return (
        <>
            <div className='flex justify-between items-center mb-4'>
                <h1 className='text-base font-medium text-black capitalize'>{localStorage.getItem('name')}</h1>
                <span onClick={logout} className='cursor-pointer'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H13.44L11.37 9.18C11.22 9.03 11.15 8.84 11.15 8.65C11.15 8.46 11.22 8.27 11.37 8.12C11.66 7.83 12.14 7.83 12.43 8.12L15.78 11.47C16.07 11.76 16.07 12.24 15.78 12.53L12.43 15.88C12.14 16.17 11.66 16.17 11.37 15.88C11.08 15.59 11.08 15.11 11.37 14.82L13.44 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z" fill="#E50000" />
                        <path d="M2.75 11.25C2.34 11.25 2 11.59 2 12C2 12.41 2.34 12.75 2.75 12.75H9V11.25H2.75Z" fill="#E50000" />
                    </svg>
                </span>
            </div>
            {!user && <Loader />}
            {user && <div className='flex flex-col gap-1 border-[1px] border-grey/30 rounded-lg p-2'>
                {localStorage.getItem('role') === 'advertiser' && <>
                    <Field label={t('formLabels.cname')} type="text" name="companyName" required={true} value={user.companyName} onChange={onChange}>
                        <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_32_1833)">
                                <path d="M10.9998 22.5H18.9998C23.0198 22.5 23.7398 20.89 23.9498 18.93L24.6998 10.93C24.9698 8.49 24.2698 6.5 19.9998 6.5H9.99983C5.72983 6.5 5.02983 8.49 5.29983 10.93L6.04983 18.93C6.25983 20.89 6.97983 22.5 10.9998 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11 6.5V5.7C11 3.93 11 2.5 14.2 2.5H15.8C19 2.5 19 3.93 19 5.7V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17 13.5V14.5C17 14.51 17 14.51 17 14.52C17 15.61 16.99 16.5 15 16.5C13.02 16.5 13 15.62 13 14.53V13.5C13 12.5 13 12.5 14 12.5H16C17 12.5 17 12.5 17 13.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24.65 11.5C22.34 13.18 19.7 14.18 17 14.52" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.62012 11.77C7.87012 13.31 10.4101 14.24 13.0001 14.53" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <filter id="filter0_d_32_1833" x="-1" y="0.5" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_32_1833" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_32_1833" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.cAdd')} type="text" name="companyAddress" required={true} value={user.companyAddress} onChange={onChange}>
                        <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_32_1833)">
                                <path d="M10.9998 22.5H18.9998C23.0198 22.5 23.7398 20.89 23.9498 18.93L24.6998 10.93C24.9698 8.49 24.2698 6.5 19.9998 6.5H9.99983C5.72983 6.5 5.02983 8.49 5.29983 10.93L6.04983 18.93C6.25983 20.89 6.97983 22.5 10.9998 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11 6.5V5.7C11 3.93 11 2.5 14.2 2.5H15.8C19 2.5 19 3.93 19 5.7V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17 13.5V14.5C17 14.51 17 14.51 17 14.52C17 15.61 16.99 16.5 15 16.5C13.02 16.5 13 15.62 13 14.53V13.5C13 12.5 13 12.5 14 12.5H16C17 12.5 17 12.5 17 13.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24.65 11.5C22.34 13.18 19.7 14.18 17 14.52" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.62012 11.77C7.87012 13.31 10.4101 14.24 13.0001 14.53" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <filter id="filter0_d_32_1833" x="-1" y="0.5" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_32_1833" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_32_1833" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.breg')} type="text" name="businessRegNo" required={true} value={user.businessRegNo} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 21.5H7C3 21.5 2 20.5 2 16.5V8.5C2 4.5 3 3.5 7 3.5H17C21 3.5 22 4.5 22 8.5V16.5C22 20.5 21 21.5 17 21.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 8.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15 12.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17 16.5H19" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.50043 11.79C9.50007 11.79 10.3104 10.9797 10.3104 9.98004C10.3104 8.98041 9.50007 8.17004 8.50043 8.17004C7.50079 8.17004 6.69043 8.98041 6.69043 9.98004C6.69043 10.9797 7.50079 11.79 8.50043 11.79Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 16.83C11.86 15.38 10.71 14.24 9.26 14.11C8.76 14.06 8.25 14.06 7.74 14.11C6.29 14.25 5.14 15.38 5 16.83" stroke="#151B33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.name3')} type="text" name="name" required={true} value={user.name} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.tel')} type="text" name="telephone" required={true} value={user.telephone} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.97 18.83C21.97 19.19 21.89 19.56 21.72 19.92C21.55 20.28 21.33 20.62 21.04 20.94C20.55 21.48 20.01 21.87 19.4 22.12C18.8 22.37 18.15 22.5 17.45 22.5C16.43 22.5 15.34 22.26 14.19 21.77C13.04 21.28 11.89 20.62 10.75 19.79C9.6 18.95 8.51 18.02 7.47 16.99C6.44 15.95 5.51 14.86 4.68 13.72C3.86 12.58 3.2 11.44 2.72 10.31C2.24 9.17 2 8.08 2 7.04C2 6.36 2.12 5.71 2.36 5.11C2.6 4.5 2.98 3.94 3.51 3.44C4.15 2.81 4.85 2.5 5.59 2.5C5.87 2.5 6.15 2.56 6.4 2.68C6.66 2.8 6.89 2.98 7.07 3.24L9.39 6.51C9.57 6.76 9.7 6.99 9.79 7.21C9.88 7.42 9.93 7.63 9.93 7.82C9.93 8.06 9.86 8.3 9.72 8.53C9.59 8.76 9.4 9 9.16 9.24L8.4 10.03C8.29 10.14 8.24 10.27 8.24 10.43C8.24 10.51 8.25 10.58 8.27 10.66C8.3 10.74 8.33 10.8 8.35 10.86C8.53 11.19 8.84 11.62 9.28 12.14C9.73 12.66 10.21 13.19 10.73 13.72C11.27 14.25 11.79 14.74 12.32 15.19C12.84 15.63 13.27 15.93 13.61 16.11C13.66 16.13 13.72 16.16 13.79 16.19C13.87 16.22 13.95 16.23 14.04 16.23C14.21 16.23 14.34 16.17 14.45 16.06L15.21 15.31C15.46 15.06 15.7 14.87 15.93 14.75C16.16 14.61 16.39 14.54 16.64 14.54C16.83 14.54 17.03 14.58 17.25 14.67C17.47 14.76 17.7 14.89 17.95 15.06L21.26 17.41C21.52 17.59 21.7 17.8 21.81 18.05C21.91 18.3 21.97 18.55 21.97 18.83Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" />
                            <path d="M18.5 9.5C18.5 8.9 18.03 7.98 17.33 7.23C16.69 6.54 15.84 6 15 6" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M22 9.5C22 5.63 18.87 2.5 15 2.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.mob')} type="text" name="mobile" required={true} value={user.mobile} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.mail')} type="text" name="email" required={true} value={user.email} onChange={onChange}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.id')} type="text" name="Id" required={true} value={user.unique_id} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.radd')} type="text" name="receivingAddress" value={user.receivingAddress} required={true} onChange={onChange}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z" stroke="#133BB7" stroke-width="1.5" />
                            <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#133BB7" stroke-width="1.5" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.rec')} type="text" name="receiver" required={true} value={user.receiver} onChange={onChange}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>

                    <div className='flex justify-center items-center gap-2 mt-10'>
                        <Link to='/advertiser/notices' className='flex items-center justify-center rounded-lg gap-2 px-4 py-2 border-grey/20 border-[1px]'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z" fill="#133BB7" />
                                <path d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" fill="#133BB7" />
                            </svg>
                            <p className='text-black text-sm font-semibold'>{t("notice")}</p>
                        </Link>
                        <Link to='/advertiser/faqs' className='flex items-center justify-center rounded-lg gap-2 px-4 py-2 border-grey/20 border-[1px]'>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.5 0.430054H5.5C2.5 0.430054 0.5 2.43005 0.5 5.43005V11.4301C0.5 14.4301 2.5 16.4301 5.5 16.4301V18.5601C5.5 19.3601 6.39 19.8401 7.05 19.3901L11.5 16.4301H15.5C18.5 16.4301 20.5 14.4301 20.5 11.4301V5.43005C20.5 2.43005 18.5 0.430054 15.5 0.430054ZM10.5 12.6001C10.08 12.6001 9.75 12.2601 9.75 11.8501C9.75 11.4401 10.08 11.1001 10.5 11.1001C10.92 11.1001 11.25 11.4401 11.25 11.8501C11.25 12.2601 10.92 12.6001 10.5 12.6001ZM11.76 8.45005C11.37 8.71005 11.25 8.88005 11.25 9.16005V9.37005C11.25 9.78005 10.91 10.1201 10.5 10.1201C10.09 10.1201 9.75 9.78005 9.75 9.37005V9.16005C9.75 8.00005 10.6 7.43005 10.92 7.21005C11.29 6.96005 11.41 6.79005 11.41 6.53005C11.41 6.03005 11 5.62005 10.5 5.62005C10 5.62005 9.59 6.03005 9.59 6.53005C9.59 6.94005 9.25 7.28005 8.84 7.28005C8.43 7.28005 8.09 6.94005 8.09 6.53005C8.09 5.20005 9.17 4.12005 10.5 4.12005C11.83 4.12005 12.91 5.20005 12.91 6.53005C12.91 7.67005 12.07 8.24005 11.76 8.45005Z" fill="#133BB7" />
                            </svg>
                            <p className='text-black text-sm font-semibold'>{t("faq")}</p>
                        </Link>
                    </div>
                </>}

                {localStorage.getItem('role') === 'staff' && <>
                    <Field label={t('formLabels.id')} type="text" name="Id" value={user.unique_id} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.name3')} type="text" name="name" value={user.name} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.mob')} type="text" name="mobile" value={user.mobile} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.mail')} type="text" name="email" value={user.email} required={true} onChange={onChange}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.department')} type="text" name="department" value={user.department} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.70001 18.5H4.15002C2.72002 18.5 2 17.78 2 16.35V4.65002C2 3.22002 2.72002 2.5 4.15002 2.5H8.45001C9.88001 2.5 10.6 3.22002 10.6 4.65002V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.3702 8.91998V20.08C17.3702 21.69 16.5702 22.5 14.9602 22.5H9.12018C7.51018 22.5 6.7002 21.69 6.7002 20.08V8.91998C6.7002 7.30998 7.51018 6.5 9.12018 6.5H14.9602C16.5702 6.5 17.3702 7.30998 17.3702 8.91998Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.3999 6.5V4.65002C13.3999 3.22002 14.1199 2.5 15.5499 2.5H19.8499C21.2799 2.5 21.9999 3.22002 21.9999 4.65002V16.35C21.9999 17.78 21.2799 18.5 19.8499 18.5H17.3699" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 11.5H14" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 14.5H14" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 22.5V19.5" stroke="#151B33" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                </>}

                {user.role === 'influencer' && <>
                    <Field label={t('formLabels.id')} type="text" name="unique_id" value={user.unique_id} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.channel')} type="text" name="youtubeChannel" value={user.youtubeChannel} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 15.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5H15C20 22.5 22 20.5 22 15.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.51953 7.60999H21.4795" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.51953 2.60999V7.46999" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.4805 2.60999V7.01999" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.75 14.95V13.75C9.75 12.21 10.84 11.58 12.17 12.35L13.21 12.95L14.25 13.55C15.58 14.32 15.58 15.58 14.25 16.35L13.21 16.95L12.17 17.55C10.84 18.32 9.75 17.69 9.75 16.15V14.95V14.95Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.scount')} type="text" name="subscriberCount" value={user.subscriberCount} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.0001 7.66C17.9401 7.65 17.8701 7.65 17.8101 7.66C16.4301 7.61 15.3301 6.48 15.3301 5.08C15.3301 3.65 16.4801 2.5 17.9101 2.5C19.3401 2.5 20.4901 3.66 20.4901 5.08C20.4801 6.48 19.3801 7.61 18.0001 7.66Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.9704 14.94C18.3404 15.17 19.8504 14.93 20.9104 14.22C22.3204 13.28 22.3204 11.74 20.9104 10.8C19.8404 10.09 18.3104 9.85003 16.9404 10.09" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.97047 7.66C6.03047 7.65 6.10047 7.65 6.16047 7.66C7.54047 7.61 8.64047 6.48 8.64047 5.08C8.64047 3.65 7.49047 2.5 6.06047 2.5C4.63047 2.5 3.48047 3.66 3.48047 5.08C3.49047 6.48 4.59047 7.61 5.97047 7.66Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.00043 14.94C5.63043 15.17 4.12043 14.93 3.06043 14.22C1.65043 13.28 1.65043 11.74 3.06043 10.8C4.13043 10.09 5.66043 9.85003 7.03043 10.09" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.0001 15.13C11.9401 15.12 11.8701 15.12 11.8101 15.13C10.4301 15.08 9.33008 13.95 9.33008 12.55C9.33008 11.12 10.4801 9.96997 11.9101 9.96997C13.3401 9.96997 14.4901 11.13 14.4901 12.55C14.4801 13.95 13.3801 15.09 12.0001 15.13Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.08973 18.28C7.67973 19.22 7.67973 20.76 9.08973 21.7C10.6897 22.77 13.3097 22.77 14.9097 21.7C16.3197 20.76 16.3197 19.22 14.9097 18.28C13.3197 17.22 10.6897 17.22 9.08973 18.28Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.viewCount')} type="text" name="viewCount" value={user.viewCount} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5799 12.5C15.5799 14.48 13.9799 16.08 11.9999 16.08C10.0199 16.08 8.41992 14.48 8.41992 12.5C8.41992 10.52 10.0199 8.92004 11.9999 8.92004C13.9799 8.92004 15.5799 10.52 15.5799 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.9998 20.77C15.5298 20.77 18.8198 18.69 21.1098 15.09C22.0098 13.68 22.0098 11.31 21.1098 9.89997C18.8198 6.29997 15.5298 4.21997 11.9998 4.21997C8.46984 4.21997 5.17984 6.29997 2.88984 9.89997C1.98984 11.31 1.98984 13.68 2.88984 15.09C5.17984 18.69 8.46984 20.77 11.9998 20.77Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.name')} type="text" name="name" value={user.name} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5901 22.5C20.5901 18.63 16.7402 15.5 12.0002 15.5C7.26015 15.5 3.41016 18.63 3.41016 22.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.radd')} type="text" name="receivingAddress" value={user.receivingAddress} required={true} onChange={onChange}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z" stroke="#133BB7" stroke-width="1.5" />
                            <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#133BB7" stroke-width="1.5" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.rec')} type="text" name="receiver" value={user.receiver} required={true} onChange={onChange}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.mob')} type="text" name="mobile" value={user.mobile} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 7.5V17.5C20 21.5 19 22.5 15 22.5H9C5 22.5 4 21.5 4 17.5V7.5C4 3.5 5 2.5 9 2.5H15C19 2.5 20 3.5 20 7.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 6H10" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.0002 19.6C12.8562 19.6 13.5502 18.906 13.5502 18.05C13.5502 17.194 12.8562 16.5 12.0002 16.5C11.1442 16.5 10.4502 17.194 10.4502 18.05C10.4502 18.906 11.1442 19.6 12.0002 19.6Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.mail')} type="text" name="email" value={user.email} required={true} onChange={onChange}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.cat')} type="text" name="category" value={user.category} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.99983 22.5H15.9998C20.0198 22.5 20.7398 20.89 20.9498 18.93L21.6998 10.93C21.9698 8.49 21.2698 6.5 16.9998 6.5H6.99983C2.72983 6.5 2.02983 8.49 2.29983 10.93L3.04983 18.93C3.25983 20.89 3.97983 22.5 7.99983 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 6.5V5.7C8 3.93 8 2.5 11.2 2.5H12.8C16 2.5 16 3.93 16 5.7V6.5" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 13.5V14.5C14 14.51 14 14.51 14 14.52C14 15.61 13.99 16.5 12 16.5C10.02 16.5 10 15.62 10 14.53V13.5C10 12.5 10 12.5 11 12.5H13C14 12.5 14 12.5 14 13.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21.65 11.5C19.34 13.18 16.7 14.18 14 14.52" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.62012 11.77C4.87012 13.31 7.41012 14.24 10.0001 14.53" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.preffered')} type="text" name="preffered" value={user.preffered} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.75 12.4999L10.58 15.3299L16.25 9.66992" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.nonpreferred')} type="text" name="nonpreferred" value={user.nonpreferred} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.75 12.4999L10.58 15.3299L16.25 9.66992" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>

                </>}
                {user.role === 'admin' && <>
                    <Field label={t('formLabels.id')} type="text" name="unique_id" value={user.unique_id} required={true} onChange={onChange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.1404 22.12C17.2604 22.38 16.2204 22.5 15.0004 22.5H9.00035C7.78035 22.5 6.74035 22.38 5.86035 22.12C6.08035 19.52 8.75035 17.47 12.0004 17.47C15.2504 17.47 17.9204 19.52 18.1404 22.12Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.5799 11.08C15.5799 13.06 13.9799 14.67 11.9999 14.67C10.0199 14.67 8.41992 13.06 8.41992 11.08C8.41992 9.10002 10.0199 7.5 11.9999 7.5C13.9799 7.5 15.5799 9.10002 15.5799 11.08Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>
                    <hr className='h-[1px] w-full border-grey/30 border-b-[1px]' />
                    <Field label={t('formLabels.mail')} type="text" name="email" value={user.email} required={true} onChange={onChange}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#133BB7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Field>

                    <div className='flex justify-center items-center gap-2 mt-10'>
                        <Link to='/admin/notices' className='flex items-center justify-center rounded-lg gap-2 px-4 py-2 border-grey/20 border-[1px]'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z" fill="#133BB7" />
                                <path d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" fill="#133BB7" />
                            </svg>
                            <p className='text-black text-sm font-semibold'>{t("notice")}</p>
                        </Link>
                        <Link to='/admin/faqs' className='flex items-center justify-center rounded-lg gap-2 px-4 py-2 border-grey/20 border-[1px]'>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 2.43005H7.5C4.5 2.43005 2.5 4.43005 2.5 7.43005V13.4301C2.5 16.4301 4.5 18.4301 7.5 18.4301V20.5601C7.5 21.3601 8.39 21.8401 9.05 21.3901L13.5 18.4301H17.5C20.5 18.4301 22.5 16.4301 22.5 13.4301V7.43005C22.5 4.43005 20.5 2.43005 17.5 2.43005ZM12.5 14.6001C12.08 14.6001 11.75 14.2601 11.75 13.8501C11.75 13.4401 12.08 13.1001 12.5 13.1001C12.92 13.1001 13.25 13.4401 13.25 13.8501C13.25 14.2601 12.92 14.6001 12.5 14.6001ZM13.76 10.4501C13.37 10.7101 13.25 10.8801 13.25 11.1601V11.3701C13.25 11.7801 12.91 12.1201 12.5 12.1201C12.09 12.1201 11.75 11.7801 11.75 11.3701V11.1601C11.75 10.0001 12.6 9.43005 12.92 9.21005C13.29 8.96005 13.41 8.79005 13.41 8.53005C13.41 8.03005 13 7.62005 12.5 7.62005C12 7.62005 11.59 8.03005 11.59 8.53005C11.59 8.94005 11.25 9.28005 10.84 9.28005C10.43 9.28005 10.09 8.94005 10.09 8.53005C10.09 7.20005 11.17 6.12005 12.5 6.12005C13.83 6.12005 14.91 7.20005 14.91 8.53005C14.91 9.67005 14.07 10.2401 13.76 10.4501Z" fill="#133BB7" />
                            </svg>
                            <p className='text-black text-sm font-semibold'>{t("faq")}</p>
                        </Link>
                    </div>
                </>}

            </div>}
            <UpdatePass />
        </>
    )
}

export default Profile


const Field = (props) => {
    return (
        <div className='flex justify-start items-center gap-2'>
            <div className='flex justify-center items-center rounded-md w-[50px] h-[50px] bg-white'>
                {props.children}
            </div>
            <div className='flex flex-col gap-1'>
                <p className='text-grey text-sm'>{props.label}</p>
                <p className='text-black text-sm'>{props.value}</p>
                {/* <input readOnly className='text-black text-sm font-medium outline-none bg-transparent' defaultValue={props.value} onChange={props.onChange} /> */}
            </div>
        </div>
    )
}



const UpdatePass = () => {
    const [t] = useTranslation("global")
    const [activeButton, setActiveButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState({})

    const onChange = (e) => {
        setPassword({...password, [e.target.name]: e.target.value})
    }

    useEffect(()=>{
        if(password.old_pass?.length >= 5 && password.new_pass?.length >= 5 && password?.confirm_new_pass?.length >= 5 ){
            setActiveButton(true)
        }
        else
            setActiveButton(false)

    }, [password])


    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/api/user/update_pass`,{
                method: 'Post',
                headers: {
                    'Content-Type': 'Application/json',
                    token: localStorage.getItem('token')
                },
                body: JSON.stringify(password)
            })

            const res = await response.json()
            if(response.status === 200){
                    toast.success(res.message)
                    setPassword({})
                    document.getElementById('old_pass').value = ''
                    document.getElementById('new_pass').value = ''
                    document.getElementById('confirm_new_pass').value = ''
                }
            else 
                toast.error(res.message)
        } catch (error) {
            console.log(error);
        }

        setLoading(false)
    }

    return (
        <form onSubmit={onSubmit} className='flex flex-col gap-2 border-[1px] border-grey/30 rounded-lg p-2 mt-4'>
            <Input labelSize='text-base' label={t('formLabels.old_pass')} type="password" name="old_pass" minLength={5} maxLength={50} required={true} onChange={onChange}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 10.5V8.5C6 5.19 7 2.5 12 2.5C17 2.5 18 5.19 18 8.5V10.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 19C13.3807 19 14.5 17.8807 14.5 16.5C14.5 15.1193 13.3807 14 12 14C10.6193 14 9.5 15.1193 9.5 16.5C9.5 17.8807 10.6193 19 12 19Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17 22.5H7C3 22.5 2 21.5 2 17.5V15.5C2 11.5 3 10.5 7 10.5H17C21 10.5 22 11.5 22 15.5V17.5C22 21.5 21 22.5 17 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Input>
            <Input labelSize='text-base' label={t('formLabels.new_pass')} type="password" name="new_pass" minLength={5} maxLength={50} required={true} onChange={onChange}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 10.5V8.5C6 5.19 7 2.5 12 2.5C17 2.5 18 5.19 18 8.5V10.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 19C13.3807 19 14.5 17.8807 14.5 16.5C14.5 15.1193 13.3807 14 12 14C10.6193 14 9.5 15.1193 9.5 16.5C9.5 17.8807 10.6193 19 12 19Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17 22.5H7C3 22.5 2 21.5 2 17.5V15.5C2 11.5 3 10.5 7 10.5H17C21 10.5 22 11.5 22 15.5V17.5C22 21.5 21 22.5 17 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Input>
            <Input labelSize='text-base' label={t('formLabels.confirm_new_pass')} type="password" name="confirm_new_pass" minLength={5} maxLength={50} required={true} onChange={onChange}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 10.5V8.5C6 5.19 7 2.5 12 2.5C17 2.5 18 5.19 18 8.5V10.5" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 19C13.3807 19 14.5 17.8807 14.5 16.5C14.5 15.1193 13.3807 14 12 14C10.6193 14 9.5 15.1193 9.5 16.5C9.5 17.8807 10.6193 19 12 19Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17 22.5H7C3 22.5 2 21.5 2 17.5V15.5C2 11.5 3 10.5 7 10.5H17C21 10.5 22 11.5 22 15.5V17.5C22 21.5 21 22.5 17 22.5Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Input>
            <div className='h-1'></div>
            <Button title={t('formLabels.update_btn')} active={activeButton} disabled={loading} />
        </form>
    )
}