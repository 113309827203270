import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { filterStyles } from '../utils/selectStyles'
import { BACKEND_HOST } from '../constants/Index'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'

function PackagesToArive() {
    const [packages, setPackages] = useState(null)
    const [arePackages, setArePackages] = useState(false)
    const [t] = useTranslation("global")

    const [sort, setSort] = useState(1)

    const search = (e) => {
        fetchPackages(e.target.value)
    }

    const fetchPackages = async (packageid = '') => {
        const response = await fetch(`${BACKEND_HOST}/api/packages/influencer/?box_id=${packageid}&sort=${sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            if (res.count > 0)
                setArePackages(true)
            setPackages(res.boxes)
        }
        else
            toast.error(res.message)
    }


    useEffect(() => {
        setPackages(null)
        fetchPackages()
    }, [sort])

    return (
        <div className='max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
            {!packages && <Loader />}
            {!arePackages && packages && <div>
                <div className='border-dashed border-[1px] border-blue rounded-xl bg-[#F2F4F9] h-[150px] flex flex-col justify-center items-center cursor-pointer'>
                    <p className='text-base text-black font-semibold'>{t('influencer.no_pack')}</p>
                </div>
            </div>}

            {arePackages && packages && <>
                <div className='flex gap-2 justify-between items-center'>
                    <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[70%]'>
                        <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: 'des', label: 'Newest' }} options={[{ value: 'des', label: 'Newest' }, { value: 'asc', label: 'Oldest' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
                </div>

                <p className='text-black font-medium text-base'>{packages?.length} Items</p>


                <div className='flex flex-col gap-2'>

                    {packages?.map((p, index) => {
                        let quantity = 0
                        for (let i = 0; i < p.packageItemIds.length; i++) {
                            quantity += p.packageItemIds[i].product_quantity

                        }
                        return <Link to={`/influencer/packages/${p._id}`} key={index} className='p-2 rounded-lg border-[1px] border-grey/50 flex justify-start items-stretch gap-2'>
                            <img src={`${BACKEND_HOST}/${p.packageItemIds[0]?.product?.image}`} className='w-[50px] h-[50px] rounded-md object-cover' />
                            <div className='flex flex-col w-full gap-1'>
                                <div className='flex flex-col w-full gap-1'>
                                    <div className='flex justify-between items-center'>
                                        {/* <h1 className='text-blue text-sm font-semibold capitalize'>{p.box_id}</h1> */}
                                        <span className='flex justify-start items-center gap-1'>
                            <h1 className='text-blue text-sm font-semibold capitalize'>{p.box_id}</h1>
                            <p className='text-[12px] text-grey'>{p.createdAt?.replace('T', ' ').substr(0,16)}</p>
                        </span>
                                    </div>
                                    <div className='flex justify-between items-stretch gap-1'>
                                        <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
                                            <p className='font-bold leading-3'>Items</p>
                                            <p>{p.packageItemIds.length}</p>
                                        </span>
                                        <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 pt-[1px] text-[10px] text-black rounded-md'>
                                            <p className='font-bold leading-3'>Quantity</p>
                                            <p>{quantity}</p>
                                        </span>
                                        <button className='flex-1 justify-center items-center flex bg-blue text-white text-[10px] leading-[10px] rounded-md'>{p.shipping_service? 'Shipped' : 'Shipping'}</button>
                                    </div>
                                    {p.shipping_service && <p className='text-black text-sm leading-3'>{p.shipping_service}: {p.invoiceno}</p>}
                                </div>
                            </div>
                        </Link>
                    })}

                </div>
            </>}

        </div>
    )
}

export default PackagesToArive
