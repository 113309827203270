import React, { useEffect, useState } from 'react';

function Input(props) {
  let  { labelSize } = props
  labelSize = labelSize? labelSize : 'text-lg'
  const [isFocused, setIsFocused] = useState(false);
  const [valid, setValid] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  const validateBRegNo = (breg) => {
    const regex = /^\d{3}-\d{2}-\d{5}$/;
    setValid(regex.test(breg));
  };

  const validateEmail = (email) => {
    const emailRegex = /(?:[!#-'*+/-9=?A-Z^-~-]+(?:\.[!#-'*+/-9=?A-Z^-~-]+)*|\"(?:\[\]!#-[^-~\t]|(\\[\t-~]))+\")@(?:(?:[!#-'*+/-9=?A-Z^-~-]+(?:\.[!#-'*+/-9=?A-Z^-~-]+)*)|\[(?:[\t-Z^-~]*)\])/;
    setValid(emailRegex.test(email));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(?:\+?82-?|0)(?:10|70|(?:2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-?\d{3,4}-?\d{4}$/;
    console.log("hll", phoneRegex.test(phoneNumber));
    setValid(phoneRegex.test(phoneNumber));
  };

  const validateMobileNumber = (phoneNumber) => {
    const mobileRegex = /^(?:\+?82-?|0)(?:10|70|(?:2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-?\d{3,4}-?\d{4}$/;

    setValid(mobileRegex.test(phoneNumber));
  };

  const validateID = (input) => {
    const regex = /^[a-zA-Z]{4}\d{6}$/;
    setValid(regex.test(input));
  };




  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (props.type === 'email') {
      validateEmail(inputValue);
      props.onChange(e)
      return;
    }
    if (props.unique_id) {
      validateID(inputValue);
      props.onChange(e)
      return;
    }

    if (props.telephone) {
      validatePhoneNumber(inputValue);
      props.onChange(e)
      return;
    }
    if (props.mobile) {
      validateMobileNumber(inputValue);
      props.onChange(e)
      return;
    }
    // if (props.brgNo) {
    //   validateBRegNo(inputValue);
    //   props.onChange(e)
    //   return;
    // }

    if (props.type == 'url') {
      const urlRegex = /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}([a-zA-Z0-9/?#&=.]+)?$/;
      setValid(urlRegex.test(inputValue));
      props.onChange(e);
      return;
    }

    if (props.maxLength) {
      setValid(e.target.value.length >= props.minLength ? true : false)
      document.getElementById(props.name).value = e.target.value.slice(0, props.maxLength)
      if (e.target.value.length < props.maxLength)
        props.onChange(e)
      return;
    }
  }

  useEffect(() => {
    const svg = document.getElementById(props.name).previousElementSibling
    if (svg) {
      const childNodes = svg.childNodes;
      childNodes.forEach(node => {
        if (node.style && valid) {
          if (props.question)
            node.style.fill = '#133BB7';
          else
            node.style.stroke = '#133BB7';
        }
        else
          if (props.question)
            node.style.fill = '#A7A9B7';
          else
            node.style.stroke = '#A7A9B7 ';
      });
    }
  }, [valid])


  useEffect(() => {
    setValid(props.value?.length >= props.minLength ? true : false)
    if (props.type == 'url') {
      const urlRegex = /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}([a-zA-Z0-9/?#&=.]+)?$/;
      setValid(urlRegex.test(props.value));
    }
    if (props.unique_id && props.value)
      setValid(true)
    if (props.type === 'email' && props.value)
      validateEmail(props.value)
    if (props.telephone  && props.value) 
      validatePhoneNumber(props.value)
    if (props.mobile  && props.value) 
      validatePhoneNumber(props.value)
    }, [props.value, props.unique_id])

  return (
    <div className='flex flex-col gap-1'>
      <label className={`text-black ${labelSize} font-normal`}>{props.label}</label>
      <div
        className={`flex justify-start items-center gap-2 bg-white ${valid ? 'border-blue' : 'border-grey/50'} border-[1px] rounded-lg px-3 py-2 transition-all ease-in duration-150 ${valid && 'border-blue'}`}
      >
        {props.children}
        <input
          id={props.name}
          type={props.type}
          onChange={handleChange}
          required={props.required}
          name={props.name}
          defaultValue={props.value || ''}
          placeholder={props.placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className='w-full outline-none font-normal text-black text-base placeholder:text-grey'
        />
      </div>
    </div>

  );
}

export default Input;