import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'
import { BACKEND_HOST } from '../constants/Index'
 
function PackageToAriveList() {
    const [t] = useTranslation("global")
    const navigate = useNavigate()
    const { _id } = useParams()

    const [packages, setPackage] = useState(null)

    const fetchItems = async () => {
        const resposne = await fetch(`${BACKEND_HOST}/api/packages/influencer/${_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            },
        })

        const res = await resposne.json()
        if (resposne.status === 200) {
            setPackage(res)
        }
        else
            toast.error(res.message)
        return false
    }


    useEffect(() => {
        fetchItems()
    }, [_id])

    return (
        <div className={`top-0 fixed transition-all ease-in duration-200 w-[400px] right-[300px] md:right-[100px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `}>
            <div className='w-[400px] relative m-auto bg-white h-screen flex flex-col px-7 pb-16 sm:w-full'>

                {!packages && <Loader/>}

                {packages && <>
                    <div className='flex h-[50px] w-full justify-start items-start mt-8'>
                        <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={() => navigate(-1)}>
                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <div className='flex flex-col gap-1 justify-center items-center w-full -ml-[30px] '>
                            <p className='text-black text-base font-medium text-center'>{t('packagePage.pack')}</p>
                            <p className='text-black text-base font-medium text-center leading-[10px]'>{packages.box_id}</p>
                        </div>
                    </div>

                    <div className='flex flex-col gap-2 py-10'>
                        {packages.packageItemIds.map((p, index) => {
                            return <div className='flex gap-2'>
                                <img src={`${BACKEND_HOST}/${p.product?.image}`} className='rounded-md w-[60px] h-[60px]'/>
                                <div className='flex flex-col justify-start items-start'>
                                    <h1 className='text-black text-sm font-bold'>{p.product?.title} <span className='text-blue text-base'>x{p?.product_quantity}</span></h1>
                                    <p className='bg-blue/20 px-2 rounded-md leading-3 py-1 text-[10px] text-blue'>{p.product?.category_name}</p>
                                    <p className='text-black text-sm'>{p.product?.description?.substr(0, 40)}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </>}

              
            </div>
        </div>
    )
}

export default PackageToAriveList
