import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import en from './languages/en/global.json'
import korean from './languages/korean/global.json'
import { BrowserRouter } from 'react-router-dom';
import PCLayout from './layouts/PCLayout';
import GlobalLayout from './layouts/GlobalLayout';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'kor', // Set the default language
  fallbackLng: 'kor', // Set the fallback language
  resources: {
    en: {
      global: en,
    },
    kor: {
      global: korean,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <GlobalLayout/>
    </BrowserRouter>
  </I18nextProvider>
);



// NODE_OPTIONS="--max_old_space_size=4096" pm2 start --name "smartSeeding" npm -- start