import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { convertBase64 } from '../utils'
import { toast } from 'react-toastify'
import Input from '../components/Input'
import Textarea from '../components/Textarea'
import Button from '../components/Button'
import { BACKEND_HOST } from '../constants/Index'
import Loader from '../components/Loader'
import imageCompression from 'browser-image-compression';

function AddProduct() {
    const [t] = useTranslation("global")
    const navigate = useNavigate()
    const [img, setImg] = useState(null)
    const [image, setImage] = useState(null)
    const [formData, setFormData] = useState(null)
    const [activeButton, setActiveButton] = useState(false)
    const [loading, setLoading] = useState(false)

    const { p_id } = useParams()

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const addNewProduct = async (fd) => {
        const response = await fetch(`${BACKEND_HOST}/api/products`, {
            method: 'POST',
            maxBodyLength: "Infinity",
            headers: {
                token: localStorage.getItem('token')
            },
            body: fd
        })

        const res = await response.json()
        if (response.status === 201) {
            toast.success(res.message)
            navigate(-1)
        }
        else
            toast.error(res.message)
    }

    const updateProduct = async (fd) => {
        const response = await fetch(`${BACKEND_HOST}/api/products`, {
            method: 'PUT',
            maxBodyLength: "Infinity",
            headers: {
                token: localStorage.getItem('token')
            },
            body: fd
        })

        const res = await response.json()
        if (response.status === 200) {
            toast.success(res.message)
            navigate(-1)
        }
        else
            toast.error(res.message)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const fd = new FormData();

        for (const [key, value] of Object.entries(formData)) {
            fd.append(key, value);
        }
        if (img)
            fd.append('p_image', img);

        if (p_id)
            await updateProduct(fd)
        else
            await addNewProduct(fd)

        setLoading(false)
    }

    const handleImg = async (e) => {
        e.stopPropagation();
        if (e.target.files[0]?.size >= 10000000) {
            toast.error('Image size should be less than 10MB')
            return;
        }
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true
        };

        const compressedFile = await imageCompression(e.target.files[0], options);

        setImg(compressedFile)
        const base64 = await convertBase64(e.target.files[0])
        setImage(base64)
    }

    const fetchProductToBeEdited = async () => {
        const response = await fetch(`${BACKEND_HOST}/api/products/${p_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            setFormData(res)
            setImage(`${BACKEND_HOST}/${res?.image}`)
            setActiveButton(true)
        }
        else
            toast.error(res.message)
    }

    useEffect(() => {
        if (p_id)
            fetchProductToBeEdited()
    }, [p_id])


    useEffect(() => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        console.log(img, formData?.title?.length >= 1, formData?.description?.length >= 1, formData?.color?.length >= 1, formData?.category_name?.length >= 1);
        if (img && formData?.title?.length >= 1 && formData?.description?.length >= 1 && formData?.color?.length >= 1 && formData?.category_name?.length >= 1) {
            setActiveButton(true)
        }
        else
            setActiveButton(false)
    }, [formData, img])

    if (p_id && !formData)
        return <form className={`top-0 fixed transition-all ease-in duration-200 w-[400px] md:right-[100px] right-[300px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `} onSubmit={onSubmit}>
            <div className='w-[400px] m-auto bg-white flex flex-col gap-4 px-7 pb-16 sm:w-full'>
                <Loader height={'h-screen'} />
            </div>
        </form>


    return (
        <form className={`top-0 fixed transition-all ease-in duration-200 bg-blue w-[400px] md:right-[100px] right-[300px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `} onSubmit={onSubmit}>
            <div className='fixed overflow-y-scroll h-screen overflow-x-hidden sm:w-full'>
            <div className='w-[400px] h-auto bg-white flex flex-col gap-4 px-7 pb-16 sm:w-full'>

                <div className='flex h-[50px] w-full justify-start items-center mt-8'>
                    <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={() => navigate(-1)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <p className='text-black text-base font-medium text-center w-full -ml-[30px]'>{p_id ? t('productPage.editProd') : t("productPage.addProduct")}</p>
                </div>

                <div onClick={() => document.getElementById('img').click()} className='relative flex flex-col justify-center items-center gap-1 border-blue border-dashed border-[1px] rounded-lg bg-[#F2F4F9] h-[134px] cursor-pointer'>
                    {image ?
                        <img src={image} alt='profile' className='w-[100px] h-[100px] object-cover rounded-full' />
                        :
                        <>
                            <img src={require('../assets/gallery.png')} alt='' className='w-[46px]' />
                            <p className='text-grey text-center text-sm'>PNG, JPG, GIF (0.5M or less then 10M)</p>
                        </>
                    }
                    <input type='file' required={p_id ? false : true} accept='image/*' id='img' className='absolute left-10 opacity-0' onChange={handleImg} onClick={(e) => e.stopPropagation()} />
                </div>

                <Input label={t('productPage.nameLabel')} type="text" name="title" required={true} onChange={onChange} maxLength={50} minLength={1} value={formData?.title}>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.25 9.5V14.5C22.25 19.5 20.25 21.5 15.25 21.5H9.25C4.25 21.5 2.25 19.5 2.25 14.5V8.5C2.25 3.5 4.25 1.5 9.25 1.5H14.25" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.25 9.5H18.25C15.25 9.5 14.25 8.5 14.25 5.5V1.5L22.25 9.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.25 12.5H13.25" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.25 16.5H11.25" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Input>
                <Textarea label={t('productPage.detailLabel')} type="text" name="description" required={true} onChange={onChange} maxLength={500} minLength={1} value={formData?.description}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z" stroke="#C4C6CF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5" stroke="#C4C6CF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 13H12" stroke="#C4C6CF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 17H16" stroke="#C4C6CF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Textarea>
                <Input label={t('productPage.colorLabel')} type="text" name="color" required={true} onChange={onChange} maxLength={50} minLength={1} value={formData?.color}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.24 3.88 10.9 6.42 10.21C7.11 11.95 8.59 13.29 10.42 13.79C10.92 13.93 11.45 14 12 14C12.55 14 13.08 13.93 13.58 13.79C13.85 14.47 14 15.22 14 16Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22 16C22 19.31 19.31 22 16 22C14.46 22 13.06 21.42 12 20.46C13.23 19.37 14 17.77 14 16C14 15.22 13.85 14.47 13.58 13.79C15.41 13.29 16.89 11.95 17.58 10.21C20.12 10.9 22 13.24 22 16Z" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Input>
                <Input label={t('productPage.catLabel')} type="text" name="category_name" required={true} onChange={onChange} maxLength={50} minLength={1} value={formData?.category_name} >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.99983 22.5H15.9998C20.0198 22.5 20.7398 20.89 20.9498 18.93L21.6998 10.93C21.9698 8.49 21.2698 6.5 16.9998 6.5H6.99983C2.72983 6.5 2.02983 8.49 2.29983 10.93L3.04983 18.93C3.25983 20.89 3.97983 22.5 7.99983 22.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 6.5V5.7C8 3.93 8 2.5 11.2 2.5H12.8C16 2.5 16 3.93 16 5.7V6.5" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 13.5V14.5C14 14.51 14 14.51 14 14.52C14 15.61 13.99 16.5 12 16.5C10.02 16.5 10 15.62 10 14.53V13.5C10 12.5 10 12.5 11 12.5H13C14 12.5 14 12.5 14 13.5Z" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M21.65 11.5C19.34 13.18 16.7 14.18 14 14.52" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2.62012 11.77C4.87012 13.31 7.41012 14.24 10.0001 14.53" stroke="#A7A9B7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Input>
                <Input label={t('productPage.urlLabel')} type="url" name="url" required={false} onChange={onChange} value={formData?.url}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.27 12C2.48 11.05 2 9.83 2 8.5C2 5.48 4.47 3 7.5 3H12.5C15.52 3 18 5.48 18 8.5C18 11.52 15.53 14 12.5 14H10" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M20.73 12C21.52 12.95 22 14.17 22 15.5C22 18.52 19.53 21 16.5 21H11.5C8.48 21 6 18.52 6 15.5C6 12.48 8.47 10 11.5 10H14" stroke="#A7A9B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Input>

                <Button title={p_id ? t('productPage.buttonUpdate') : t('productPage.buttonTitle')} active={activeButton} disabled={loading} />
            </div>

            </div>
        </form>
    )
}

export default AddProduct
