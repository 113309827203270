import React from 'react'
import { useTranslation } from 'react-i18next';

function MainFooter() {
    const [t] = useTranslation('global')
  return (
    <div className='flex flex-col gap-7 justify-center items-center sm:mb-10'>

      <div className='flex justify-between items-center gap-x-5 gap-y-3 flex-wrap'>
            <div className='flex flex-col gap-1'>
                <p className='text-[10px] text-blue font-bold capitalize'>{t('br_no')}</p>
                <p className='text-[10px] text-black font-bold capitalize'>111-87-00728</p>
            </div>
            <div className='flex flex-col gap-1'>
                <p className='text-[10px] text-blue font-bold capitalize'>{t("e_liscence")}</p>
                <p className='text-[10px] text-black font-bold capitalize'>{t("e_liscence_val")}</p>
            </div>
            <div className='flex flex-col gap-1'>
                <p className='text-[10px] text-blue font-bold capitalize'>{t("c_name")}</p>
                <p className='text-[10px] text-black font-bold capitalize'>{t("c_name_val")}</p>
            </div>
            <div className='flex flex-col gap-1'>
                <p className='text-[10px] text-blue font-bold capitalize'>{t("add")}</p>
                <p className='text-[10px] text-black font-bold capitalize'>{t("add_value")}</p>
            </div>
            <div className='flex gap-5 justify-between flex-wrap'>
                <div className='flex flex-col gap-1'>
                    <p className='text-[10px] text-blue font-bold capitalize'>{t("Contact")}</p>
                    <p className='text-[10px] text-black font-bold capitalize'>02-545-8807</p>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-[10px] text-blue font-bold capitalize'>Email</p>
                    <p className='text-[10px] text-black font-bold lowercase'>seedingplus@icecreative.kr</p>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-[10px] text-blue font-bold capitalize'>{t("Rep")}</p>
                    <p className='text-[10px] text-black font-bold lowercase'>{t("rep_val")}</p>
                </div>
            </div>

            <div className='flex justify-between items-center w-full mt-4 sm:mb-10'>
                    <p className='text-[10px] text-blue font-bold capitalize'>copyeight @ {new Date().getFullYear()} Ice Creative</p>
                    <div className='flex justify-end items-center gap-2'>
                    <a target='_blank' href='https://www.notion.so/seedingplus/216b93dda63a4eaa86a267497c6678fa?pvs=4' className='text-[10px] text-blue font-bold capitalize'>{t("Terms")}</a>
                    <p className='text-[10px] text-blue font-bold capitalize'>-</p>
                    <a target='_blank' href='https://www.notion.so/seedingplus/fda830045f7c488a99a2e9c14c194058?pvs=4' className='text-[10px] text-blue font-bold capitalize'>{t("p_policy")}</a>
                    </div>
            </div>
      </div>
    </div>
  )
}

export default MainFooter
