import React, { useState } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

function LoginHeader() {
  const [t, i18n] = useTranslation("global")
  const [langDrapDown, setLangDropDown] = useState(false)
  const [selectedLang, setSelectedLang] = useState('KO')

  const changeLang = (name) => {
    if (name == 'en') {
      setSelectedLang('EN')
    }
    if (name == 'kor') {
      setSelectedLang('KO')
    }
    i18next.changeLanguage(name, (err, t) => {
      if (err) return console.log('Something went wrong loading the language', err);
    });
    setLangDropDown(false)
  }

  return (
    <div className='bg-white w-full flex justify-between h-[100px] bg-red py-8'>
      {/* <h1 className=' text-blue text-[30px] leading-8 font-extrabold lowercase'>seeding+</h1> */}
      
      <img src={require('../assets/seedingplus_logo_pc.png')} className='w-[150px] h-[40px] object-contain -mt-4'/>
      <button onClick={() => setLangDropDown(true)} className='flex shadow-sm rounded-lg border-[1px] border-grey/10 bg-white px-4 py-1 gap-2 justify-between items-center'>
        {selectedLang}
        <IoIosArrowDown />
      </button>

      {langDrapDown && <div className='fixed top-0 sm:right-0 sm:w-full h-screen bg-black/40 w-[400px] right-[300px] md:right-[100px]'>
        <div className='flex flex-col justify-center items-center absolute top-8 bg-white right-4 rounded-lg'>
          <span className='flex justify-start items-start p-2 gap-2 cursor-pointer w-full' onClick={() => changeLang('en')}>
            <p className='text-black text-lg font-normal'>English</p>
            {selectedLang === 'EN' && <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2.65503C6.49 2.65503 2 7.14503 2 12.655C2 18.165 6.49 22.655 12 22.655C17.51 22.655 22 18.165 22 12.655C22 7.14503 17.51 2.65503 12 2.65503ZM16.78 10.355L11.11 16.025C10.97 16.165 10.78 16.245 10.58 16.245C10.38 16.245 10.19 16.165 10.05 16.025L7.22 13.195C6.93 12.905 6.93 12.425 7.22 12.135C7.51 11.845 7.99 11.845 8.28 12.135L10.58 14.435L15.72 9.29503C16.01 9.00503 16.49 9.00503 16.78 9.29503C17.07 9.58503 17.07 10.055 16.78 10.355Z" fill="#00D261" />
            </svg>}
          </span>
          <hr className='border-grey/40 border-b-[1px] h-[1px] w-[80%]' />
          <span className='flex justify-start items-start p-2 gap-2 cursor-pointer w-full' onClick={() => changeLang('kor')}>
            <p className='text-black text-lg font-normal'>한국어</p>
            {selectedLang === 'KO' && <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2.65503C6.49 2.65503 2 7.14503 2 12.655C2 18.165 6.49 22.655 12 22.655C17.51 22.655 22 18.165 22 12.655C22 7.14503 17.51 2.65503 12 2.65503ZM16.78 10.355L11.11 16.025C10.97 16.165 10.78 16.245 10.58 16.245C10.38 16.245 10.19 16.165 10.05 16.025L7.22 13.195C6.93 12.905 6.93 12.425 7.22 12.135C7.51 11.845 7.99 11.845 8.28 12.135L10.58 14.435L15.72 9.29503C16.01 9.00503 16.49 9.00503 16.78 9.29503C17.07 9.58503 17.07 10.055 16.78 10.355Z" fill="#00D261" />
            </svg>}
          </span>
        </div>
      </div>}
    </div>
  )
}

export default LoginHeader
