import React, { createContext, useEffect, useState } from "react";

export const AdvertiserContext = createContext();


function AdvertiserProvider(props) {
    const [selectProductPopuup, setSelectProductPopup] = useState(false)
    const [selectInfluencerPopuup, setSelectInfluencerPopup] = useState(false)
    const [packageInvoicePopup, setPackageInvoicePopup] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [packageToBeCreated, setPackageToBeCreated] = useState({package_id: null, total_fee : 0,  packages: [{
        product :null, influencer:null, comment: null, return_leavel: null, 
    }]})


    const deletePackageItem = (index) => {
      if(index === 0 && packageToBeCreated.packages.length === 1){
        packageToBeCreated.packages[0].product = null
        packageToBeCreated.packages[0].influencer = null
        packageToBeCreated.packages[0].comment = null
        packageToBeCreated.packages[0].return_leavel = null
      }
      else
        packageToBeCreated.packages.splice(index, 1);
      setRefresh(state => !state)
    }

    const  copyPackageItem = (index) => {
      const duplicate = packageToBeCreated.packages[index]
      packageToBeCreated.packages.push(duplicate)
      setRefresh(state => !state)

    }
    const  AddNewPackageItem = (index) => {
        const newObjec = { product :null, influencer:null, comment: null, return_leavel: null}
        packageToBeCreated.packages.push(newObjec)
        setRefresh(state => !state)
    }


    const calculateFee = () => {
      let _fee = 0
      for (let i = 0; i < packageToBeCreated?.packages.length; i++) {
        let area_price =  packageToBeCreated?.packages[i]?.influencer?.fee?.area_fee
        let charges = area_price? area_price : 0
        _fee += charges
      }
      packageToBeCreated.total_fee = _fee
      setRefresh(state => !state)
    }

    useEffect(()=>{
     
    }, [])

    const contextValues = {
        selectProductPopuup, setSelectProductPopup,
        packageToBeCreated, setPackageToBeCreated,
        selectInfluencerPopuup, setSelectInfluencerPopup,
        refresh, setRefresh,
        calculateFee,
        deletePackageItem, copyPackageItem, AddNewPackageItem,
        packageInvoicePopup, setPackageInvoicePopup,
    }
  return (
    <AdvertiserContext.Provider value={contextValues}>
       {props.children}
    </AdvertiserContext.Provider>
  )
}

export default AdvertiserProvider
