import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

function RefundConfirmation() {
    const [t] = useTranslation("global")
    const navigate = useNavigate()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const packageId = queryParams.get('packageId');
    const status = queryParams.get('status');


    const back = () => {
        if(localStorage.getItem('role') === 'admin'){
            navigate('/admin/payments')
            return;
        }

        if (status == "true")
            navigate('/advertiser/package')
        else
            navigate(`/advertiser/send`)
    }


    return (
        <div className={`top-0 fixed transition-all ease-in duration-200 w-[400px] md:right-[100px] right-[300px] sm:right-0 sm:w-full h-screen overflow-scroll z-[51] `}>
            <div className='w-[400px] relative m-auto bg-white h-screen flex flex-col px-7 pb-16 sm:w-full'>

                <div className='flex h-[50px] w-full justify-start items-start mt-8'>
                    <span className='px-[4px] py-[4px] flex justify-center items-center rounded-full border-grey/20 border-[1px] cursor-pointer z-50' onClick={back}>
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.77217 5.93579L3.20801 11.5L8.77217 17.0641" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.7918 11.5H3.36426" stroke="#191D31" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <div className='flex flex-col gap-1 justify-center items-center w-full -ml-[30px] '>
                        <p className='text-black text-base font-medium text-center leading-[10px]'>{packageId || ''}</p>
                        <p className='text-black text-base font-semibold text-center'>환불(Refund)</p>
                    </div>
                </div>

                <div className='flex justify-center items-center h-full'>
                    {status == "true" ?
                    <p className='text-base text-black font-medium font-inter text-center'>
                        환불 <br /> 완료되었습니다.<br />(Refund completed)
                    </p>
                    :
                    <p className='text-base text-black font-medium font-inter text-center'>
                        환불  <br /> 실패하였습니다.<br />(Refund failed)
                    </p>
                    }
                </div>

            </div>
        </div>
    )
}

export default RefundConfirmation
