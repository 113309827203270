import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PiDotsThreeOutlineThin } from 'react-icons/pi'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants/Index'
import { filterStyles } from '../utils/selectStyles'
import { AdvertiserContext } from '../state/AdvertiserProvider'
import { StaffContext } from '../state/StaffProvide'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import { getSeoulTime } from '../utils/getSeoulTime'

function StaffSend() {
    const [packages, setPackages] = useState(null)
    const [arePackages, setArePackages] = useState(false)
    const [t] = useTranslation("global")

    const { packageInvoicePopup, setPackageInvoicePopup } = useContext(StaffContext)

    const [sort, setSort] = useState(0)

    const search = (e) => {
        fetchPackages(e.target.value)
    }

    const fetchPackages = async (packageid = '') => {
        const response = await fetch(`${BACKEND_HOST}/api/packages/staff/boxes?box_id=${packageid}&sort=${sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            if (res.count > 0)
                setArePackages(true)
            setPackages(res.boxes)
        }
        else
            toast.error(res.message)
    }


    const confirm = async (id) => {
        const response = await fetch(`${BACKEND_HOST}/api/packages/staff/confirm/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
                token: localStorage.getItem('token')
            }
        })

        const res = await response.json()
        if (response.status === 200) {
            toast.success(res.message)
        }
        else
            toast.error(res.message)
    }


    useEffect(() => {
        setPackages(null)
        fetchPackages()
    }, [sort, packageInvoicePopup])
    return (
        <div className='min-h-[200px] min-h-screen max-h-auto z-0 overflow-hidden flex flex-col gap-4'>
            {!packages && <Loader />}
            {!arePackages && packages?.length < 0 &&
                <div>
                    <div className='border-dashed border-[1px] border-blue rounded-xl bg-[#F2F4F9] h-[150px] flex flex-col justify-center items-center cursor-pointer'>
                        <p className='text-base text-black font-semibold'>{t('sendPage.no_pack')}</p>
                    </div>
                </div>}

            {packages && <>

                <div className='flex gap-2 justify-between items-center'>
                    <span className='flex px-3 py-2 justify-between gap-1 bg-[#F9F9F9] rounded-lg w-[70%]'>
                        <input type='text' className='bg-transparent text-sm text-grey w-full outline-none' onChange={search} />
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1104 20.4923C16.0809 20.4923 20.1104 16.4629 20.1104 11.4923C20.1104 6.52175 16.0809 2.49231 11.1104 2.49231C6.13979 2.49231 2.11035 6.52175 2.11035 11.4923C2.11035 16.4629 6.13979 20.4923 11.1104 20.4923Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.0398 21.1821C19.5698 22.7821 20.7798 22.9421 21.7098 21.5421C22.5598 20.2621 21.9998 19.2121 20.4598 19.2121C19.3198 19.2021 18.6798 20.0921 19.0398 21.1821Z" stroke="#133BB7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <Select onChange={(opt) => setSort(opt.value)} defaultValue={{ value: '0', label: 'Newest' }} options={[{ value: '0', label: 'Newest' }, { value: '1', label: 'A-Z' }]} styles={filterStyles} components={{ IndicatorSeparator: () => null }} isSearchable={false} />
                </div>

                <p className='text-black font-medium text-base'>{packages?.length} Packages</p>


                <div className='flex flex-col gap-2'>

                    {packages?.map((p, index) => {
                        let quantity = 0
                        p.items_count = [];
                        let totalPayment = p.packageItemIds.length * p.packageItemIds[0]?.influencer?.fee?.area_fee
                        for (let i = 0; i < p.packageItemIds.length; i++) {
                            quantity += p.packageItemIds[i].product_quantity
                            if (!p.items_count.some(x => x === p.packageItemIds[i].product))
                                p.items_count.push(p.packageItemIds[i].product)

                        }
                        p.items_count = p.items_count.length
                        return <div key={index} className='p-2 rounded-lg border-[1px] border-grey/50 flex justify-start items-stretch gap-2'>
                            <Link to={`/staff/send/${p._id}`} className='w-[70px] h-[60px] rounded-md bg-grey/50'>
                                <img src={`${BACKEND_HOST}/${p.packageItemIds[0]?.influencer?.profile_image}`} className='rounded-md w-[60px] h-[60px] object-cover bg-grey/20' />
                            </Link>
                            <div className='flex flex-col w-full gap-1'>
                                <div className='flex flex-col w-full gap-1'>
                                    <div className='flex justify-between items-center'>
                                        {/* <h1 className='text-blue text-sm font-semibold capitalize'>{p.box_id}</h1> */}
                                        <span className='flex justify-start items-center gap-1'>
                                            <h1 className='text-blue text-sm font-semibold capitalize'>{p.box_id}</h1>
                                            <p className='text-[12px] text-grey'>{getSeoulTime(p.createdAt)}</p>
                                        </span>
                                        <PiDotsThreeOutlineThin /*onClick={() => setPackageInvoicePopup(p)} */ className='text-base cursor-pointer' />
                                    </div>
                                    <div className='flex justify-between items-stretch gap-1'>
                                        <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
                                            <p className='font-bold leading-3'>{t('packageItem.items')}</p>
                                            <p>{p.items_count}</p>
                                        </span>
                                        <span className='flex-1 flex flex-col justify-center items-center bg-grey/40 py- text-[10px] text-black rounded-md'>
                                            <p className='font-bold leading-3'>{t('packageItem.quantity')}</p>
                                            <p>{quantity}</p>
                                        </span>
                                        {!p.shipping_service && <button className='flex-1 bg-blue text-white text-[10px] leading-[10px] rounded-md' onClick={() => setPackageInvoicePopup(p)} >Enter Invoice</button>}
                                        {p.shipping_service && !p.confirm && <button className='flex-1 bg-blue text-white text-[10px] leading-[10px] rounded-md' onClick={() => confirm(p._id)} >Confirm</button>}
                                        {p.confirm && <button disabled className='flex-1 bg-blue/75 text-white text-[10px] leading-[10px] rounded-md' >Finish</button>}
                                    </div>
                                    {p.shipping_service && <p className='text-black text-sm leading-3 text-center border-[1px] border-blue p-1 rounded-sm'>{p.shipping_service}: {p.invoiceno}</p>}
                                </div>
                            </div>
                        </div>
                    })}

                </div>

            </>}

        </div>
    )
}

export default StaffSend
